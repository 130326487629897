import { CustomCard, PageHeading } from "../common-styling";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Image,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Form
} from "react-bootstrap";
// import SpinnerButton from "../spinner-button/spinner-button";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import {
  FieldSet,
  Legend
  // ValiditySpinner,
  // HorizontalDivider
  // BorderlessInput
} from "../common-styling";
import styled from "styled-components";
import { DNS_RECORD_TYPES, THUMBNAIL_TYPE } from "../strings";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useLocation } from "react-router";
import {
  deleteTemplateDraft,
  getDnsRecord,
  getCustomerActiveWebsiteTemplate,
  getCustomerAllWebTemplates,
  saveCustomerWebTemplate,
  getCustomerActiveWebsiteDomain,
  generateWebsiteImages,
  generateWebsiteContent,
  getCustomerById
} from "../../services/api-declaration";
// import GoToSiteButton from "../go-to-site-button/site-button";
import { generateCustomerSitePreviewUrl } from "../../services/common-service";
import WebsiteDomainSection from "../reusable-form-section/webiste-domain-section";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDown,
  faClone,
  faEye,
  faTrash,
  faUpload,
  faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import { useDialog } from "../../hooks/useDialog";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { showError, showSuccess } from "../../services/toast-service";
import HeaderSection from "./website-sections/header-section";
import HeroSection from "./website-sections/hero-section";
import AboutSection from "./website-sections/about-section";
import ServiceSection from "./website-sections/service-section";
import FirstBanner from "./website-sections/first-banner";
import KeyPointSection from "./website-sections/key-point-section";
import SecondBanner from "./website-sections/second-banner";
import MapSection from "./website-sections/map-section";
import FaqSection from "./website-sections/faq-section";
import FooterSection from "./website-sections/footer-section";
import BrandColorsSection from "./website-sections/brand-colors-section";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import AddNewSection from "./website-sections/add-new-section";
import { v4 as uuidv4 } from "uuid";
import PreventReload from "../funnel-form/prevent-reload";
import { websiteSchema } from "./website-schema/validation-schema";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";

const WebsiteInfo = styled.div`
  background: var(--body-light-bg);
  border-bottom: 3px solid var(--primary-color);
`;

const Img = styled(Image)`
  height: 300px;
  width: 150px;
  object-fit: cover;
`;

// const SaveTemplate = styled.div`
//   background: var(--body-light-bg);
// `;

const CustomerWebsite = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isSubmittingForce, setIsSubmittingForce] = useState(false);
  const [isDeletingDraft, setIsDeletingDraft] = useState(false);
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState({});
  const [templates, setTemplates] = useState([]);
  const [hoverActive, setHoverActive] = useState(-1);
  const [selectedTemplate, setSelectedTemplate] = useState("TEMPLATE_1");
  const [showPreviewTemplate, setShowPreviewTemplate] = useState(-1);
  const [mainLinks, setMainLinks] = useState([]);
  const [isAllowedChange, setIsAllowedChange] = useState(true);
  const [sectionActiveKeys, setSectionActiveKeys] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7"
  ]);
  const [isDraftWarning, setIsDraftWarning] = useState(false);
  const [isConflictWarning, setIsConflictWarning] = useState(false);
  const [dnsResult, setDnsResult] = useState(null);
  const { state } = useLocation();
  const [dnsLoading, setDnsLoading] = useState(false);
  const [showCheckDNS, setShowCheckDNS] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [customer, setCustomer] = useState(state?.customer);

  // const [isGeneratingContent, setIsGeneratingContent] = useState(false);

  const oca = useRef(0);

  const isFormChanged = useRef(false);
  const isApiCalling = useRef(false);
  const apiIntervalHandler = useRef(undefined);

  const { REACT_APP_ASSET_CDN_URL } = process.env;

  const {
    showDuplicateWebTemplateDialogBox,
    showAIPromptDialogBox,
    showConfirmationDialogBox,
    setDependentStates
  } = useDialog();
  const tags = useSelector((state) => state.tags);
  function extractImageRequirements(sections) {
    const result = {};
    sections.forEach((section) => {
      const { component, data, name } = section;
      if (!component || !data) return;
      let imageCount = 0;
      imageCount = countImages(data);
      if (imageCount > 0) {
        const matchedSystemIdentifier = tags.tags.find(
          (item) => item.systemIdentifier === component
        );
        const systemTagId = matchedSystemIdentifier
          ? matchedSystemIdentifier._id
          : null;
        result[name] = {
          imagesRequired: imageCount,
          component: component,
          name: name,
          systemTagId: systemTagId
        };
      }
    });
    return result;
  }
  // Helper function to count images for each section
  function countImages(obj) {
    if (!obj || typeof obj !== "object") return 0;
    let count = 0;
    if (
      (obj.image && typeof obj.image === "object") ||
      (obj.bg_image && typeof obj.bg_image === "object")
    ) {
      count += 1;
    }
    if (Array.isArray(obj.images)) {
      count += obj.images.length;
    }
    if (Array.isArray(obj.services)) {
      count += obj.services.filter((service) => service.image).length;
    }
    return count;
  }
  const toggleConfirmationDialog = () => {
    showConfirmationDialogBox({
      dialogMessage: renderDeleteDialogMessage(),
      dialogType: "danger",
      responseAction: deleteDialogResponseAction,
      title: t("customerWebsite.deleteSection")
    });
  };

  const toggleForcePushDialog = () => {
    showConfirmationDialogBox({
      dialogMessage: renderForcePushDialogMessage(),
      dialogType: "danger",
      responseAction: forcePushResponseAction,
      title: "Merge Conflict",
      acceptLabel: "Forcely Merge",
      declineLabel: "Reload"
    });
  };

  const deleteDialogResponseAction = (action, values) => {
    if (action) {
      removeSection(values.index);
    }
  };

  const renderDeleteDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("customerWebsite.deleteSectionMessage")
      }}
    ></div>
  );

  const renderForcePushDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: "Someone is working on the same template"
      }}
    ></div>
  );

  const logoTypes = [
    { name: t("customerWebsite.text"), _id: "text" },
    { name: t("customerWebsite.image"), _id: "image" }
  ];

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    move(source.index, destination.index);
    isFormChanged.current = true;
  };

  const handleCollapseSections = () => {
    setSectionActiveKeys([]);
    if (sectionActiveKeys.length > 0) {
      setSectionActiveKeys([]);
    } else {
      const sectionKeys = sections.map((_, index) => index.toString());
      setSectionActiveKeys(sectionKeys);
    }
  };

  const toggleDuplicateDialog = () => {
    showDuplicateWebTemplateDialogBox({
      templateType: selectedTemplate,
      customerId: params.id,
      formValues: getValues()
    });
  };

  const toggleAIPromptDialog = () => {
    showAIPromptDialogBox({
      customerId: params.id,
      customerContactData: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone,
        address: customer
          ? `${customer.address || ""} ${customer.zipCode || ""} ${
              customer?.city?.name || ""
            }`.trim()
          : "",
        email: customer.email
      },
      generateContent: generateContent
    });
  };

  // const [shouldUpdateNonActive, setShouldUpdateNonActive] = useState({
  //   templateId: null,
  //   customerId: null
  // });

  const initialValues = useMemo(
    () => ({
      hero: {
        isCustom: false,
        component: "hero",
        hashId: "hero",
        name: "Hero",
        data: {
          bgColor: "#ffffff",
          bgColorDisplay: false,
          image: {
            key: "",
            url: "",
            _id: "",
            asset: ""
          },
          btn: {
            bgColor: "#000000",
            bgColorDisplay: true
          }
        }
      },
      about: {
        isCustom: false,
        component: "about",
        hashId: "about",
        name: "About",
        data: {
          images: [
            {
              key: "",
              url: "",
              _id: "",
              asset: ""
            },
            {
              key: "",
              url: "",
              _id: "",
              asset: ""
            },
            {
              key: "",
              url: "",
              _id: "",
              asset: ""
            }
          ],
          bgColor: "#ffffff",
          bgColorDisplay: false
        }
      },
      service: {
        isCustom: false,
        component: "service",
        hashId: "service",
        name: "Service",
        data: {
          bgColor: "#ffffff",
          bgColorDisplay: false,
          services: [
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              },
              image: {
                key: "",
                url: "",
                _id: "",
                asset: ""
              }
            },
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              },
              image: {
                key: "",
                url: "",
                _id: "",
                asset: ""
              }
            },
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              },
              image: {
                key: "",
                url: "",
                _id: "",
                asset: ""
              }
            }
          ]
        }
      },
      banner: {
        isCustom: false,
        component: "banner",
        hashId: "banner",
        name: "Banner",
        data: {
          btn: {
            bgColor: "#000000",
            bgColorDisplay: false
          },
          bg_image: {
            key: "",
            url: "",
            _id: "",
            asset: ""
          }
        }
      },
      keyPoint: {
        isCustom: false,
        component: "keyPoint",
        hashId: "keyPoint",
        name: "Key Point",
        data: {
          bgColor: "#ffffff",
          bgColorDisplay: false,
          keyPoints: [
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              }
            },
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              }
            },
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              }
            },
            {
              heading: {
                text: "",
                color: "#000000"
              },
              para: {
                text: "",
                color: "#000000"
              }
            }
          ]
        }
      },
      secondBanner: {
        isCustom: false,
        component: "secondBanner",
        hashId: "secondBanner",
        name: "Second Banner",
        data: {
          bgColor: "#f4f4ea",
          bgColorDisplay: true,
          btn: {
            bgColor: "#000000",
            bgColorDisplay: true
          },
          image: {
            key: "",
            url: "",
            _id: "",
            asset: ""
          }
        }
      },
      map: {
        isCustom: false,
        component: "map",
        hashId: "map",
        name: "Map"
      },
      faq: {
        isCustom: false,
        component: "faq",
        hashId: "faq",
        name: "Faqs",
        data: {
          bgColor: "#f4f4ea",
          bgColorDisplay: true,
          active: false,
          faqs: {
            data: [{ q: "", a: "" }]
          }
        }
      }
    }),
    []
  );

  const defaultValues = useMemo(
    () => ({
      brand_colors: {
        logo_color: "#000000",
        heading_dark: "#000000",
        heading_light: "#ffffff",
        text_primary: "#000000",
        text_secondary: "#b4b4b4",
        text_light: "#ffffff",
        btn_primary: "#ffffff",
        btn_bg_primary: "#000000",
        btn_secondary: "#ffffff",
        btn_bg_secondary: "#000000",
        primary_bg: "#f4f4ea",
        secondary_bg: "#ffffff"
      },
      navbar: {
        bgColor: "#ffffff",
        bgColorDisplay: false,
        logo: {
          color: "#000000"
        }
      },
      sections: [
        initialValues.hero,
        initialValues.about,
        initialValues.service,
        initialValues.banner,
        initialValues.keyPoint,
        initialValues.secondBanner,
        initialValues.map,
        initialValues.faq
      ],
      footer: {
        bgColor: "#ffffff",
        bgColorDisplay: false,
        logo: {
          color: "#000000"
        },
        footer_links: {
          social_link: {
            text: t("customerWebsite.socialLinks")
          },
          quick_links: {
            text: t("customerWebsite.quickLinks")
          },
          pp_links: {
            text: t("customerWebsite.policies")
          }
        },
        contact_card: {
          text: t("customerWebsite.contact"),
          btn: {
            bgColor: "#000000",
            bgColorDisplay: true
          }
        }
      }
    }),
    [initialValues, t]
  );

  const methods = useForm({
    resolver: yupResolver(websiteSchema(logoTypes)),
    defaultValues
  });

  const {
    formState: { isDirty },
    reset,
    getValues,
    watch,
    setValue,
    handleSubmit,
    register,
    trigger,
    control
  } = methods;

  const {
    fields: sections,
    append: addSection,
    remove: removeSection,
    move
  } = useFieldArray({
    control,
    name: "sections"
  });

  const forcePushResponseAction = async (action) => {
    if (action) {
      setIsSubmittingForce(true);
      const response = await getCustomerActiveWebsiteTemplate({
        customerId: params.id
      });

      if (response.status === 200) {
        const template = response.data.data;
        oca.current = template?.oca;
      }
      await handleAutoSave(params.id, selectedTemplate, getValues());
      setIsSubmittingForce(false);
    } else {
      setIsConflictWarning(false);
      removeTemplateFromLocalStorage(params.id);
      getActiveTemplate();
    }
  };

  const handleAddSection = (section) => {
    Object.assign(initialValues[section.component], {
      ...initialValues[section.component],
      name: section.name,
      hashId: uuidv4(),
      isCustom: true
    });
    addSection(initialValues[section.component]);
  };

  const btnRef = useRef();

  const parseResourceSrc = (resourceObj) =>
    resourceObj?.key
      ? `${REACT_APP_ASSET_CDN_URL}/${resourceObj.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";

  const parseTemplateResourceSrc = (type) =>
    type
      ? `${REACT_APP_ASSET_CDN_URL}/customer-website/${type}/main.jpg/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";

  const parsePreviewResourceSrc = (resourceObj) =>
    resourceObj?.key ? `${resourceObj.url}/${resourceObj.key}` : "";

  const parseTemplatePreviewResourceSrc = (type) =>
    type ? `${REACT_APP_ASSET_CDN_URL}/customer-website/${type}/main.jpg` : "";

  const handleSaveWebsite = async (formVals, isPublished) => {
    isApiCalling.current = true;
    try {
      const {
        domain: updatedDomain,
        subDomain: updatedSubDomain,
        prompt,
        ...templateData
      } = formVals;
      const template = {
        templateType: selectedTemplate,
        active: true,
        isPublished: isPublished || false,
        oca: oca.current,
        data: templateData
      };

      const response = await saveCustomerWebTemplate(params.id, template);

      // Handle the API response
      const updatedTemplate = response.data.data;
      showSuccess(response.data.message);
      updateTemplate(updatedTemplate);
      removeTemplateFromLocalStorage(params.id);
      if (updatedTemplate && !updatedTemplate?.isPublished) {
        setIsDraftWarning(true);
        oca.current = updatedTemplate?.oca;
      } else {
        setIsDraftWarning(false);
        oca.current = 0;
      }
      isApiCalling.current = false;
      return updatedTemplate;
    } catch (err) {
      showError(err?.response?.data?.message || "API Error");
      console.error("An error occurred while submitting the form:", err);
      isApiCalling.current = false;
    }
  };

  const handleAutoSave = useCallback(
    async (customerId, templateType, formValues) => {
      isApiCalling.current = true;
      try {
        const { domain, subDomain, prompt, ...templateData } = formValues;
        const template = {
          templateType: templateType,
          active: true,
          isPublished: false,
          oca: oca.current,
          data: templateData
        };
        const response = await saveCustomerWebTemplate(customerId, template);
        const updatedTemplate = response.data.data;
        setActiveTemplate(updatedTemplate);
        setIsDraftWarning(true);
        setIsConflictWarning(false);
        oca.current = updatedTemplate?.oca;
        removeTemplateFromLocalStorage(customerId);
      } catch (err) {
        console.log(err);
        const tempData = {
          templateType: templateType,
          oca: oca.current,
          data: formValues
        };
        const storedData = localStorage.getItem("customerSite");
        try {
          const parsedData = storedData ? JSON.parse(storedData) : {};
          parsedData[customerId] = tempData;
          localStorage.setItem("customerSite", JSON.stringify(parsedData));
        } catch (err) {
          console.log(err);
        }
        if (err?.response?.status === 409) {
          setIsConflictWarning(true);
          setIsDraftWarning(false);
        } else {
          showError(err?.response?.data?.message || "Auto Save Issue");
        }
        console.error("An error occurred while submitting the form:", err);
      }
      isApiCalling.current = false;
    },
    []
  );

  const handlePreview = async () => {
    let templateId = activeTemplate._id;
    try {
      setIsPreviewing(true);
      const response = await getCustomerActiveWebsiteTemplate({
        customerId: params.id
      });

      const template = response.data.data;
      if (template) {
        if (!template?.isPublished && template?.oca !== oca.current) {
          oca.current = template?.oca;
          toggleForcePushDialog();
          return;
        }
      }

      if (
        isDirty ||
        findTemplateFromLocalStorage(params.id) ||
        template.templateType !== selectedTemplate
      ) {
        const template = await handleSaveWebsite(getValues(), false);
        templateId = template?._id;
      }

      const url = generateCustomerSitePreviewUrl(templateId);
      window.open(url, "_blank");
    } catch (error) {
      showError(error?.response?.message);
      console.error(error?.response?.message || error);
    } finally {
      setIsPreviewing(false);
    }
  };

  const handleDeleteDraft = async () => {
    setIsDeletingDraft(true);
    try {
      await deleteTemplateDraft(params.id);
      removeTemplateFromLocalStorage(params.id);
      setIsDraftWarning(false);
      getActiveTemplate();
    } catch (err) {
      showError(err?.response?.data?.message || "Delete Draft Error");
    }
    setIsDeletingDraft(false);
  };

  const onSubmit = async (formVals) => {
    try {
      setIsSubmitting(true);
      await handleSaveWebsite(formVals, true);
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to handle template render
  const updateTemplate = (template) => {
    setActiveTemplate(template);
    reset(template?.data);
    template?.isPublished && setValue("domain", template.domain);
  };

  const getDNS = async (domain, isValid) => {
    try {
      if (!isValid) {
        return;
      }
      setDnsLoading(true);
      setShowCheckDNS(false);
      const dnsRecord = await getDnsRecord({ domain });
      const dnsResult = dnsRecord.data.data.dnsResult;
      setDnsResult(dnsResult);
      setDnsLoading(false);
      setIsEditable(false);
    } catch (err) {
      console.log(err);
      setDnsLoading(false);
      setDnsResult(
        err?.response?.data?.data?.dnsResult || DNS_RECORD_TYPES.NOT_FOUND
      );
      setIsEditable(true);
    }
  };

  const getActiveTemplate = async () => {
    setIsAllowedChange(false);
    try {
      const response = await getCustomerActiveWebsiteTemplate({
        customerId: params.id
      });

      const template = response.data.data;
      setActiveTemplate(template);

      const existingRecord = findTemplateFromLocalStorage(params.id);
      if (existingRecord) {
        reset(existingRecord.data);
        oca.current = existingRecord.oca;
        setSelectedTemplate(existingRecord.templateType);
        const sectionKeys = existingRecord.data.sections.map((_, index) =>
          index.toString()
        );
        setSectionActiveKeys(sectionKeys);
      } else {
        const template = response.data.data;
        if (template) {
          if (template?.isPublished) {
            setIsDraftWarning(false);
          } else {
            setIsDraftWarning(true);
            oca.current = template.oca;
          }
          setSelectedTemplate(template.templateType ?? "TEMPLATE_1");
          if (template.data) {
            template.data.navbar.links.links.forEach((link) => {
              if (link.link) {
                link.link.name = t(
                  `customerWebsite.sections.${link.link?.component}`
                );
              }
            });
            template.data.footer.footer_links.quick_links.links.forEach(
              (link) => {
                if (link.link) {
                  link.link.name = t(
                    `customerWebsite.sections.${link.link?.component}`
                  );
                }
              }
            );
            reset(template.data);
            const sectionKeys = template.data.sections.map((_, index) =>
              index.toString()
            );
            setSectionActiveKeys(sectionKeys);
          } else {
            reset(defaultValues);
          }
        } else {
          setIsEditable(true);
          setDnsResult(null);
          reset(defaultValues);
        }
        template?.isPublished && setValue("domain", template.domain);
      }
    } catch (error) {
      console.error("Error fetching template:", error);
    } finally {
      setTimeout(() => {
        setIsAllowedChange(true);
      }, 1000);
    }
  };

  const getActiveWebsiteDomain = async () => {
    try {
      const response = await getCustomerActiveWebsiteDomain({
        customerId: params.id
      });

      const domain = response?.data?.data?.domain;
      if (domain) {
        setValue("domain", domain);
        const isValid = await trigger("domain");
        getDNS(domain, isValid);
      } else {
        setValue("domain", "");
        setIsEditable(true);
        setDnsResult(null);
      }
    } catch (error) {
      console.error("Error fetching domain:", error);
    }
  };

  const getAllTemplates = async () => {
    try {
      const response = await getCustomerAllWebTemplates();
      const templates = response.data.data;
      setTemplates(templates);
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  const activeTemplateCB = useCallback(getActiveTemplate, [
    reset,
    params.id,
    t,
    defaultValues,
    setValue
  ]);

  const activeWebsiteDomainCB = useCallback(getActiveWebsiteDomain, [
    params.id,
    setValue,
    trigger
  ]);

  const allTemplatesCB = useCallback(getAllTemplates, []);

  useEffect(() => {
    allTemplatesCB();
  }, [allTemplatesCB]);

  useEffect(() => {
    (async () => {
      await activeTemplateCB();
      await activeWebsiteDomainCB();
    })();
  }, [params.id, activeWebsiteDomainCB, activeTemplateCB]);

  useEffect(() => {
    setMainLinks(
      sections.map(({ component, hashId, name, isCustom }) => ({
        name: isCustom ? name : t(`customerWebsite.sections.${component}`),
        component,
        hashId,
        isCustom
      }))
    );
  }, [sections, t]);

  const renderSection = (section, index) => {
    switch (section.component) {
      case "hero":
        return (
          <HeroSection
            index={index}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parsePreviewResourceSrc}
            isFormChanged={isFormChanged}
          />
        );
      case "about":
        return (
          <AboutSection
            index={index}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parsePreviewResourceSrc}
            isFormChanged={isFormChanged}
          />
        );
      case "service":
        return (
          <ServiceSection
            index={index}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parsePreviewResourceSrc}
            isFormChanged={isFormChanged}
          />
        );
      case "banner":
        return (
          <FirstBanner
            index={index}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parsePreviewResourceSrc}
            isFormChanged={isFormChanged}
          />
        );
      case "keyPoint":
        return <KeyPointSection index={index} isFormChanged={isFormChanged} />;
      case "secondBanner":
        return (
          <SecondBanner
            index={index}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parsePreviewResourceSrc}
            isFormChanged={isFormChanged}
          />
        );
      case "map":
        return <MapSection index={index} />;
      case "faq":
        return <FaqSection index={index} isFormChanged={isFormChanged} />;
      default:
        return null;
    }
  };

  const toggleAccordion = (key) => {
    const isFound = sectionActiveKeys.find((i) => i === key);
    if (isFound) {
      const activeKeys = [...sectionActiveKeys.filter((i) => i !== key)];
      setSectionActiveKeys(activeKeys);
    } else {
      const activeKeys = [...sectionActiveKeys];
      activeKeys.push(key);
      setSectionActiveKeys(activeKeys);
    }
  };

  const handleAccordionClick = (key) => {
    toggleAccordion(key?.toString());
  };

  const removeTemplateFromLocalStorage = (customerId) => {
    const storedData = localStorage.getItem("customerSite");
    const parsedData = storedData ? JSON.parse(storedData) : {};
    delete parsedData[customerId];
    localStorage.setItem("customerSite", JSON.stringify(parsedData));
  };

  const findTemplateFromLocalStorage = (customerId) => {
    const storedData = localStorage.getItem("customerSite");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData[customerId];
    }
  };

  useEffect(() => {
    clearInterval(apiIntervalHandler.current);
    apiIntervalHandler.current = setInterval(() => {
      if (isFormChanged.current && !isApiCalling.current) {
        isFormChanged.current = false;
        handleAutoSave(params.id, selectedTemplate, getValues());
      }
    }, 1000);
    return () => clearInterval(apiIntervalHandler.current);
  }, [params.id, selectedTemplate, getValues, handleAutoSave]);
  useEffect(() => {
    console.log(params.id);
    if (!customer && params.id) {
      const getCustomer = async () => {
        try {
          const response = await getCustomerById(params.id);
          console.log("response:", response);
          setCustomer(response.data?.data);
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message ||
              t("toastMessage.errorInGetting", {
                what: t("common.customer")
              })
          );
        }
      };
      getCustomer();
    }
    // eslint-disable-next-line
  }, [customer, params.id]);
  watch(async (data, state) => {
    if (state.type !== "change") return;
    if (state.name === "domain" || state.name === "prompt") return;
    isFormChanged.current = true;
  });
  console.log("customer data", customer);

  const generateContent = async (formVals) => {
    try {
      const response = await generateWebsiteContent(formVals);
      const requiredImagesData = extractImageRequirements(sections);
      const imagesData = await generateWebsiteImages({
        formVals,
        requiredImagesData
      });
      sections.forEach((section, index) => {
        const data = response.data.data.find(
          (s) => s.component === section.component
        );
        const images = imagesData.data.data.find(
          (s) => s.name === section.name
        );
        if (data && section.data.active !== false) {
          setValue(`sections[${index}].data.heading.text`, data.data.heading);
          setValue(`sections[${index}].data.para.text`, data.data.para);
          const services = section?.data?.services;
          const keyPoints = section?.data?.keyPoints;
          switch (section.component) {
            case "about":
              if (images) {
                setValue(`sections[${index}].data.images`, images.data);
              }
              break;
            case "hero":
              if (images) {
                setValue(`sections[${index}].data.image`, images.data[0]);
              }
              setValue(
                `sections[${index}].data.bullets.data`,
                data.data.bullets
              );
              setValue(`sections[${index}].data.btn.text`, data.data.btn.text);
              break;
            case "service":
              services.forEach((service, i) => {
                if (images) {
                  setValue(
                    `sections[${index}].data.services[${i}].image`,
                    images.data[i]
                  );
                }
                setValue(
                  `sections[${index}].data.services[${i}].heading.text`,
                  data.data.services[i].heading
                );
                setValue(
                  `sections[${index}].data.services[${i}].heading.text`,
                  data.data.services[i].heading
                );
                setValue(
                  `sections[${index}].data.services[${i}].para.text`,
                  data.data.services[i].para
                );
              });
              break;
            case "banner":
              if (images) {
                setValue(`sections[${index}].data.bg_image`, images.data[0]);
              }
              setValue(`sections[${index}].data.btn.text`, data.data.btn.text);
              break;
            case "keyPoint":
              keyPoints.forEach((keyPoint, i) => {
                setValue(
                  `sections[${index}].data.keyPoints[${i}].heading.text`,
                  data.data.keyPoints[i].heading
                );
                setValue(
                  `sections[${index}].data.keyPoints[${i}].para.text`,
                  data.data.keyPoints[i].para
                );
              });
              break;
            case "secondBanner":
              if (images) {
                setValue(`sections[${index}].data.image`, images.data[0]);
              }
              setValue(`sections[${index}].data.btn.text`, data.data.btn.text);
              break;
            case "faq":
              setValue(`sections[${index}].data.faqs.data`, data.data.faqs);
              break;
            default:
              break;
          }
        }
      });
      isFormChanged.current = true;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="my-2">
      <WebsiteInfo className="sticky-top mb-3">
        <div className="d-flex align-items-center justify-content-between py-2">
          <PageHeading className="mt-0">{t("common.website")}</PageHeading>
          <div>
            <Button
              onClick={toggleAIPromptDialog}
              className="me-3"
              style={{ paddingBottom: 2, paddingTop: 0 }}
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />{" "}
              <span className="ms-2">Write with AI</span>
            </Button>
            <Button
              variant="secondary"
              className="me-3"
              type="button"
              style={{ paddingBottom: 2, paddingTop: 0 }}
              onClick={toggleDuplicateDialog}
              disabled={!activeTemplate._id || !activeTemplate.isPublished}
            >
              <FontAwesomeIcon icon={faClone} />
              <span className="pe-2 ms-2">
                {t("customerWebsite.duplicate")}
              </span>
            </Button>
            <Button
              variant="dark"
              className="me-3"
              type="button"
              style={{ paddingBottom: 2, paddingTop: 0 }}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              disabled={isSubmitting || isConflictWarning}
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <FontAwesomeIcon icon={faUpload} />
              )}

              <span className="pe-2 ms-2">Publish</span>
            </Button>
            {/* <GoToSiteButton
              url={generateCustomerSiteUrl(
                activeTemplate?.domain,
                activeTemplate?.subDomain
              )}
              // isArchived={!activeTemplate?.isPublished}
              resourceId={params.id}
              className="me-3"
              isPublished={activeTemplate?.isPublished}
              // isTestMode
            /> */}
            <Button
              variant="success"
              type="button"
              style={{ paddingBottom: 2, paddingTop: 0 }}
              onClick={handlePreview}
              className="me-3"
              disabled={
                isPreviewing ||
                isConflictWarning ||
                (!activeTemplate && !isDirty)
              }
            >
              <span className="pe-2 me-2">Preview</span>
              {isPreviewing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </Button>
            {activeTemplate && !activeTemplate.isPublished && (
              <Button
                variant="danger"
                type="button"
                style={{ paddingBottom: 2, paddingTop: 0 }}
                disabled={isDeletingDraft}
                onClick={handleDeleteDraft}
              >
                <span className="pe-2 me-2">Discard Draft</span>
                {isDeletingDraft ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </Button>
            )}
          </div>
        </div>
        {isDraftWarning && (
          <div className="bg-warning text-dark w-100 mt-1 mb-3 p-1 px-2 rounded d-flex align-items-center">
            <p className="mb-0">There are some changes are not published yet</p>
          </div>
        )}
        {isConflictWarning && (
          <div className="bg-warning text-dark w-100 mt-1 mb-3 p-2 px-2 rounded d-flex align-items-center justify-content-between">
            <p className="mb-0 me-2">Someone has worked on this template</p>
            <div>
              <Button
                variant="dark"
                size="sm"
                className="me-2 rounded"
                onClick={() => forcePushResponseAction(false)}
              >
                Discard Changes
              </Button>
              <Button
                variant="danger"
                size="sm"
                className="rounded"
                disabled={isSubmittingForce}
                onClick={() => forcePushResponseAction(true)}
              >
                {isSubmittingForce && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <span className="ms-2">Force Push</span>
              </Button>
            </div>
          </div>
        )}
      </WebsiteInfo>
      <FormProvider {...methods}>
        <PreventReload />
        <form>
          <CustomCard className="mb-3">
            <FieldSet>
              <WebsiteDomainSection
                btnRef={btnRef}
                isDomainValid={isDomainValid}
                setIsDomainValid={setIsDomainValid}
                dnsResult={dnsResult}
                dnsLoading={dnsLoading}
                getDNS={getDNS}
                showCheckDNS={showCheckDNS}
                setShowCheckDNS={setShowCheckDNS}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                // setShouldUpdateNonActive={setShouldUpdateNonActive}
              />
            </FieldSet>
          </CustomCard>
          {/* <CustomCard className="mb-3">
            <FieldSet>
              <Row>
                <Col md={12}>
                  <Legend>
                    <h4 className="mb-0 fw-bold">Prompt</h4>
                  </Legend>
                </Col>
                <Col md={12} className="mt-3">
                  <TextArea name="prompt" register={register} />
                </Col>
                <Col>
                  <Button
                    onClick={generateContent}
                    variant="primary"
                    disabled={isGeneratingContent}
                  >
                    {isGeneratingContent && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                    )}
                    {isGeneratingContent ? "Generating..." : "Generate Content"}
                  </Button>
                </Col>
              </Row>
            </FieldSet>
          </CustomCard> */}
          <CustomCard className="mb-3">
            <FieldSet>
              <Row>
                <Col md={12}>
                  <Legend>
                    <h4 className="mb-0 fw-bold">
                      {t("customerWebsite.template")}
                    </h4>
                  </Legend>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <div className="d-flex" style={{ gap: "20px" }}>
                    {templates.length > 0 &&
                      templates.map((template, index) => {
                        return (
                          <div
                            key={template.type}
                            className="position-relative rounded cursor-pointer"
                            style={{
                              border: `5px solid ${
                                selectedTemplate === template.type
                                  ? "blue"
                                  : "black"
                              }`
                            }}
                            onMouseEnter={() => setHoverActive(index)}
                            onClick={() => {
                              setSelectedTemplate(template.type);
                              isFormChanged.current = true;
                            }}
                            onMouseLeave={() => setHoverActive(-1)}
                          >
                            <Img
                              className="rounded cursor-pointer"
                              src={parseTemplateResourceSrc(template.type)}
                            />
                            {hoverActive === index && (
                              <div
                                className="cursor-pointer position-absolute top-0 left-0 d-flex justify-content-center align-items-center"
                                style={{
                                  width: "150px",
                                  height: "300px",
                                  background: "rgba(0,0,0,0.3)"
                                }}
                              >
                                <Button
                                  variant="outline-light"
                                  size="sm"
                                  onClick={() => setShowPreviewTemplate(index)}
                                >
                                  {t("customerWebsite.preview")}
                                </Button>
                              </div>
                            )}
                            {showPreviewTemplate === index && (
                              <ImagePreviewModal
                                source={parseTemplatePreviewResourceSrc(
                                  template.type
                                )}
                                toggleModal={() => setShowPreviewTemplate(-1)}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </FieldSet>
          </CustomCard>
          {/* <HorizontalDivider /> */}
          <BrandColorsSection
            isAllowedChange={isAllowedChange}
            isFormChanged={isFormChanged}
          />
          <HeaderSection
            logoTypes={logoTypes}
            sections={mainLinks}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parseResourceSrc}
            isFormChanged={isFormChanged}
            customerData={customer}
          />
          <CustomCard className="mb-3 bg-transparent border-0 px-0">
            <Row>
              <Col md={12} className="text-end">
                <Button
                  className="btn-primary w-full btn-sm"
                  onClick={handleCollapseSections}
                >
                  Toggle Collapse
                </Button>
              </Col>
            </Row>
          </CustomCard>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion
                      flush
                      activeKey={sectionActiveKeys}
                      className="mb-3"
                      alwaysOpen
                    >
                      {sections.map((section, index) => (
                        <Draggable
                          key={section.id}
                          draggableId={section.id}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <Accordion.Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={section._id}
                                eventKey={index.toString()}
                                className="mb-3 border"
                              >
                                <div className="d-flex bg-white align-items-center px-3">
                                  <FontAwesomeIcon
                                    className="fw-regular text-secondary"
                                    size="lg"
                                    icon={faArrowsUpDown}
                                  />
                                  <Accordion.Button
                                    onClick={() => handleAccordionClick(index)}
                                    className="bg-white rounded border-0 box-shadow-0"
                                    style={{ boxShadow: "none" }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: "10px" }}
                                    >
                                      <Legend>
                                        <h4 className="mb-0 fw-bold text-black">
                                          {section.isCustom
                                            ? section.name
                                            : t(
                                                `customerWebsite.sections.${section.component}`
                                              )}

                                          <span
                                            className="ms-2 text-secondary"
                                            style={{ fontSize: "16px" }}
                                          >
                                            (
                                            {t(
                                              `customerWebsite.sections.${section.component}`
                                            )}
                                            )
                                          </span>
                                        </h4>
                                      </Legend>
                                      {section.component === "map" && (
                                        <p
                                          className="text-primary fw-bold mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          ({t("customerWebsite.mapMessage")})
                                        </p>
                                      )}
                                      {section.component === "faq" && (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>
                                              {watch(
                                                `sections[${index}].data.active`
                                              )
                                                ? "Display On"
                                                : "Display Off"}
                                            </Tooltip>
                                          }
                                        >
                                          <Form.Check
                                            role="button"
                                            type="switch"
                                            {...register(
                                              `sections[${index}].data.active`
                                            )}
                                          />
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  </Accordion.Button>
                                  {section.isCustom && (
                                    <Button
                                      onClick={() => {
                                        setDependentStates({ index });
                                        toggleConfirmationDialog();
                                      }}
                                      variant="outline-danger mx-2"
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  )}
                                </div>
                                {(section.component !== "faq" ||
                                  watch(`sections[${index}].data.active`)) && (
                                  <AccordionBody className={`bg-white`}>
                                    {renderSection(section, index)}
                                  </AccordionBody>
                                )}
                              </Accordion.Item>
                            );
                          }}
                        </Draggable>
                      ))}
                    </Accordion>
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <AddNewSection
            addSection={handleAddSection}
            selectedTemplate={selectedTemplate}
          />
          <FooterSection
            logoTypes={logoTypes}
            parseResourceSrc={parseResourceSrc}
            parsePreviewResourceSrc={parseResourceSrc}
            sections={mainLinks}
            isFormChanged={isFormChanged}
            customerData={customer}
          />
          {/* <SaveTemplate className="p-0 sticky-bottom">
            <HorizontalDivider />
            <Row>
              <Col xs={12} md={6}>
                {!isSubmitting ? (
                  <Button
                    variant="primary"
                    className="me-3 mb-3"
                    type="button"
                    ref={btnRef}
                    onClick={handleDraft}
                    disabled={!isDomainValid}
                  >
                    {t("customerWebsite.saveWebsite")}
                  </Button>
                ) : (
                  <SpinnerButton
                    btnText={t("customerWebsite.savingWebsite")}
                    btnStyle="me-3 mb-3 btn-primary"
                  />
                )}
              </Col>
            </Row>
          </SaveTemplate> */}
        </form>
      </FormProvider>
    </div>
  );
};
export default CustomerWebsite;
