import { Tab, Tabs } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import { DISPLAY_FUNNEL } from "../strings";
import FunnelTableRow from "./funnel-table-row";
import ViewMoreButton from "../view-more-button";
import TableLoader from "../loader/table-loader";
import { useDialog } from "../../hooks/useDialog";
import { funnelFilterConfig } from "./filter-config";
import { showError } from "../../services/toast-service";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { getAllFunnels } from "../../services/api-declaration";
import { useTranslation } from "react-i18next";
import "./tabs.css";

const FunnelsTable = ({ customerId = null, asSubComponent = false, limit }) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const { showCreateNewDialogBox } = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();
  const [funnels, setFunnels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});

  const getFunnels = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      if (params.archived === undefined) {
        params.archived = false;
      }
      if (customerId) {
        params.customer = customerId;
      }
      if (limit) {
        params.limit = limit;
      }
      const response = await getAllFunnels(params);
      const data = response.data.data;
      setFunnels(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log("ERROR: ", error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "funnels" })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const handleSelectTab = (evKey) => {
    searchParams.set("pageIndex", "1");
    setSearchParams(searchParams);
    if (evKey === DISPLAY_FUNNEL.ARCHIVED_ONES) {
      handleQueryParams("archived", true);
    } else {
      handleQueryParams("archived", false);
    }
  };

  const generateQueryParams = () => ["funnelType"];

  const getFunnelsCb = useCallback(
    getFunnels,
    // eslint-disable-next-line
    [searchParams, customerId, limit]
  );

  useEffect(() => {
    getFunnelsCb();
  }, [getFunnelsCb]);

  const handleModal = () =>
    showCreateNewDialogBox({
      queryparams: generateQueryParams(),
      url: "/dashboard/funnels/new",
      customer: state?.customer,
      modaltype: "common.funnel"
    });
  return (
    <div>
      <PageHeading>Funnels</PageHeading>
      <div className="mt-3">
        {!asSubComponent && (
          <AdvancedFilters
            queryName="category"
            components={funnelFilterConfig}
            pageType="funnelType"
            pageName="funnelName"
            btnText={t("common.createNew.title", {
              name: "funnel"
            })}
            asSubComponent={asSubComponent}
            handleModal={handleModal}
            shouldDisplayResourceType
          />
        )}
        <Tabs
          id="justify-tabs"
          className="mt-4 mb-2"
          onSelect={(evKey) => handleSelectTab(evKey)}
          activeKey={
            searchParams.get("archived") === "true"
              ? DISPLAY_FUNNEL.ARCHIVED_ONES
              : DISPLAY_FUNNEL.NON_ARCHIVED_ONES
          }
        >
          <Tab
            eventKey={DISPLAY_FUNNEL.NON_ARCHIVED_ONES}
            title={t("funnelTable.all")}
          ></Tab>
          <Tab
            eventKey={DISPLAY_FUNNEL.ARCHIVED_ONES}
            title={t("common.archive")}
          ></Tab>
        </Tabs>
        <CustomCard className="mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn>Funnel {t("common.name")}</FirstHeaderColumn>
                <HeaderColumn>{t("common.form")}</HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("funnelTable.leadCount")}
                </HeaderColumn>
                <HeaderColumn>{t("common.type")}</HeaderColumn>
                <HeaderColumn>{t("common.template")}</HeaderColumn>
                <HeaderColumn>{t("funnelTable.lastChange")}</HeaderColumn>
                <HeaderColumn className="text-center">
                  {t("funnelTable.integrationStatus")}
                </HeaderColumn>
                <LastHeaderColumn
                  // className="pb-0 border-end border-top rounded-end"
                  empty
                ></LastHeaderColumn>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoader colSpan={8} />
              ) : (
                <FunnelTableRow
                  funnels={funnels}
                  getFunnels={getFunnels}
                  asSubComponent={asSubComponent}
                />
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
        {!asSubComponent ? (
          <CustomPagination
            paginateOpts={paginateOpts}
            activeTab={Number(searchParams.get("pageIndex")) || 1}
            select={(val) => handleQueryParams("pageIndex", val)}
          />
        ) : paginateOpts.totalDocs > 0 ? (
          <ViewMoreButton
            id={customerId}
            path="../../../funnels"
            searchParams={searchParams}
            resultsLength={funnels.length}
            totalDocs={paginateOpts.totalDocs}
            handleQueryParams={handleQueryParams}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default FunnelsTable;
