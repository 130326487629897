import * as Yup from "yup";

export const websiteSchema = (logoTypes) => {
  const imageSchema = Yup.object().shape({
    key: Yup.string().required("Required"),
    url: Yup.string().required("Required"),
    _id: Yup.string().required("Required"),
    asset: Yup.string().required("Required")
  });

  const linksSchema = Yup.object().shape({
    text: Yup.string().required("Required"),
    link: Yup.object().shape({
      name: Yup.string().required("Required"),
      component: Yup.string().required("Required")
    })
  });

  const brandColorsSchema = Yup.object().shape({
    logo_color: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    heading_dark: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    heading_light: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    text_primary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    text_secondary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    text_light: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    btn_primary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    btn_bg_primary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    btn_secondary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    btn_bg_secondary: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    primary_bg: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required"),
    secondary_bg: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .required("Color is required")
  });

  const headerSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true,
        then: Yup.string().required("Background color is required"),
        otherwise: Yup.string()
      }),
    logo: Yup.object().shape({
      type: Yup.object().shape({
        name: Yup.string().required("Type is required"),
        _id: Yup.string().required("Type is required")
      }),
      text: Yup.string().when("type", {
        is: (val) => val._id === logoTypes[0]._id,
        then: Yup.string().required("Logo text is required"),
        otherwise: Yup.string().notRequired()
      }),
      color: Yup.string().when("type", {
        is: (val) => val._id === logoTypes[0]._id,
        then: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Logo color is required"),
        otherwise: Yup.string().notRequired()
      }),
      image: Yup.mixed().when("type", {
        is: (val) => val._id === logoTypes[1]._id,
        then: Yup.mixed().required("Logo image is required"),
        otherwise: Yup.mixed().notRequired()
      })
    }),
    links: Yup.object().shape({
      links: Yup.array()
        .of(linksSchema)
        .min(4, "At least 4 links are required")
        .max(4, "Only 4 links are allowed")
        .required("Links are required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Links color is required")
    }),
    phone: Yup.object().shape({
      text: Yup.string().required("Text is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    address: Yup.object().shape({
      text: Yup.string().required("Text is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    })
  });

  const heroSectionSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true,
        then: Yup.string().required("Background color is required"),
        otherwise: Yup.string()
      }),
    heading: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    bullets: Yup.object().shape({
      data: Yup.array()
        .of(Yup.string().required("Each bullet is required"))
        .length(3, "Exactly 3 bullets are required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    image: Yup.mixed().required("Image is required").nullable(),
    btn: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      link: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required"),
      bgColor: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Background Color is required"),
      bgColorDisplay: Yup.boolean()
    })
  });

  const aboutSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    heading: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    images: Yup.array()
      .of(imageSchema)
      .required("Images are required")
      .min(3, "At least 3 images are required")
  });

  const singleServiceSchema = Yup.object().shape({
    heading: Yup.object().shape({
      text: Yup.string().required("Heading is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Heading color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("Paragraph is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Paragraph color is required")
    }),
    image: imageSchema
  });

  const serviceSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    heading: Yup.object().shape({
      text: Yup.string().required("Heading is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Heading color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("Paragraph is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Paragraph color is required")
    }),
    services: Yup.array()
      .of(singleServiceSchema)
      .min(3, "At least 3 services are required")
      .max(3, "Only 3 services are allowed")
      .required("Services are required")
  });

  const bannerSchema = Yup.object().shape({
    heading: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    btn: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      link: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required"),
      bgColor: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Background Color is required"),
      bgColorDisplay: Yup.boolean()
    }),
    bg_image: imageSchema
  });

  const singleKeypointSchema = Yup.object().shape({
    heading: Yup.object().shape({
      text: Yup.string().required("Heading is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Heading color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("Paragraph is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Paragraph color is required")
    })
  });

  const keyPointSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    heading: Yup.object().shape({
      text: Yup.string().required("Heading is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Heading color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("Paragraph is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Paragraph color is required")
    }),
    keyPoints: Yup.array()
      .of(singleKeypointSchema)
      .min(4, "At least 4 key points are required")
      .max(4, "Only 4 key points are allowed")
      .required("KeyPoints are required")
  });

  const secondBannerSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    heading: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    para: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    btn: Yup.object().shape({
      text: Yup.string().required("This Field is Required"),
      link: Yup.string().required("This Field is Required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required"),
      bgColor: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Background color is required"),
      bgColorDisplay: Yup.boolean()
    }),
    image: imageSchema
  });

  const mapSchema = Yup.object().shape({
    link: Yup.string().required("Link is Required")
  });

  const singleFaqSchema = Yup.object().shape({
    q: Yup.string().required("Question is required"),
    a: Yup.string().required("Answer is required")
  });

  const faqSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    active: Yup.boolean(),
    heading: Yup.object().when("active", {
      is: true,
      then: (schema) =>
        schema.shape({
          text: Yup.string().required("This Field is Required"),
          color: Yup.string()
            .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
            .required("Color is required")
        }),
      otherwise: (schema) =>
        schema.shape({
          text: Yup.string().notRequired(),
          color: Yup.string().notRequired()
        })
    }),
    para: Yup.object().when("active", {
      is: true,
      then: (schema) =>
        schema.shape({
          text: Yup.string().required("This Field is Required"),
          color: Yup.string()
            .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
            .required("Color is required")
        }),
      otherwise: (schema) =>
        schema.shape({
          text: Yup.string().notRequired(),
          color: Yup.string().notRequired()
        })
    }),
    faqs: Yup.object().when("active", {
      is: true,
      then: (schema) =>
        schema.shape({
          data: Yup.array()
            .of(singleFaqSchema)
            .min(1, "At least one FAQ is required")
            .max(10, "Maximum 10 faqs are allowed"),
          q: Yup.object().shape({
            color: Yup.string()
              .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
              .required("Color is required")
          }),
          a: Yup.object().shape({
            color: Yup.string()
              .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
              .required("Color is required")
          })
        }),
      otherwise: (schema) =>
        schema.shape({
          data: Yup.array().notRequired(),
          q: Yup.object().shape({
            color: Yup.string().notRequired()
          }),
          a: Yup.object().shape({
            color: Yup.string().notRequired()
          })
        })
    })
  });

  const footerSchema = Yup.object().shape({
    bgColorDisplay: Yup.boolean(),
    bgColor: Yup.string()
      .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
      .when("bgColorDisplay", {
        is: true, // When bgColorDisplay is true
        then: Yup.string().required("Background color is required"), // Make bgColor required
        otherwise: Yup.string() // Optional if bgColorDisplay is false
      }),
    logo: Yup.object().shape({
      type: Yup.object().shape({
        name: Yup.string().required("Type is required"),
        _id: Yup.string().required("Type is required")
      }),
      text: Yup.string().when("type", {
        is: (val) => val._id === logoTypes[0]._id,
        then: Yup.string().required("Logo text is required"),
        otherwise: Yup.string().notRequired()
      }),
      color: Yup.string().when("type", {
        is: (val) => val._id === logoTypes[0]._id,
        then: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Logo color is required"),
        otherwise: Yup.string().notRequired()
      }),
      image: Yup.mixed().when("type", {
        is: (val) => val._id === logoTypes[1]._id,
        then: Yup.mixed().required("Logo image is required"),
        otherwise: Yup.mixed().notRequired()
      })
    }),
    tag_line: Yup.object().shape({
      text: Yup.string().required("Text is required"),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    facebook: Yup.object().shape({
      link: Yup.string(),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    instagram: Yup.object().shape({
      link: Yup.string(),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    youtube: Yup.object().shape({
      link: Yup.string(),
      color: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
        .required("Color is required")
    }),
    footer_links: Yup.object().shape({
      quick_links: Yup.object().shape({
        links: Yup.array()
          .of(linksSchema)
          .min(4, "At least 4 links are required")
          .max(4, "Only 4 links are allowed")
          .required("Links are required"),
        text: Yup.string().required("Required"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Links color is required")
      }),
      pp_links: Yup.object().shape({
        links: Yup.array()
          .of(
            Yup.object().shape({
              text: Yup.string().required("Required")
            })
          )
          .min(2, "At least 2 links are required")
          .max(2, "Only 2 links are allowed")
          .required("Links are required"),
        text: Yup.string().required("Required"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Links color is required")
      })
    }),
    contact_card: Yup.object().shape({
      text: Yup.string().required("Required"),
      name: Yup.object().shape({
        text: Yup.string().required("This Field is Required"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Color is required")
      }),
      title: Yup.object().shape({
        text: Yup.string().required("This Field is Required"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Color is required")
      }),
      email: Yup.object().shape({
        text: Yup.string().email("Invalid Email"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Color is required")
      }),
      btn: Yup.object().shape({
        text: Yup.string().required("This Field is Required"),
        link: Yup.string().required("This Field is Required"),
        color: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Color is required"),
        bgColor: Yup.string()
          .matches(/^#[0-9A-Fa-f]{6}$/, "Invalid color format")
          .required("Background color is required"),
        bgColorDisplay: Yup.boolean()
      }),
      image: Yup.mixed().required("Image is required")
    })
  });

  const sectionValidationSchema = {
    hero: heroSectionSchema,
    about: aboutSchema,
    service: serviceSchema,
    banner: bannerSchema,
    keyPoint: keyPointSchema,
    secondBanner: secondBannerSchema,
    map: mapSchema,
    faq: faqSchema
  };

  const schema = Yup.object().shape({
    domain: Yup.string()
      .required("Domain is required")
      .matches(
        /^(?!https?:\/\/)/,
        "Protocol not allowed. Remove http:// or https://"
      )
      .matches(/^(?!www\.)/, 'Remove "www." from the domain')
      .matches(
        /^[a-z0-9.-]+$/,
        "Only lowercase letters, numbers, dot, and hyphen are allowed"
      )
      .matches(
        /^[^!@#$%^&*()_+={}[\]:;"'<>,?/|\\]+$/,
        "Special characters are not allowed"
      )
      .test("single-dot", "Sub domain not allowed", (value) =>
        value ? (value.match(/\./g) || []).length === 1 : false
      )
      .matches(/^[^.].*[^.]$/, "Dot cannot be at the start or end")
      .max(63, "Domain must be at most 63 characters long"),
    // subDomain: Yup.string().required("Required"),
    brand_colors: brandColorsSchema,
    navbar: headerSchema,
    sections: Yup.array()
      .of(
        Yup.object().shape({
          component: Yup.string()
            .oneOf(
              Object.keys(sectionValidationSchema),
              "Invalid section component"
            )
            .required("Section component is required"),
          data: Yup.lazy((value, options) => {
            const sectionKey = options?.parent?.component;
            const validationSchema = sectionValidationSchema[sectionKey];
            if (validationSchema) {
              return validationSchema;
            }
            return Yup.object();
          })
        })
      )
      .min(1, "At least one section is required"),
    footer: footerSchema
  });

  return schema;
};

export default websiteSchema;
