import React, { useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Legend } from "../../common-styling";
import { Button, Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";
import TextArea from "../../reusable-form-fields/text-area";

const AboutSection = ({
  parseResourceSrc,
  parsePreviewResourceSrc,
  index,
  isFormChanged
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    control,
    register
  } = useFormContext();

  const {
    fields: aboutImages,
    append: addImage,
    remove: removeImage
  } = useFieldArray({
    control,
    name: `sections[${index}].data.images` // Path to the nested array
  });

  const AboutBGColorDisplay = watch(`sections[${index}].data.bgColorDisplay`);
  const aboutBgColorPickerRef = useRef();
  const aboutHeadingColorPickerRef = useRef();
  const aboutParaColorPickerRef = useRef();

  return (
    <>
      <Row className="mt-1">
        <Col md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.BGColor")}
            fieldName={`sections[${index}].data.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bgColor
            }
            fieldRef={aboutBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bgColor`)}
            displayFieldWatcher={AboutBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
            isDisabled={Boolean(!AboutBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <TextField
            label={t("customerWebsite.heading")}
            placeholder={t("customerWebsite.heading")}
            type="text"
            name={`sections[${index}].data.heading.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.heading?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.headingColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.heading.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.heading?.color
            }
            fieldRef={aboutHeadingColorPickerRef}
            fieldVal={watch(`sections[${index}].data.heading.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <TextArea
            label={t("customerWebsite.para")}
            name={`sections[${index}].data.para.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.para?.text
            }
            register={register}
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.paraColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.para.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.para?.color
            }
            fieldRef={aboutParaColorPickerRef}
            fieldVal={watch(`sections[${index}].data.para.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6}>
          <Legend>
            <h6>{t("customerWebsite.images")}</h6>
          </Legend>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button
            variant="primary"
            type="button"
            onClick={() =>
              addImage({
                key: "",
                url: "",
                _id: "",
                asset: ""
              })
            }
          >
            {t("customerWebsite.addImage")}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        {aboutImages.map((image, i) => (
          <Col xs={12} md={4} key={image.id}>
            <FileInput
              label={`${t("customerWebsite.image")} #${i + 1}`}
              name={`sections[${index}].data.images[${i}]`}
              // resourceId={resourceId}
              parentFolderName={"customer"}
              url={parseResourceSrc(
                watch(`sections[${index}].data.images[${i}]`)
              )}
              urlForPreview={parsePreviewResourceSrc(
                watch(`sections[${index}].data.images[${i}]`)
              )}
              asset={watch(`sections[${index}].data.images[${i}]`)?.asset}
              error={
                errors?.sections &&
                errors?.sections[index]?.data?.images &&
                errors?.sections[index]?.data?.images[i]?._id
              }
              isRemoveAllowed
              removeId={i}
              removeImage={removeImage}
              removeBtnDisabled={aboutImages.length === 3}
              isFormChanged={isFormChanged}
              tagName="website_überuns"
              // selectedCategory={getValues("category")}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AboutSection;
