import { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col, Form, Modal, Button, Container } from "react-bootstrap";
import TextField from "../reusable-form-fields/text-field";
import { duplicateCustomerWebTemplate } from "../../services/api-declaration";
import SpinnerButton from "../spinner-button/spinner-button";
import { showSuccess, showWarning } from "../../services/toast-service";
import { useTranslation } from "react-i18next";
import SelectField from "../reusable-form-fields/select-field";
import FileInput from "../reusable-form-fields/input-file";
import { THUMBNAIL_TYPE } from "../strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncCreatableSelectField from "../reusable-form-fields/creatable-async-select";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ValidationMessage from "../validation-message/validation-message";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useNotification } from "../../hooks/useNotification";
import WebsiteReplaceDialog from "./website-replace-dialog";
import DuplicateWebsiteDomainSection from "../reusable-form-section/duplicate-website-domain-section";

const WebsiteDuplicateDialog = ({
  show,
  mainFormValues,
  toggleDialog,
  customerId,
  templateType
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useLocation();
  const [isDomainValid, setIsDomainValid] = useState(true);
  const btnRef = useRef(null);
  const { showToastWithLink } = useNotification();
  const [replaceTemplateData, setReplaceTemplateData] = useState(null);
  const [showreplaceDialog, setShowReplaceDialog] = useState(false);

  const logoTypes = [
    { name: t("customerWebsite.text"), _id: "text" },
    { name: t("customerWebsite.image"), _id: "image" }
  ];

  const toggleReplaceDialog = () => {
    setShowReplaceDialog((prev) => !prev);
  };

  const displayLinkToast = ({ customerId, toastType, toastMsg }) =>
    showToastWithLink({
      url: `/dashboard/customers/${customerId}/website`,
      msg: toastMsg,
      showToast: toastType
    });

  const { handleCustomerOptions } = useOptionsFetch();

  const parseResourceSrc = (resourceObj) =>
    resourceObj?.key
      ? `${process.env.REACT_APP_ASSET_CDN_URL}/${resourceObj.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";
  const parsePreviewResourceSrc = (resourceObj) =>
    resourceObj?.key ? `${resourceObj.url}/${resourceObj.key}` : "";

  const onSubmit = async (formVals) => {
    setIsSubmitting(true);

    const template = {
      domain: formVals.domain,
      cloneFromCustomer: customerId,
      logo: formVals.logo
    };

    try {
      // Send API request
      await duplicateCustomerWebTemplate(formVals.customer._id, template);

      displayLinkToast({
        customerId: formVals.customer._id,
        toastType: showSuccess,
        toastMsg: "Website Cloned Successfully"
      });
      toggleDialog();
    } catch (error) {
      console.error("Error submitting form:", error);
      showWarning(error.response.data.message);
      if (error.response.status === 409) {
        setReplaceTemplateData({
          template,
          customerId: formVals.customer._id,
          templateId: error.response.data.data.templateId
        });
        toggleReplaceDialog();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const YupCustomerSelectFieldValidation = Yup.lazy((value) => {
    if (value) {
      switch (typeof value) {
        case "string":
          return Yup.string().required("Required").nullable();
        case "object":
          return Yup.object().shape({
            _id: Yup.string()
              .required("Required")
              .nullable()
              .test(
                "not-equal-customerId",
                "Please Select Different Customer",
                (id) => id !== customerId
              )
          });
        default:
          return Yup.mixed();
      }
    } else {
      return Yup.string().required("Required").nullable();
    }
  });

  const duplicateWebsiteSchema = Yup.object().shape({
    customer: YupCustomerSelectFieldValidation,
    domain: Yup.string()
      .required("Domain is required")
      .matches(
        /^(?!https?:\/\/)/,
        "Protocol not allowed. Remove http:// or https://"
      )
      .matches(/^(?!www\.)/, 'Remove "www." from the domain')
      .matches(
        /^[a-z0-9.-]+$/,
        "Only lowercase letters, numbers, dot, and hyphen are allowed"
      )
      .matches(
        /^[^!@#$%^&*()_+={}[\]:;"'<>,?/|\\]+$/,
        "Special characters are not allowed"
      )
      .test("single-dot", "Sub domain not allowed", (value) =>
        value ? (value.match(/\./g) || []).length === 1 : false
      )
      .matches(/^[^.].*[^.]$/, "Dot cannot be at the start or end")
      .max(63, "Domain must be at most 63 characters long"),
    logo: Yup.object().shape({
      type: Yup.object().shape({
        name: Yup.string().required("Type is required"),
        _id: Yup.string().required("Type is required")
      }),
      text: Yup.string().when("type", {
        is: (val) => val._id === logoTypes[0]._id,
        then: Yup.string().required("Logo text is required"),
        otherwise: Yup.string().notRequired()
      }),
      image: Yup.mixed().when("type", {
        is: (val) => val._id === logoTypes[1]._id,
        then: Yup.mixed().required("Logo image is required"),
        otherwise: Yup.mixed().notRequired()
      })
    })
  });

  const methods = useForm({
    resolver: yupResolver(duplicateWebsiteSchema)
    // defaultValues: initialValues
  });
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors }
  } = methods;

  const logoType = watch("logo.type");
  const logoImage = watch("logo.image");

  return (
    <>
      <Modal size="lg" show={show} onHide={toggleDialog} centered>
        <Modal.Header className="bg-success text-white">
          <h5 className="m-0">{t("customerWebsite.cloneDialogHeading")}</h5>
        </Modal.Header>
        <Modal.Body>
          <FormProvider {...methods} id="template-form">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Container className="px-0">
                <Row className="mb-3">
                  <Col sm={12} md={12}>
                    <div className="d-flex align-items-end">
                      <Form.Group controlId="customer" className="flex-fill">
                        <Form.Label
                          className={errors?.customer && "text-danger"}
                        >
                          {t("common.customer")}
                          {errors?.customer && "*"}
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <AsyncCreatableSelectField
                            control={control}
                            name="customer"
                            getOptionLabel={(opt) =>
                              `${opt.firstName} ${opt.lastName} (${opt.email})`
                            }
                            fetchData={handleCustomerOptions}
                            isDisabled={state?.navigatedCustomer}
                            capitalized={false}
                          />
                        </div>
                        <ValidationMessage
                          error={
                            errors?.customer?.message ||
                            errors?.customer?._id?.message
                          }
                        />
                      </Form.Group>
                      {watch("customer")?._id && (
                        <Button
                          as={Link}
                          target="_blank"
                          className="mb-1 ms-3"
                          variant="outline-secondary"
                          to={`../../../customers/${
                            getValues("customer")._id
                          }/overview`}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                <DuplicateWebsiteDomainSection
                  btnRef={btnRef}
                  isDomainValid={isDomainValid}
                  setIsDomainValid={setIsDomainValid}
                  inDialog
                  noHeading
                  customerId={customerId}
                  selectedCustomerId={getValues("customer")?._id}
                  // setShouldUpdateNonActive={setShouldUpdateNonActive}
                />
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <Form.Group controlId="land">
                      <Form.Label>
                        {t("customerWebsite.logo.logoType")}
                      </Form.Label>
                      <SelectField
                        control={control}
                        name="logo.type"
                        options={logoTypes}
                        getValues={getValues}
                        errors={errors?.logo?.type}
                      />
                    </Form.Group>
                  </Col>

                  {logoType?._id === logoTypes[0]._id ? (
                    <>
                      <Col sm={12} md={6}>
                        <TextField
                          label={t("customerWebsite.logo.logoText")}
                          placeholder={t("customerWebsite.logo.logoText")}
                          type="text"
                          name="logo.text"
                          errors={errors?.logo?.text}
                          register={register}
                          noSpacing
                        />
                      </Col>
                    </>
                  ) : logoType?._id === logoTypes[1]._id ? (
                    <Col xs={12} md={6}>
                      <FileInput
                        label={t("customerWebsite.logo.logoImage")}
                        name="logo.image"
                        // resourceId={resourceId}
                        parentFolderName={"customer"}
                        url={parseResourceSrc(logoImage)}
                        urlForPreview={parsePreviewResourceSrc(logoImage)}
                        asset={logoImage?.asset}
                        error={errors?.logo?.image}
                        // selectedCategory={getValues("category")}
                        // tagName="backgroundimage"
                      />
                    </Col>
                  ) : null}
                </Row>
              </Container>
              <hr />
              <div className="d-flex flex-row-reverse">
                <Button
                  className="ms-2"
                  variant="outline-secondary"
                  onClick={() => toggleDialog()}
                >
                  {t("common.cancel")}
                </Button>
                {isSubmitting ? (
                  <SpinnerButton
                    btnText="Saving..."
                    btnStyle="btn-outline-success"
                  />
                ) : (
                  <Button
                    ref={btnRef}
                    type="submit"
                    variant="outline-success"
                    disabled={!isDomainValid}
                  >
                    {t("common.save")}
                  </Button>
                )}
              </div>
            </Form>
          </FormProvider>
        </Modal.Body>
      </Modal>

      {showreplaceDialog && (
        <WebsiteReplaceDialog
          show={showreplaceDialog}
          toggleDuplicateDialog={toggleDialog}
          toggleDialog={toggleReplaceDialog}
          data={replaceTemplateData}
        />
      )}
    </>
  );
};
export default WebsiteDuplicateDialog;
