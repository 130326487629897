import styled from "styled-components";
import { getEuroCurrencyData } from "../../services/common-service";
import { CustomCard } from "../common-styling";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StatsHeadings = styled.div`
  color: var(--text-gray-muted);
  font-weight: 600;
  margin-bottom: 5px;
`;

const Sub = styled.sub`
  font-size: 10px;
  font-weight: 800;
  word-spacing: 0.6rem;
`;

export const ReportStatistics = ({
  leadCount,
  costPerLead,
  spend,
  costPerQualifiedLead,
  leadQualifiedCount,
  conversionRate
}) => {
  const { t } = useTranslation();
  return (
    <>
      <StatsHeadings>Statistics</StatsHeadings>
      <CustomCard className="w-100 px-4 py-3">
        <Row className="d-flex justify-content-between mb-3">
          <Col className="px-0" xs={6} lg={12} xl={5}>
            <StatsHeadings>Leads</StatsHeadings>
            <h3 className="mb-0 fw-bold">{leadCount}</h3>
          </Col>
          <Col className="px-0" xs={6} lg={12} xl={6}>
            <StatsHeadings>
              {t("advancedFilter.fbSort.costPerLead")}
            </StatsHeadings>
            <h3 className="mb-0 fw-bold">{getEuroCurrencyData(costPerLead)}</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between mb-3">
          <Col className="px-0" xs={6} lg={12} xl={5}>
            <StatsHeadings>{t("customerStats.budget")}</StatsHeadings>
            <h2 className="mb-0 fs-6 fw-bold ">{getEuroCurrencyData(spend)}</h2>
          </Col>
          <Col className="px-0" xs={6} lg={12} xl={6}>
            <StatsHeadings>{t("leadAgent.qualified")}</StatsHeadings>
            <div className="d-flex gap-2">
              {leadQualifiedCount && (
                <h2 className="mb-0 fs-6 fw-bold ">
                  {leadQualifiedCount + " "}
                  <Sub className="bottom-0">
                    ({Math.round((leadQualifiedCount / leadCount) * 100) || 0}
                    %)
                  </Sub>
                </h2>
              )}
              <h2 className="mb-0 fs-6 fw-bold">
                {getEuroCurrencyData(costPerQualifiedLead)}
              </h2>
            </div>
          </Col>
        </Row>
        {(conversionRate || conversionRate === 0) && (
          <Row className="d-flex justify-content-between">
            <Col className="px-0" xs={6} lg={12} xl={5}>
              <StatsHeadings>{t("customerStats.conversionRate")}</StatsHeadings>
              <h2 className="mb-0 fs-6 fw-bold ">{conversionRate}%</h2>
            </Col>
          </Row>
        )}
      </CustomCard>
    </>
  );
};
