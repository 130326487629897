import _debounce from "lodash/debounce";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  CustomCard,
  ValiditySpinner,
  HorizontalDivider
} from "../common-styling";
import Loader from "../loader/loader";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import {
  createCustomer,
  updateCustomer,
  getCustomerById,
  getCustomerByEmail,
  validateContactCloudId
} from "../../services/api-declaration";
import CityForm from "../city/city-form";
import { BASE_COUNTRY } from "../strings";
import NavigateBackButton from "../back-button";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import SpinnerButton from "../spinner-button/spinner-button";
import { countryOptions } from "../common-confgs/common-vars";
import FormModal from "../create-new-modal/new-customer-modal";
import { CustomerFormSchema } from "../../services/validation-schema";
import ValidationMessage from "../validation-message/validation-message";
import ContactDetailsCommonFields from "../reusable-form-fields/contact-details-common-fields";
import { useTranslation } from "react-i18next";

const CustomerForm = ({
  fromModal = false,
  initialValue = null,
  createCustomerCallback = () => {}
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const saveBtnRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [createCityText, setCreateCityText] = useState("");
  const [allowAddNew, setAllowAddNew] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const initialValues = {
    firstName: initialValue,
    lastName: "",
    companyName: "",
    email: "",
    address: "",
    country: {
      name: "Deutschland",
      _id: BASE_COUNTRY
    },
    city: null,
    zipCode: "",
    phone: "",
    hubspotId: "",
    amKundennummer: "",
    contactCloudId: ""
  };
  const methods = useForm({
    resolver: yupResolver(CustomerFormSchema),
    defaultValues: initialValues
  });
  const {
    formState: { errors },
    reset,
    getValues,
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = methods;

  const handleEmailChange = (fieldVal) => {
    if (fieldVal) {
      console.log(saveBtnRef.current);
      saveBtnRef.current.disabled = true;
      setIsEmailChanged(true);
    }
  };
  const handleDebounceCb = useMemo(
    () =>
      _debounce(async (email) => {
        try {
          if (email) {
            const response = await getCustomerByEmail({ email });
            if (response.data.data && response.data.data._id !== params.id) {
              setIsEmailValid(false);
              saveBtnRef.current.disabled = true;
              showWarning(`Email: ${email} ${t("common.isAlreadyTaken")}`);
            } else {
              saveBtnRef.current.disabled = false;
              setIsEmailValid(true);
            }
          }
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message ||
              t("toastMessage.errorInGetting", {
                what: t("toastMessage.categoryByID")
              })
          );
        } finally {
          setIsEmailChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [setIsEmailValid, saveBtnRef, params.id]
  );
  const updateInitialValues = async () => {
    try {
      setIsLoading(true);
      const response = await getCustomerById(params.id);
      const customer = response.data.data;
      if (customer.country) {
        const countryObj = countryOptions.find(
          (item) => item.name === customer.country // matching by name because from hubspot, name will be sent
        );
        customer.country = countryObj;
      }
      reset({ ...initialValues, ...customer });
    } catch (err) {
      console.log(err);
      showError(
        err.response.data.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.customer")
          })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const navigateToOverview = (data) =>
    navigate(
      { pathname: `../${data._id}/overview` },
      { state: { customer: data } }
    );
  const validateIdFromContactCloud = async (contactCloudId) => {
    try {
      setIsValidating(true);
      const validateResponse = await validateContactCloudId({ contactCloudId });
      if (!validateResponse.data?.data?.tenantExists) {
        setError(
          "contactCloudId",
          { type: "focus", message: t("invalidIdErrorMessage") },
          { shouldFocus: true }
        );
        return false;
      } else {
        clearErrors("contactCloudId");
        return true;
      }
    } catch (error) {
      showError("Error in validating contact cloud id");
    } finally {
      setIsValidating(false);
    }
  };
  const onSubmit = (formValues) => {
    if (formValues.city?.hasOwnProperty.call(formValues.city, "value")) {
      formValues.city = formValues.city.value;
    }
    if (formValues.country) {
      formValues.country = formValues.country.name;
    }
    handleSubmitCustomer(formValues);
  };
  const handleSubmitCustomer = async (formData) => {
    try {
      if (formData.contactCloudId) {
        const validated = await validateIdFromContactCloud(
          formData.contactCloudId
        );
        if (!validated) return;
      }
      setIsSubmitting(true);
      if (params.id && !fromModal) {
        const response = await updateCustomer(params.id, formData);
        if (response) {
          showSuccess(response.data.message);
          navigateToOverview(formData);
        }
      } else {
        const response = await createCustomer(formData);
        if (response) {
          showSuccess(response.data.message);
          fromModal
            ? createCustomerCallback(response.data.data)
            : navigateToOverview(response.data.data);
        }
      }
    } catch (err) {
      console.log(err);
      showError(err.response.data.message || t("toastMessage.saveCustomer"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleCreateCityText = (cityName) => setCreateCityText(cityName);
  const handleAllowAddNew = (flag) => setAllowAddNew(flag);
  const customerCb = useCallback(
    updateInitialValues,
    // eslint-disable-next-line
    [params.id, reset]
  );
  useEffect(() => {
    if (params.id) {
      customerCb();
    }
  }, [customerCb, params.id]);

  return params.id && isLoading ? (
    <Loader />
  ) : (
    <div className={fromModal ? "mb-0" : "mb-5"}>
      <FormProvider {...methods}>
        <PreventReload />
        <form
          onSubmit={(event) => {
            handleSubmit(onSubmit)(event);
          }}
        >
          {!fromModal && (
            <div
              className={`${
                fromModal ? "py-2" : "pt-4 pb-4"
              } px-0 d-flex align-items-center`}
            >
              {params.id && (
                <div className="me-3">
                  <NavigateBackButton />
                </div>
              )}
              <h5 className="mb-0 me-4">{t("customerForm")}</h5>
            </div>
          )}
          <CustomCard>
            <div className={fromModal ? "p-3" : "p-4"}>
              <div className="mb-3">
                <h5 className="fw-bold mb-2 text-secondary">
                  {t("infoDetails")}
                </h5>
                <Row>
                  <Col xs={12} md={6}>
                    <TextField
                      label={t("common.firstName")}
                      placeholder={t("common.firstName")}
                      type="text"
                      name="firstName"
                      errors={errors?.firstName}
                      register={register}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <TextField
                      label={t("common.lastName")}
                      placeholder={t("common.lastName")}
                      type="text"
                      name="lastName"
                      errors={errors?.lastName}
                      register={register}
                    />
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label className={errors?.email && "text-danger"}>
                      {t("common.email")}
                      {errors?.email && "*"}
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            type="email"
                            placeholder="Email"
                            value={getValues("email")}
                            onChange={(ev) => {
                              field.onChange(ev);
                              handleEmailChange(ev.target.value);
                              handleDebounceCb(ev.target.value);
                            }}
                            className={errors?.email && "border-danger"}
                          />
                        )}
                      />
                      {isEmailChanged && (
                        <div className="align-self-center">
                          <ValiditySpinner
                            animation="border"
                            variant="secondary"
                            className="ms-3 rounded-circle"
                          />
                        </div>
                      )}
                    </div>
                    {!isEmailValid && !isEmailChanged && (
                      <small className="mt-1 text-danger fw-bold">
                        <FontAwesomeIcon
                          className="me-2"
                          icon={faExclamationCircle}
                        />
                        {t("common.alreadyTaken")}
                      </small>
                    )}
                    {errors?.email && (
                      <ValidationMessage error={errors?.email.message} />
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <TextField
                      label={t("common.companyName")}
                      placeholder={t("common.companyName")}
                      type="text"
                      name="companyName"
                      register={register}
                    />
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <h5 className="fw-bold mb-2 text-secondary">
                  {t("contactDetails")}
                </h5>
                <ContactDetailsCommonFields
                  handleAllowAddNew={handleAllowAddNew}
                  handleCreateCityText={handleCreateCityText}
                />
                <Row>
                  <Col xs={12} md={6}>
                    <TextField
                      label={t("common.telephone")}
                      placeholder={t("common.telephone")}
                      type="text"
                      name="phone"
                      register={register}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <TextField
                      label={`A&M ${t("customerNumber")}`}
                      placeholder={`Enter A&M ${t("customerNumber")}`}
                      type="text"
                      name="amKundennummer"
                      register={register}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs={12} md={6}>
                    <TextField
                      label={t("hubspotID")}
                      placeholder={`Enter ${t("hubspotID")}`}
                      type="text"
                      name="hubspotId"
                      register={register}
                      noSpacing
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="d-flex align-items-end">
                      <div className="flex-fill">
                        <TextField
                          label={t("contactCloudID")}
                          placeholder={`Enter ${t("contactCloudID")}`}
                          type="text"
                          name="contactCloudId"
                          errors={errors?.contactCloudId}
                          register={register}
                          noSpacing
                        />
                      </div>
                      {isValidating && (
                        <div className="ms-3 mb-2">
                          <Spinner
                            size="sm"
                            animation="border"
                            className="text-primary"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <HorizontalDivider />
              <div className="mt-3 d-flex justify-content-end">
                {!fromModal && (
                  <Button
                    className="me-2"
                    onClick={() => navigate(-1)}
                    variant="outline-secondary"
                  >
                    {t("common.cancel")}
                  </Button>
                )}
                {isSubmitting ? (
                  <SpinnerButton
                    btnText={`${t("common.save")}...`}
                    btnStyle="btn-success"
                  />
                ) : (
                  <Button
                    type="submit"
                    ref={saveBtnRef}
                    variant="outline-success"
                    disabled={isValidating}
                  >
                    {t("common.save")}
                  </Button>
                )}
              </div>
            </div>
          </CustomCard>
        </form>
      </FormProvider>
      <FormModal
        show={allowAddNew}
        onHide={() => handleAllowAddNew(false)}
        modalHeading={t("createNewCity")}
        modalSize="md"
      >
        <CityForm
          createCityText={createCityText}
          handleAllowAddNew={handleAllowAddNew}
          setValue={setValue}
        />
      </FormModal>
    </div>
  );
};
export default CustomerForm;
