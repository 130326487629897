import {
  Row,
  Col,
  Form,
  Fade,
  Button,
  Spinner,
  Collapse
} from "react-bootstrap";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  SEND_DATA,
  PRICE_TYPE,
  DISBALE_LS_ACTIONS,
  LEAD_SERVICE_PLUS_EDIT_MODES
} from "../strings";
import {
  toggleLeadService,
  toggleLeadServicePlus,
  toggleAlternativeAddress,
  updateAlternativeAddress,
  syncNowOnDisableLeadservice,
  getAfterConfirmationIntegrationIdsByFunnel
} from "../../services/api-declaration";
import CityForm from "../city/city-form";
import { FieldSet } from "../common-styling";
import { useDialog } from "../../hooks/useDialog";
import PreventReload from "../funnel-form/prevent-reload";
import TextField from "../reusable-form-fields/text-field";
import {
  countryOptions,
  leadServiceQuestions
} from "../common-confgs/common-vars";
import FormModal from "../create-new-modal/new-customer-modal";
import PriceTypeRadio from "./integration-view/price-type-radio";
import { showError, showSuccess } from "../../services/toast-service";
import NumberInputGroup from "../reusable-form-fields/number-input-group";
import { LeadServiceCompanyNameSchema } from "../../services/validation-schema";
import LeadServiceActionsSection from "./integration-view/lead-service-actions-section";
import ContactDetailsCommonFields from "../reusable-form-fields/contact-details-common-fields";
import { Trans, useTranslation } from "react-i18next";
import SelectField from "../reusable-form-fields/select-field";

const questions = leadServiceQuestions;

const LeadService = ({
  toggleCb,
  emailIntegrations,
  zapierIntegrations,
  contactCloudIntegrations
}) => {
  const {
    FOR_COMPANY,
    FOR_PRODUCT,
    FOR_PRICE,
    FOR_FIXED_PRICE,
    FOR_UNIT,
    FOR_BUDGET_QUERY,
    FOR_PRICES_PER_AREA,
    FOR_QUESTIONS
  } = LEAD_SERVICE_PLUS_EDIT_MODES;
  const { DYNAMIC, FIXED, AREA_QUERY } = PRICE_TYPE;
  const { t } = useTranslation();
  const {
    showConfirmationDialogBox,
    showDisableLeadServiceConfirmationDialogBox
  } = useDialog();
  const id = useParams().id;
  const { funnel, setFunnel } = useOutletContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdatingAlternativeAddress, setIsUpdatingAlternativeAddress] =
    useState(false);
  const [editMode, setEditMode] = useState({
    [FOR_COMPANY]: false,
    [FOR_PRODUCT]: false,
    [FOR_PRICE]: false,
    [FOR_FIXED_PRICE]: false,
    [FOR_UNIT]: false,
    [FOR_BUDGET_QUERY]: false,
    [FOR_PRICES_PER_AREA]: false,
    [FOR_QUESTIONS]: false
  });
  const [createCityText, setCreateCityText] = useState("");
  const [allowAddNew, setAllowAddNew] = useState(false);

  const methods = useForm({
    resolver: yupResolver(LeadServiceCompanyNameSchema),
    defaultValues: {
      enabled: funnel.leadService,
      enabledPlus: funnel.leadServicePlus || false,
      companyNameForLeadAgent:
        funnel.companyNameForLeadAgent || funnel.customer?.companyName || "",
      leadServiceProductName:
        funnel.leadServiceProductName || funnel.category?.name || "",
      leadServicePlusPriceType: funnel.leadServicePlusPriceType || DYNAMIC,
      pricePerInvoiceUnitForLeadAgent:
        funnel.pricePerInvoiceUnitForLeadAgent || 0,
      fixedPricePerInvoiceUnitForLeadAgent:
        funnel.fixedPricePerInvoiceUnitForLeadAgent || 0,
      nameOfAccountingUnitForLeadAgent:
        funnel.nameOfAccountingUnitForLeadAgent || funnel.category?.unit || "",
      alternativeAddressEnabled: funnel.alternativeAddress?.enabled || false,
      minBudgetQuery: funnel.minBudgetQuery || 0,
      maxBudgetQuery: funnel.maxBudgetQuery || 0,
      pricePerAreaBelowMinBudget: funnel.pricePerAreaBelowMinBudget || 0,
      pricePerAreaBetweenBudget: funnel.pricePerAreaBetweenBudget || 0,
      pricePerAreaAboveMaxBudget: funnel.pricePerAreaAboveMaxBudget || 0,
      question1: funnel.leadServiceQuestions?.[0] || null,
      question2: funnel.leadServiceQuestions?.[1] || null,
      question3: funnel.leadServiceQuestions?.[2] || null,
      leadServiceQuestions: funnel.leadServiceQuestions || []
    }
  });
  const alternativeAddressCountry = funnel.alternativeAddress?.country
    ? funnel.alternativeAddress.country
    : funnel.customer.country;
  const methodsForAlternativeAddressFields = useForm({
    defaultValues: {
      country:
        countryOptions.find(
          (item) => item.name === alternativeAddressCountry
        ) || "",
      city: funnel.alternativeAddress?.city || funnel.customer.city || "",
      address:
        funnel.alternativeAddress?.address || funnel.customer?.address || "",
      zipCode:
        funnel.alternativeAddress?.zipCode ||
        String(funnel.customer?.zipCode) ||
        ""
    }
  });
  const {
    watch,
    control,
    setValue,
    register,
    formState: { errors, dirtyFields }
  } = methods;
  const {
    handleSubmit,
    setValue: setAlternativeAddressFields,
    reset: resetAlternativeAddressFields,
    formState: { dirtyFields: dirtyFieldsForAlternativeAddress }
  } = methodsForAlternativeAddressFields;

  const handleQuestionChange = (field, selectedOption) => {
    setValue(field, selectedOption);
  };
  const getAvailableQuestions = (currentField) => {
    return questions.filter(
      (question) =>
        !Object.values(watch()).some((q) => q?.id === question.id) ||
        watch(currentField)?.id === question.id
    );
  };
  const isOptionDisabled = (option) => {
    return Object.values(watch()).some((q) => q?.id === option.id);
  };

  const checkZapierOccurrence = (_integration) =>
    zapierIntegrations.some(
      (item) => item.sourceData.meta.sendData === SEND_DATA.AFTER_CONFIRMATION
    );
  const checkSendDataTypeOccurrence = (_checked) => {
    const flags = { forEmail: false, forContactCloud: false, forZapier: false };
    const sendDataValue = _checked
      ? SEND_DATA.IMMEDIATELY
      : SEND_DATA.AFTER_CONFIRMATION;
    if (
      emailIntegrations.length &&
      emailIntegrations[0].sourceData.meta.sendData === sendDataValue
    ) {
      flags.forEmail = true;
    }
    if (
      contactCloudIntegrations.length &&
      contactCloudIntegrations[0].sourceData.meta.sendData === sendDataValue
    ) {
      flags.forContactCloud = true;
    }
    if (
      zapierIntegrations.length &&
      ((_checked && !checkZapierOccurrence()) ||
        (!_checked && checkZapierOccurrence()))
    ) {
      flags.forZapier = true;
    }
    return flags;
  };
  const handleToggleLeadService = async ({ payload, forPlus }) => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);
      let response = {};
      if (forPlus) {
        response = await toggleLeadServicePlus(payload, id);
      } else {
        response = await toggleLeadService(payload, id);
      }
      showSuccess(response.data.message);
    } catch (error) {
      showError(t("leadServiceError"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleLeadServiceToggle = async (_checked) => {
    let data = undefined;
    let associatedLeadsCount = 0;
    if (!_checked) {
      // check integration association in leads
      try {
        const response = await getAfterConfirmationIntegrationIdsByFunnel(id);
        data = response.data.data;
        associatedLeadsCount = data.leadsCount;
      } catch (error) {
        console.log("error in fetching integrations", error);
      }
    }
    if (associatedLeadsCount) {
      showDisableLeadServiceConfirmationDialogBox({
        title: t("disableLeadServiceModal.heading"),
        dialogType: "danger",
        dialogMessage: renderAssociatedLeadsDialogMessage(associatedLeadsCount),
        responseAction: (action, btnEventType) =>
          disableLeadserviceAndUpdateLeadsResponse({
            action,
            btnEventType,
            _checked,
            ids: data.integrationIds
          })
      });
    } else {
      toggleLeadServiceConfirmation(_checked);
    }
  };
  const renderAssociatedLeadsDialogMessage = (count) => (
    <Trans
      i18nKey={t("disableLeadServiceModal.associatedIntegrationMsg", { count })}
    />
  );
  const handleSyncNowAndDisable = async (integrationIds) => {
    try {
      await syncNowOnDisableLeadservice(integrationIds);
    } catch (error) {
      showError(error.response?.data?.message || "Error in sync leads");
    }
  };
  const disableLeadserviceAndUpdateLeadsResponse = async ({
    action,
    btnEventType,
    _checked,
    ids
  }) => {
    if (action && ids?.length) {
      switch (btnEventType) {
        case DISBALE_LS_ACTIONS.SYNC_NOW_AND_DISABLE:
          handleSyncNowAndDisable(ids);
          leadServiceDialogResponse(true, _checked);
          break;
        case DISBALE_LS_ACTIONS.DISABLE_NOW_AND_SYNC_LATER:
          leadServiceDialogResponse(true, _checked);
          break;
        default:
          break;
      }
    }
  };
  const handleLeadServicePlusToggle = (_checked) => {
    toggleLeadServicePlusConfirmation(_checked);
  };
  const toggleLeadServiceConfirmation = (_checked) => {
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderLeadServiceDialogMessage(_checked),
      responseAction: (action) => leadServiceDialogResponse(action, _checked)
    });
  };
  const toggleLeadServicePlusConfirmation = (_checked) => {
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderLeadServicePlusDialogMessage(_checked),
      responseAction: (action) =>
        leadServicePlusDialogResponse(action, _checked)
    });
  };
  const renderLeadServiceDialogMessage = (_checked) => {
    const { forEmail, forContactCloud, forZapier } =
      checkSendDataTypeOccurrence(_checked);
    const content = _checked
      ? t("funnelIntegration.leadService.enableDialogMsg")
      : forEmail || forContactCloud || forZapier
        ? t("funnelIntegration.leadService.warningDialogMessage")
        : t("funnelIntegration.leadService.disableDialogMsg");
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: content
        }}
      ></div>
    );
  };
  const renderLeadServicePlusDialogMessage = (_checked) => {
    const content = _checked
      ? t("funnelIntegration.leadService.enablePlusMsg")
      : t("funnelIntegration.leadService.disablePlusMsg");
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: content
        }}
      ></div>
    );
  };
  const updateFormAndFunnelValues = (property, _checked, updatePayload) => {
    setValue(property, _checked);
    setFunnel({ ...funnel, ...updatePayload });
  };
  const leadServiceDialogResponse = (action, _checked) => {
    if (action) {
      const { forEmail, forContactCloud, forZapier } =
        checkSendDataTypeOccurrence(_checked);
      const payload = { leadService: _checked };
      handleToggleLeadService({ payload, forPlus: false });
      if (forEmail || forContactCloud || forZapier) {
        toggleCb({ forEmail, forContactCloud, forZapier, checked: _checked });
      }
      updateFormAndFunnelValues("enabled", _checked, payload);
    }
  };
  const leadServicePlusDialogResponse = (action, _checked) => {
    if (action) {
      const { forEmail, forContactCloud, forZapier } =
        checkSendDataTypeOccurrence(_checked);
      const payload = {
        leadServicePlus: _checked,
        leadService: watch("enabled")
      };
      handleToggleLeadService({ payload: payload, forPlus: true });
      if (_checked) {
        setValue("enabled", _checked);
        if (forEmail || forContactCloud || forZapier) {
          toggleCb({ forEmail, forContactCloud, forZapier, checked: _checked });
        }
      }
      payload.leadService = watch("enabled");
      updateFormAndFunnelValues("enabledPlus", _checked, payload);
    }
  };
  const handleAlternativeAddress = async (_checked) => {
    try {
      if (isUpdatingAlternativeAddress) return;
      setIsUpdatingAlternativeAddress(true);
      const response = await toggleAlternativeAddress(
        { alternativeAddress: _checked },
        id
      );
      setValue("alternativeAddressEnabled", _checked);
      showSuccess(response.data.message);
      setFunnel({
        ...funnel,
        alternativeAddress: { ...funnel.alternativeAddress, enabled: _checked }
      });
    } catch (error) {
      showError(t("funnelIntegration.alternativeAddress.error"));
    } finally {
      setIsUpdatingAlternativeAddress(false);
    }
  };
  const submitAlternativeAddress = async (values) => {
    setIsUpdatingAlternativeAddress(true);
    const payload = {};
    Object.keys(dirtyFieldsForAlternativeAddress).forEach((key) => {
      payload[key] = values[key];
    });
    payload.enabled = watch("alternativeAddressEnabled");
    if (payload.country) {
      payload.country = payload.country.name;
    }
    try {
      const response = await updateAlternativeAddress(payload, id);
      showSuccess(response.data.message);
      resetAlternativeAddressFields({
        country: values.country,
        city: values.city,
        address: values.address,
        zipCode: values.zipCode
      });
    } catch (error) {
      showError(t("funnelIntegration.alternativeAddress.error"));
    } finally {
      setIsUpdatingAlternativeAddress(false);
    }
  };
  const handleCreateCityText = (cityName) => setCreateCityText(cityName);
  const handleAllowAddNew = (flag) => setAllowAddNew(flag);

  return (
    <div className="px-3">
      <FieldSet>
        <FormProvider {...methods}>
          <PreventReload />
          <Form className="p-0">
            <Form.Check
              id="enabled"
              type="checkbox"
              label={t("common.enabled")}
              disabled={watch("enabledPlus")}
              checked={watch("enabled")}
              onChange={({ target: { checked } }) =>
                handleLeadServiceToggle(checked)
              }
            />
            <Form.Check
              id="enabledPlus"
              type="checkbox"
              label={`${t("common.enabled")} - Leadservice+`}
              checked={watch("enabledPlus")}
              onChange={({ target: { checked } }) =>
                handleLeadServicePlusToggle(checked)
              }
            />
            <Collapse in={watch("enabled") || watch("enabledPlus")}>
              <div>
                {/* leadServiceCompanyName field */}
                <Row className="align-items-center">
                  <Col sm={4} md={2}>
                    <Form.Label
                      className={`mb-0 text-break fw-bold ${
                        errors?.companyNameForLeadAgent ? "text-danger" : ""
                      }`}
                    >
                      {`${t(
                        "funnelIntegration.leadService.leadServiceCompanyNameLabel"
                      )}:`}
                    </Form.Label>
                  </Col>
                  <Col sm={6} md={3}>
                    <Form.Control
                      size="sm"
                      type="text"
                      className={`${
                        errors?.companyNameForLeadAgent ? "border-danger" : ""
                      }`}
                      placeholder={`${t("common.enter")} ${t(
                        "funnelIntegration.leadService.leadServiceCompanyNameLabel"
                      )}`}
                      disabled={!editMode[FOR_COMPANY]}
                      {...register("companyNameForLeadAgent")}
                    />
                  </Col>
                  <LeadServiceActionsSection
                    editMode={editMode}
                    actionsFor={FOR_COMPANY}
                    handleEditMode={setEditMode}
                    isBtnDisabled={!dirtyFields?.companyNameForLeadAgent}
                  />
                </Row>
                {/* leadServiceProductName field */}
                <Row className="align-items-center mt-2">
                  <Col sm={4} md={2}>
                    <Form.Label className="mb-0 text-break fw-bold">
                      {`${t(
                        "funnelIntegration.leadService.leadServiceProductNameLabel"
                      )}:`}
                    </Form.Label>
                  </Col>
                  <Col sm={6} md={3}>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder={`${t("common.enter")} ${t(
                        "funnelIntegration.leadService.leadServiceProductNameLabel"
                      )}`}
                      disabled={!editMode[FOR_PRODUCT]}
                      {...register("leadServiceProductName")}
                    />
                  </Col>
                  <LeadServiceActionsSection
                    editMode={editMode}
                    actionsFor={FOR_PRODUCT}
                    handleEditMode={setEditMode}
                    isBtnDisabled={!dirtyFields?.leadServiceProductName}
                  />
                </Row>
              </div>
            </Collapse>

            <Collapse in={watch("enabledPlus")}>
              <div>
                <Form.Group className="my-2 border-top border-bottom">
                  <Form.Label className="fw-bold mt-1">
                    {t("funnelIntegration.leadServicePlus.priceTypeLabel")}:
                  </Form.Label>
                  <PriceTypeRadio
                    value={DYNAMIC}
                    name="leadServicePlusPriceType"
                    label={t(
                      "funnelIntegration.leadServicePlus.calculationLabel"
                    )}
                  />
                  <Collapse in={watch("leadServicePlusPriceType") === DYNAMIC}>
                    <div>
                      <Row className="mb-2 align-items-center">
                        <Col sm={4} md={2}>
                          <Form.Label className="ms-4 text-break mb-0 fw-bold">
                            {`${t(
                              "funnelIntegration.leadServicePlus.pricePerInvoiceUnitLabel"
                            )}:`}
                          </Form.Label>
                        </Col>
                        <Col sm={6} md={3}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="pricePerInvoiceUnitForLeadAgent"
                            isDisabled={!editMode[FOR_PRICE]}
                          />
                        </Col>
                        <LeadServiceActionsSection
                          editMode={editMode}
                          actionsFor={FOR_PRICE}
                          handleEditMode={setEditMode}
                          hideDelete
                        />
                      </Row>
                    </div>
                  </Collapse>
                  <PriceTypeRadio
                    value={FIXED}
                    name="leadServicePlusPriceType"
                    label={t(
                      "funnelIntegration.leadServicePlus.fixedPriceLabel"
                    )}
                  />
                  <Collapse in={watch("leadServicePlusPriceType") === FIXED}>
                    <div>
                      <Row className="mb-2 align-items-center">
                        <Col sm={4} md={2}>
                          <Form.Label className="ms-4 text-break mb-0 fw-bold">
                            {`${t(
                              "funnelIntegration.leadServicePlus.fixedPricePerInvoiceUnitLabel"
                            )}:`}
                          </Form.Label>
                        </Col>
                        <Col sm={6} md={3}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="fixedPricePerInvoiceUnitForLeadAgent"
                            isDisabled={!editMode[FOR_FIXED_PRICE]}
                          />
                        </Col>
                        <LeadServiceActionsSection
                          editMode={editMode}
                          actionsFor={FOR_FIXED_PRICE}
                          handleEditMode={setEditMode}
                          hideDelete
                        />
                      </Row>
                    </div>
                  </Collapse>
                  <div className="mb-2 d-flex align-items-center">
                    <PriceTypeRadio
                      value={AREA_QUERY}
                      name="leadServicePlusPriceType"
                      label={t(
                        "funnelIntegration.leadServicePlus.areaQueryLabel"
                      )}
                      isDisabled={
                        watch("nameOfAccountingUnitForLeadAgent") === ""
                      }
                    />
                    <Fade in={watch("nameOfAccountingUnitForLeadAgent") === ""}>
                      <small className="ms-4 fw-bold text-primary">
                        <FontAwesomeIcon
                          className="me-1"
                          icon={faExclamationCircle}
                        />
                        {t(
                          "funnelIntegration.leadServicePlus.disabledBudgetLimitRadioMsg"
                        )}
                      </small>
                    </Fade>
                  </div>
                  <Collapse
                    in={watch("leadServicePlusPriceType") === AREA_QUERY}
                  >
                    <div>
                      <Row className="mb-2 align-items-center">
                        <Col sm={4} md={2}>
                          <Form.Label className="ms-4 text-break mb-0 fw-bold">
                            {`${t(
                              "funnelIntegration.leadServicePlus.budgetQueryLimitsLabel"
                            )}:`}
                          </Form.Label>
                        </Col>
                        <Col sm={6} md={2}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="minBudgetQuery"
                            isDisabled={!editMode[FOR_BUDGET_QUERY]}
                            groupTextSymbol={watch(
                              "nameOfAccountingUnitForLeadAgent"
                            )}
                          />
                        </Col>
                        <Col sm={6} md={2}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="maxBudgetQuery"
                            isDisabled={!editMode[FOR_BUDGET_QUERY]}
                            groupTextSymbol={watch(
                              "nameOfAccountingUnitForLeadAgent"
                            )}
                          />
                        </Col>
                        <Col sm={0} md={2}></Col>
                        <LeadServiceActionsSection
                          editMode={editMode}
                          actionsFor={FOR_BUDGET_QUERY}
                          handleEditMode={setEditMode}
                          hideDelete
                        />
                      </Row>
                      <Row className="mb-2 align-items-center">
                        <Col sm={4} md={2}>
                          <Form.Label className="ms-4 text-break mb-0 fw-bold">
                            {`${t(
                              "funnelIntegration.leadServicePlus.pricePerAreaLabel"
                            )}:`}
                          </Form.Label>
                        </Col>
                        <Col sm={6} md={2}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="pricePerAreaBelowMinBudget"
                            isDisabled={!editMode[FOR_PRICES_PER_AREA]}
                          />
                        </Col>
                        <Col sm={6} md={2}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="pricePerAreaBetweenBudget"
                            isDisabled={!editMode[FOR_PRICES_PER_AREA]}
                          />
                        </Col>
                        <Col sm={6} md={2}>
                          <NumberInputGroup
                            inputSize="sm"
                            name="pricePerAreaAboveMaxBudget"
                            isDisabled={!editMode[FOR_PRICES_PER_AREA]}
                          />
                        </Col>
                        <LeadServiceActionsSection
                          editMode={editMode}
                          actionsFor={FOR_PRICES_PER_AREA}
                          handleEditMode={setEditMode}
                          hideDelete
                        />
                      </Row>
                    </div>
                  </Collapse>
                </Form.Group>
                <Row className="align-items-center my-3">
                  <Col sm={4} md={2}>
                    <Form.Label className="text-break mb-0 fw-bold">
                      {`${t(
                        "funnelIntegration.leadServicePlus.nameOfAccountingUnitLabel"
                      )}:`}
                    </Form.Label>
                  </Col>
                  <Col sm={6} md={3}>
                    <TextField
                      type="text"
                      inputSize="sm"
                      register={register}
                      disabled={!editMode[FOR_UNIT]}
                      name="nameOfAccountingUnitForLeadAgent"
                      noSpacing
                    />
                  </Col>
                  <LeadServiceActionsSection
                    editMode={editMode}
                    actionsFor={FOR_UNIT}
                    handleEditMode={setEditMode}
                    isBtnDisabled={
                      !dirtyFields?.nameOfAccountingUnitForLeadAgent
                    }
                  />
                </Row>
              </div>
            </Collapse>

            <Collapse in={watch("enabled") || watch("enabledPlus")}>
              <div>
                <div className="mt-2 mb-4 border-top">
                  <Form.Label className="text-break mt-2 fw-bold">
                    {`${t(
                      "funnelIntegration.leadServicePlus.optionalQuestion"
                    )}:`}
                  </Form.Label>
                  <Row className="align-items-center mb-3">
                    <Col sm={4} md={2}>
                      <Form.Label className="text-break mb-0 ps-3">
                        {`${t(
                          "funnelIntegration.leadServicePlus.question"
                        )}: 1`}
                      </Form.Label>
                    </Col>
                    <Col sm={6} md={3}>
                      <SelectField
                        control={control}
                        name="question1"
                        options={getAvailableQuestions("question1")}
                        onSelect={(option) =>
                          handleQuestionChange("question1", option)
                        }
                        showSpinner={false}
                        isDisabled={!editMode[FOR_QUESTIONS]}
                        isOptionDisabled={isOptionDisabled}
                        isClearable={true}
                      />
                    </Col>
                    <LeadServiceActionsSection
                      editMode={editMode}
                      actionsFor={FOR_QUESTIONS}
                      handleEditMode={setEditMode}
                    />
                    {/* <LeadServiceActionsSection
                      editMode={editMode}
                      actionsFor={FOR_QUESTION_1}
                      handleEditMode={setEditMode}
                      questionField="question1"
                    /> */}
                  </Row>
                  <Row className="align-items-center mt-2 mb-3">
                    <Col sm={4} md={2}>
                      <Form.Label className="text-break mb-0 ps-3">
                        {`${t(
                          "funnelIntegration.leadServicePlus.question"
                        )}: 2`}
                      </Form.Label>
                    </Col>
                    <Col sm={6} md={3}>
                      <SelectField
                        control={control}
                        name="question2"
                        options={getAvailableQuestions("question2")}
                        onSelect={(option) =>
                          handleQuestionChange("question2", option)
                        }
                        showSpinner={false}
                        isDisabled={!editMode[FOR_QUESTIONS]}
                        isOptionDisabled={isOptionDisabled}
                        isClearable={true}
                      />
                    </Col>
                    {/* <LeadServiceActionsSection
                      editMode={editMode}
                      actionsFor={FOR_QUESTION_2}
                      handleEditMode={setEditMode}
                      questionField="question2"
                    /> */}
                  </Row>
                  <Row className="align-items-center mt-2">
                    <Col sm={4} md={2}>
                      <Form.Label className="text-break mb-0 ps-3">
                        {`${t(
                          "funnelIntegration.leadServicePlus.question"
                        )}: 3`}
                      </Form.Label>
                    </Col>
                    <Col sm={6} md={3}>
                      <SelectField
                        control={control}
                        name="question3"
                        options={getAvailableQuestions("question3")}
                        onSelect={(option) =>
                          handleQuestionChange("question3", option)
                        }
                        showSpinner={false}
                        isDisabled={!editMode[FOR_QUESTIONS]}
                        isOptionDisabled={isOptionDisabled}
                        isClearable={true}
                      />
                    </Col>
                    {/* <LeadServiceActionsSection
                      editMode={editMode}
                      actionsFor={FOR_QUESTION_3}
                      handleEditMode={setEditMode}
                      questionField="question3"
                    /> */}
                  </Row>
                </div>
              </div>
            </Collapse>

            {/* alternative address fields */}
            <FormProvider {...methodsForAlternativeAddressFields}>
              <PreventReload />
              <div>
                <div
                  className={`d-flex align-items-center ${
                    watch("enabled") || watch("enabledPlus")
                      ? "mt-2 pt-2 border-top"
                      : ""
                  }`}
                >
                  <Form.Check
                    id="alternativeAddressEnabled"
                    type="checkbox"
                    checked={watch("alternativeAddressEnabled")}
                    label={t(
                      "funnelIntegration.alternativeAddress.checkboxLabel"
                    )}
                    onChange={({ target: { checked } }) =>
                      handleAlternativeAddress(checked)
                    }
                    disabled={isUpdatingAlternativeAddress}
                  />
                  {isUpdatingAlternativeAddress && (
                    <Spinner
                      size="sm"
                      animation="border"
                      className="ms-3 mb-1"
                    />
                  )}
                </div>
                <Collapse in={watch("alternativeAddressEnabled")}>
                  <div>
                    <div className="mt-1 p-3 bg-light rounded">
                      <ContactDetailsCommonFields
                        labelStyle="fw-bold"
                        handleAllowAddNew={handleAllowAddNew}
                        handleCreateCityText={handleCreateCityText}
                      />
                      <Button
                        type="submit"
                        variant="success"
                        disabled={
                          isUpdatingAlternativeAddress ||
                          !Object.keys(dirtyFieldsForAlternativeAddress)?.length
                        }
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleSubmit(submitAlternativeAddress)();
                        }}
                      >
                        {t("common.save")}
                      </Button>
                    </div>
                  </div>
                </Collapse>
              </div>
            </FormProvider>
          </Form>
        </FormProvider>
      </FieldSet>
      <FormModal
        show={allowAddNew}
        onHide={() => handleAllowAddNew(false)}
        modalHeading={t("createNewCity")}
        modalSize="md"
      >
        <CityForm
          createCityText={createCityText}
          handleAllowAddNew={handleAllowAddNew}
          setValue={setAlternativeAddressFields}
        />
      </FormModal>
    </div>
  );
};

export default LeadService;
