import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ModalHead } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";
import { useState } from "react";
import { duplicateCustomerWebTemplate } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { useNotification } from "../../hooks/useNotification";

const WebsiteReplaceDialog = ({
  show,
  toggleDialog,
  toggleDuplicateDialog,
  data
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showToastWithLink } = useNotification();

  const displayLinkToast = ({ customerId, toastType, toastMsg }) =>
    showToastWithLink({
      url: `/dashboard/customers/${customerId}/website`,
      msg: toastMsg,
      showToast: toastType
    });

  const handleReplace = () => {
    setIsSubmitting(true);
    try {
      data.template.isReplace = true;
      duplicateCustomerWebTemplate(data.customerId, data.template);
      displayLinkToast({
        customerId: data.customerId,
        toastType: showSuccess,
        toastMsg: "Website Cloned Successfully"
      });
      toggleDialog();
      toggleDuplicateDialog();
    } catch (error) {
      console.error("Error submitting form:", error);
      showError(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static">
      <ModalHead className={`fw-bold "text-light"}`} bg="warning">
        <Modal.Title>{t("customerWebsite.replaceWarning.title")}</Modal.Title>
      </ModalHead>
      <Modal.Body>{t("customerWebsite.replaceWarning.para")}</Modal.Body>
      <Modal.Footer className={`border-warning`}>
        <div className="d-flex flex-row-reverse">
          <Button
            className="ms-2"
            variant="outline-secondary"
            onClick={() => {
              toggleDialog();
            }}
          >
            {t("common.cancel")}
          </Button>
          {isSubmitting ? (
            <SpinnerButton
              btnText={t("customerWebsite.replaceWarning.saving")}
              btnStyle="btn-outline-success"
            />
          ) : (
            <Button
              type="button"
              onClick={handleReplace}
              variant="outline-success"
            >
              {t("customerWebsite.replaceWarning.btn")}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default WebsiteReplaceDialog;
