import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import CustomerForm from "../../components/customers/customer-form";
import CustomerView from "../../components/customers/customer-view";
import CustomerLeads from "../../components/customers/customer-leads";
import CustomersTable from "../../components/customers/customers-table";
import CustomerFunnels from "../../components/customers/customer-funnels";
import CustomerOverview from "../../components/customers/customer-overview";
import CustomerPerformance from "../../components/customers/customer-performance";
import CustomerWebsite from "../../components/customers/customer-website";
import CustomerInvoices from "../../components/customers/customer-invoices";

const CustomerPage = () => {
  return (
    <>
      <Routes>
        <Route index element={<CustomersTable />} />
        <Route path="new" element={<CustomerForm />} />
        <Route path=":id/edit" element={<CustomerForm />} />
        <Route path=":id" element={<CustomerView />}>
          <Route path="overview" element={<CustomerOverview />} />
          <Route path="funnels" element={<CustomerFunnels />} />
          <Route path="leads" element={<CustomerLeads />} />
          <Route path="performance" element={<CustomerPerformance />} />
          <Route path="website" element={<CustomerWebsite />} />
          <Route path="invoices" element={<CustomerInvoices />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard/customers" />} />
      </Routes>
      <Outlet />
    </>
  );
};
export default CustomerPage;
