import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const ViewMoreButton = ({
  id,
  path,
  totalDocs,
  searchParams,
  resultsLength,
  handleQueryParams
}) => {
  const { t } = useTranslation();
  const showViewMore = totalDocs > resultsLength;
  const navigate = useNavigate();
  const handleViewMore = () => {
    handleQueryParams("customer", id);
    navigate({
      pathname: path,
      search: searchParams.toString()
    });
  };
  return (
    <div className="mb-3 d-flex align-items-center">
      {showViewMore && (
        <div className="flex-fill text-center">
          <Button variant="outline-secondary me-2" onClick={handleViewMore}>
            {t("viewMore")}
          </Button>
        </div>
      )}
      <div
        className={`text-muted ${!showViewMore ? "flex-fill text-end" : ""}`}
      >
        {resultsLength} {t("common.of")} {totalDocs} {t("common.results")}
      </div>
    </div>
  );
};
export default ViewMoreButton;
