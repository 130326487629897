import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tooltip,
  Collapse,
  OverlayTrigger
} from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Overlay from "../../overlay";
import {
  getFunnelLandingPage,
  createFunnelLandingPage,
  toggleLandingPageEnable,
  updateFunnelLandingPage,
  updateVersionLandingPageId
} from "../../../services/api-declaration";
import PreventReload from "../prevent-reload";
import { useDialog } from "../../../hooks/useDialog";
import { LANDING_PAGE_TEMPLATES } from "../../strings";
import { CustomCard, HorizontalDivider } from "../../common-styling";
import { LandingPageSchema } from "../../../services/validation-schema";
import { showError, showSuccess } from "../../../services/toast-service";
import ValidationMessage from "../../validation-message/validation-message";
import { landingPageTemplateOptions } from "../../common-confgs/common-vars";
import { fetchAndSetFunnelVersions } from "../../../services/common-service";
import LandingPageTempOne from "./landing-page-templates/landing-page-temp-one";
import LandingPageTempTwo from "./landing-page-templates/landing-page-temp-two";
import DuplicateTemplateModal from "./landing-page-templates/duplicate-template-modal";
import { Trans, useTranslation } from "react-i18next";

const DuplicateBtnContainer = styled.div`
  top: 15px;
  right: 15px;
`;

const resetData = {
  headlineText: "",
  headlineColor: "#000000",
  heroHeadline: "",
  heroHeadlineColor: "#000000",
  subHeadlineText: "",
  subHeadlineColor: "#000000",
  ctaButtonText: "",
  ctaButtonColor: "#000000",
  ctaButtonBackgroundColor: "#000000",
  benefitsGridHeadline: "",
  benefitsGridHeadlineColor: "#000000",
  benefitsGridTextColor: "#000000"
};

const FunnelLandingPage = () => {
  const { FUNNEL_LANDING_PAGE_TEMP_ONE, FUNNEL_LANDING_PAGE_TEMP_TWO } =
    LANDING_PAGE_TEMPLATES;
  const params = useParams();
  const { t } = useTranslation();
  const { funnel, setFunnel, setFunnelVersions } = useOutletContext();
  const { showConfirmationDialogBox } = useDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [fetchedLandingPageId, setFetchedLandingPageId] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const methods = useForm({
    resolver: yupResolver(LandingPageSchema),
    defaultValues: { enabled: funnel.isLandingPageEnabled || false }
  });
  const {
    watch,
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful }
  } = methods;
  const isEnabled = watch("enabled");
  const selectedTemplate = watch("template");
  const shouldNotDuplicate =
    funnel.landingPageId !== fetchedLandingPageId || isDirty;

  const checkTemplateType = (selectedId) =>
    selectedTemplate?._id === selectedId;
  const getSelectedTemplate = (tempType, _t) =>
    landingPageTemplateOptions(_t).find((item) => item._id === tempType);
  const handleEnableChange = (_checked) => toggleEnableConfirmation(_checked);
  // enable-confirmation-dialog-methods
  const toggleEnableConfirmation = (_checked) =>
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderEnableDialogMessage(_checked),
      responseAction: (action) => toggleEnableDialogResponse(action, _checked)
    });
  const renderEnableDialogMessage = (_checked) => (
    <div>
      {_checked ? (
        <Trans i18nKey={t("preLandingPage.enableSwitchDialogMsg")} />
      ) : (
        <Trans i18nKey={t("preLandingPage.disableSwitchDialogMsg")} />
      )}
    </div>
  );
  const toggleEnableDialogResponse = (action, _checked) => {
    if (action) {
      setValue("enabled", _checked);
      if (funnel.landingPageId) {
        toggleLandingPageEnableAtFunnel(_checked, funnel.landingPageId);
      }
    }
  };
  // toggle-template-confirmation-dialog-methods
  const toggleTemplateConfirmationDialog = (_option) =>
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderTemplateDialogMessage(),
      responseAction: (action) => toggleTemplateDialogResponse(action, _option)
    });
  const renderTemplateDialogMessage = () => (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<strong>Your new changes will be lost!</strong> <div>Are you sure, you want to change landing page template?</div>`
        }}
      ></div>
    </>
  );
  const toggleTemplateDialogResponse = (action, _option) => {
    if (action) {
      reset({ enabled: isEnabled, template: _option });
    }
  };
  // async-methods
  const getTemplate = async ({ funnel: _funnel, templateType }) => {
    // check if funnel has connected to landing page
    if (!_funnel.landingPageId || !_funnel.isLandingPageEnabled) return;
    setIsLoadingTemplate(true);
    try {
      const query = {
        funnelId: _funnel._id,
        templateType
      };
      const response = await getFunnelLandingPage(query);
      const responseData = response.data.data;
      setFetchedLandingPageId(responseData._id);
      if (responseData) {
        const { _id, __v, templateType, ...restFormData } = responseData;
        setValue("data", { ...restFormData });
        // forcefully setting benefitsGrid and heroContentPoints because it's creating by useFieldArray and not updating
        setValue("data.benefitsGrid", restFormData.benefitsGrid);
        setValue("data.heroContentPoints", restFormData.heroContentPoints);
      } else {
        setValue("data", { ...resetData });
        setValue("data.benefitsGrid", []);
        setValue("data.heroContentPoints", []);
      }
    } catch (error) {
      console.log("Error in fetch landing page template: ", error);
      showError(error.response?.data?.message || "Error in fetch landing page");
    } finally {
      setIsLoadingTemplate(false);
    }
  };
  const updateFunnelForLandingPage = async ({
    funnel,
    landingPageId,
    isLandingPageEnabled,
    landingPageTemplateType
  }) => {
    try {
      const updateFunnelPayload = {
        landingPageId,
        isLandingPageEnabled,
        landingPageTemplateType,
        addNewVersion: !funnel.landingPageId ? true : false
      };
      const response = await updateFunnelLandingPage(funnel._id, {
        ...updateFunnelPayload
      });
      const { addNewVersion, ...restPayload } = updateFunnelPayload;
      const { newVersionId } = response.data.data;
      setFunnel({
        ...funnel,
        ...restPayload,
        ...(newVersionId ? { activeVersion: newVersionId } : undefined)
      });
      if (newVersionId) {
        await fetchAndSetFunnelVersions(t, params.id, setFunnelVersions);
      }
    } catch (error) {
      console.log("Error in updating funnel for landing page", error);
      showError(
        error.response?.data?.message ||
          "Error in updating funnel for landing page"
      );
    }
  };
  const toggleLandingPageEnableAtFunnel = async (_checked, _landingPageId) => {
    try {
      const updatedValues = {
        isLandingPageEnabled: _checked,
        landingPageId: _landingPageId
      };
      const response = await toggleLandingPageEnable(funnel._id, updatedValues);
      await fetchAndSetFunnelVersions(t, params.id, setFunnelVersions);
      const { landingPageId, ...restPayload } = updatedValues;
      setFunnel({
        ...funnel,
        ...restPayload,
        activeVersion: response.data.data.newVersionId
      });
      showSuccess(response.data?.message);
    } catch (error) {
      console.log("Error in toggle enable ", error);
      showError(error.response?.data?.message || "Error in toggle enable");
    }
  };
  const onSubmittingLandingPage = async (formValues) => {
    setIsSubmitting(true);
    try {
      const { enabled, template } = formValues;
      const { ...restVals } = formValues.data;
      const response = await createFunnelLandingPage({
        template,
        funnelId: funnel._id,
        ...restVals
      });
      showSuccess(response.data?.message || "Landing page success!");
      const landingPageId = response.data.data._id;
      if (landingPageId) {
        await updateFunnelForLandingPage({
          funnel,
          landingPageId,
          isLandingPageEnabled: enabled,
          landingPageTemplateType: selectedTemplate._id
        });
        try {
          await updateVersionLandingPageId(funnel.activeVersion, {
            landingPageId
          });
        } catch (error) {
          console.log("Error in updating version: ", error);
          showError(
            error.response?.data?.message || "Error in updating version"
          );
        }
      }
    } catch (error) {
      console.log("Error in saving data: ", error);
      showError(error.response?.data?.message || "Error in saving data");
    } finally {
      setIsSubmitting(false);
    }
  };
  const toggleDuplicateModal = () => setShowDuplicateModal(!showDuplicateModal);

  const getTemplateCb = useCallback(getTemplate, [setValue]);
  useEffect(() => {
    // update template form-value according to funnel
    const tempType = funnel.landingPageTemplateType;
    if (tempType) {
      setValue("template", getSelectedTemplate(tempType, t));
    }
  }, [funnel.landingPageTemplateType, setValue, t]);
  // fetch template
  useEffect(() => {
    if (isEnabled && selectedTemplate) {
      getTemplateCb({
        funnel,
        templateType: selectedTemplate._id
      });
    }
  }, [selectedTemplate, isEnabled, funnel, getTemplateCb]);
  // after submit is successfull, keep values but not dirty form state
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true, keepDirty: false });
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <CustomCard className="my-3 p-4 position-relative">
      <Overlay show={isLoadingTemplate} showMsg={false} />
      <DuplicateBtnContainer className="position-absolute">
        <OverlayTrigger
          overlay={
            shouldNotDuplicate ? (
              <Tooltip>{t("preLandingPage.duplicateDisabledTooltip")}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              variant="outline-primary"
              onClick={toggleDuplicateModal}
              disabled={shouldNotDuplicate}
            >
              <FontAwesomeIcon size="sm" className="me-2" icon={faClone} />
              <span>{t("preLandingPage.duplicateLabel")}</span>
            </Button>
          </span>
        </OverlayTrigger>
      </DuplicateBtnContainer>
      <FormProvider {...methods}>
        <PreventReload />
        <Form className="p-0" onSubmit={handleSubmit(onSubmittingLandingPage)}>
          <Form.Check
            id="enabled"
            type="checkbox"
            checked={isEnabled}
            label={t("preLandingPage.enableSwitchLabel")}
            onChange={({ target: { checked } }) => handleEnableChange(checked)}
          />
          <Collapse in={isEnabled}>
            <div>
              <Row className="mt-4">
                <Col xs={12} md={6}>
                  <Form.Group controlId="template-type">
                    <Form.Label
                      className={errors?.template ? "text-danger" : ""}
                    >
                      {`${t("preLandingPage.selectTemplateLabel")} ${
                        errors?.template ? "*" : ""
                      }`}
                    </Form.Label>
                    <Controller
                      control={control}
                      name="template"
                      render={({ field }) => (
                        <div className="w-100">
                          <Select
                            {...field}
                            options={landingPageTemplateOptions(t)}
                            getOptionLabel={(opt) => opt.name}
                            getOptionValue={(opt) => opt._id}
                            onChange={(opt, _) => {
                              if (field.value?._id !== opt._id) {
                                if (isDirty) {
                                  toggleTemplateConfirmationDialog(opt);
                                } else {
                                  field.onChange(opt);
                                }
                              }
                            }}
                            styles={{
                              menu: (base) => ({ ...base, zIndex: 1021 }),
                              menuPortal: (base) => ({ ...base, zIndex: 1021 }),
                              control: (base) => ({
                                ...base,
                                borderColor: errors?.template
                                  ? "red"
                                  : "#ced4da"
                              })
                            }}
                          />
                        </div>
                      )}
                    />
                    {errors?.template && (
                      <ValidationMessage error={errors.template.message} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Collapse>
          {isEnabled && selectedTemplate && (
            <HorizontalDivider className="mt-4" />
          )}
          {isEnabled && checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_ONE) ? (
            <LandingPageTempOne />
          ) : isEnabled && checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_TWO) ? (
            <LandingPageTempTwo />
          ) : null}
          <HorizontalDivider className="mt-4" />
          <Button
            type="submit"
            disabled={isSubmitting || !isEnabled}
            variant="outline-success"
          >
            {isSubmitting && (
              <Spinner size="sm" className="me-2" animation="border" />
            )}
            {t("common.save")}
          </Button>
        </Form>
      </FormProvider>
      <DuplicateTemplateModal
        formData={getValues()}
        show={showDuplicateModal}
        toggle={toggleDuplicateModal}
        fetchedLandingPageId={fetchedLandingPageId}
      />
    </CustomCard>
  );
};
export default FunnelLandingPage;
