import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";
import TextArea from "../../reusable-form-fields/text-area";

const SecondBanner = ({
  parseResourceSrc,
  parsePreviewResourceSrc,
  index,
  isFormChanged
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    register
  } = useFormContext();

  const SecondBannerBGColorDisplay = watch(
    `sections[${index}].data.bgColorDisplay`
  );
  const SecondBannerBtnBGColorDisplay = watch(
    `sections[${index}].data.btn.bgColorDisplay`
  );
  const secondBannerBgImage = watch(`sections[${index}].data.image`);

  const secondBannerBgColorPickerRef = useRef();
  const secondBannerHeadingColorPickerRef = useRef();
  const secondBannerParaColorPickerRef = useRef();
  const secondBannerCATButtonColorPickerRef = useRef();
  const secondBannerCATButtonBgColorPickerRef = useRef();
  return (
    <>
      <Row>
        <Col md={3} className="mt-1">
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.BGColor")}
            fieldName={`sections[${index}].data.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bgColor
            }
            fieldRef={secondBannerBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bgColor`)}
            displayFieldWatcher={SecondBannerBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
            isDisabled={Boolean(!SecondBannerBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <TextField
            label={t("customerWebsite.heading")}
            placeholder={t("customerWebsite.heading")}
            type="text"
            name={`sections[${index}].data.heading.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.heading?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.headingColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.heading.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.heading?.color
            }
            fieldRef={secondBannerHeadingColorPickerRef}
            fieldVal={watch(`sections[${index}].data.heading.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <TextArea
            label={t("customerWebsite.para")}
            name={`sections[${index}].data.para.text`}
            register={register}
            errors={
              errors?.sections && errors?.sections[index]?.data?.para?.text
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.paraColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.para.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.para?.color
            }
            fieldRef={secondBannerParaColorPickerRef}
            fieldVal={watch(`sections[${index}].data.para.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={12} md={3}>
          <TextField
            label={t("customerWebsite.catBtnText")}
            placeholder={t("customerWebsite.catBtnText")}
            type="text"
            name={`sections[${index}].data.btn.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.btn?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col sm={12} md={3}>
          <TextField
            label={t("customerWebsite.catBtnLink")}
            placeholder={t("customerWebsite.catBtnLink")}
            type="text"
            name={`sections[${index}].data.btn.link`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.btn?.link
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.catBtnColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.btn.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.btn?.color
            }
            fieldRef={secondBannerCATButtonColorPickerRef}
            fieldVal={watch(`sections[${index}].data.btn.color`)}
            defaultColorValue="#ffffff"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
        <Col xs={12} md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.catBtnBGColor")}
            fieldName={`sections[${index}].data.btn.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.btn?.bgColor
            }
            fieldRef={secondBannerCATButtonBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.btn.bgColor`)}
            displayFieldWatcher={SecondBannerBtnBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.btn.bgColorDisplay`}
            isDisabled={Boolean(!SecondBannerBtnBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <FileInput
            label={t("customerWebsite.image")}
            name={`sections[${index}].data.image`}
            // resourceId={resourceId}
            parentFolderName={"customer"}
            url={parseResourceSrc(secondBannerBgImage)}
            urlForPreview={parsePreviewResourceSrc(secondBannerBgImage)}
            asset={secondBannerBgImage?.asset}
            error={
              errors?.sections && errors?.sections[index]?.data?.image?._id
            }
            isFormChanged={isFormChanged}
            tagName="website_banner_2"
            // selectedCategory={getValues("category")}
          />
        </Col>
      </Row>
    </>
  );
};

export default SecondBanner;
