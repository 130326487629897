import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Tooltip,
  Collapse,
  InputGroup,
  SplitButton,
  OverlayTrigger
} from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import { useSelector, useDispatch } from "react-redux";
import { components } from "react-select";
import {
  faPen,
  faUser,
  faPhone,
  faXmark,
  faClock,
  faBellSlash,
  faPhoneSlash,
  faRotateLeft,
  faCircleCheck,
  faXmarkCircle,
  faLocationDot,
  faCalendarDays,
  faPersonCircleExclamation
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import {
  ICON_TYPE,
  USER_ROLES,
  BASE_COUNTRY,
  ACTIVITY_TYPES,
  SEARCH_LEAD_BY,
  FUNNEL_TYPE_FILTER,
  ACTIVITY_RESULT_TYPES,
  LEAD_INTERACTION_STATUS,
  LEAD_AGENT_TIMESTAMP_UPDATE_DELAY,
  DATE_TIME_WITH_MONTH_FORMAT
} from "../strings";
import {
  CustomCard,
  HorizontalDivider,
  AgentViewActivityBtn
} from "../common-styling";
import {
  CustomDiv,
  CustomBadge,
  DotSeparator,
  AddressColumn,
  SelectContainer,
  CustomCardHeader,
  CalcDistanceText,
  CustomFontSizeDiv,
  AnimationContainer,
  ForceCallContainer,
  ExtraInfoCustomBadge,
  AbsoluteInfoContainer,
  ControlledHeightContainer,
  ControlledHeightLoaderContainer
} from "./lead-agent-styles";
import Loader from "../loader/loader";
import {
  countryCodesHash,
  leadSearchOptions
} from "../common-confgs/common-vars";
import {
  generateDate,
  generateDateTime,
  generateScriptUrl,
  leadAgentSessionExpiry
} from "../../services/common-service";
import {
  createActivity,
  updateLeadById,
  getLeadForAgent,
  getLeadsBySearch,
  getLeadActivities,
  getFunnelsForLeadsBySearch,
  validateAndUpdateLeadAgentInfo
} from "../../services/api-declaration";
import PickerDialog from "./picker-dialog";
import AppointmentPickerDialog from "../lead-appointment-confirm/datetime-picker-dialog";
import ActivityButton from "./activity-button";
import { useDialog } from "../../hooks/useDialog";
import AgentPricePrompt from "./agent-price-prompt";
import CountryFlagEmoji from "../country-flag-emoji";
import LeadActivities from "../leads/lead-activities";
import PDFViewer from "../leadservice-panel/pdf-viewer";
import PreventReload from "../funnel-form/prevent-reload";
import {
  disableAppointmentTab,
  enableAppointmentTab
} from "../../store/slices/tabStatus";
import AvailabilityBadgeContent from "./availability-badge-content";
import { LeadUpdateSchema } from "../../services/validation-schema";
import { showError, showSuccess } from "../../services/toast-service";
import Option from "../reusable-form-fields/select-custom-option-item";
import { useTranslation, Trans } from "react-i18next";

const MapLink = styled.a`
  padding: 2px 6px;
  border-radius: 6px;
  border: 1px solid var(--color-light-gray);
`;

const {
  CALL,
  COMPLETED,
  NAME_WRONG,
  NOT_REACHED,
  NOT_SUITABLE,
  NUMBER_WRONG,
  QUALIFIED,
  CONTACT_LATER,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_CONFIRMED,
  APPOINTMENT_POSTPONED
} = ACTIVITY_RESULT_TYPES;

const defaultCurrentActivityLoader = {
  [CALL]: false,
  [COMPLETED]: false,
  [NUMBER_WRONG]: false,
  [NOT_REACHED]: false,
  [NAME_WRONG]: false,
  [QUALIFIED]: false,
  [NOT_SUITABLE]: false,
  [CONTACT_LATER]: false,
  [APPOINTMENT_CANCELLED]: false,
  [APPOINTMENT_CONFIRMED]: false,
  [APPOINTMENT_POSTPONED]: false
};

const MenuList = (props) => {
  const { MenuListHeader = null, MenuListFooter = null } =
    props.selectProps.components;

  return (
    <components.MenuList {...props}>
      {props.children.length && MenuListHeader}
      {props.children}
      {props.children.length && MenuListFooter}
    </components.MenuList>
  );
};
const MenuListFooter = ({ showing, total, onClick, isDisabled }) => {
  const { t } = useTranslation();
  return !showing || showing >= total ? null : (
    <Button
      className="mt-1 ms-2"
      variant="secondary"
      onClick={onClick}
      disabled={isDisabled}
    >
      {t("leadAgent.loadMore")}
    </Button>
  );
};

const defaultEditMode = {
  leadPhone: false,
  leadEmail: false,
  leadName: false
};

const LeadAgent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timer = useRef();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const phoneNumberClickRef = useRef();
  const [lead, setLead] = useState({});
  const [comment, setComment] = useState("");
  const [isQualified, setIsQualified] = useState(false);
  const [availableAfterFlag, setAvailableAfterFlag] = useState(false);
  const [contactLaterFlag, setContactLaterFlag] = useState(false);
  const [searchType, setSearchType] = useState(SEARCH_LEAD_BY.PHONE);
  const [leadSearchVia, setLeadSearchVia] = useState(SEARCH_LEAD_BY.AUTO);
  const [activities, setActivities] = useState([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [emailsToSend, setEmailsToSend] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activityLoader, setActivityLoader] = useState(false);
  const [currentActivityLoader, setCurrentActivityLoader] = useState(
    defaultCurrentActivityLoader
  );
  const [updateLoader, setUpdateLoader] = useState(false);
  const [paginateOptions, setPaginateOptions] = useState({
    totalDocs: 0
  });
  const [options, setOptions] = useState([]);
  const [editMode, setEditMode] = useState(defaultEditMode);
  const [funnelAppointment, setFunnelAppointment] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [interactionStatus, setInteractionStatus] = useState("");
  const [selectedFunnel, setSelectedFunnel] = useState(null);
  const [showPickerDialog, setShowPickerDialog] = useState(false);
  const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
  const [responses, setResponses] = useState({});
  const { showAlertDialogBox } = useDialog();
  const handleCallStart = () => {
    dispatch(disableAppointmentTab());
  };

  const handleCallEnd = () => {
    dispatch(enableAppointmentTab());
  };

  const initialValues = {
    leadEmail: "",
    leadPhone: "",
    leadName: "",
    isInCall: false
  };
  const methods = useForm({
    resolver: yupResolver(LeadUpdateSchema),
    defaultValues: initialValues
  });
  const {
    reset,
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = methods;

  const togglePickerDialog = () => setShowPickerDialog(!showPickerDialog);
  const toggleAppointmentDialog = () =>
    setShowAppointmentDialog(!showAppointmentDialog);
  const toggleDialog = () =>
    showAlertDialogBox({
      title: "Warning",
      message: t("leadAgent.leadServicePlusWarning"),
      acceptLabel: t("common.understood"),
      dialogType: "warning"
    });

  const leadData = getValues();
  const shouldContactLater = (_contactLater) =>
    _contactLater && moment().isBefore(moment(_contactLater));
  const checkLeadAvailability = (_availableAfter) =>
    moment(_availableAfter) > moment(Date.now());
  const generatePhoneNumber = (leadPhoneNumber) => {
    let phoneNumber = leadPhoneNumber;
    const country = lead?.funnelId?.companyInfo?.country;
    const countryCode = countryCodesHash[country];
    if (leadPhoneNumber.charAt(0) === "0" && countryCode) {
      phoneNumber = `${countryCode}${leadPhoneNumber.substring(1)}`;
    } else {
      phoneNumber = leadPhoneNumber;
    }
    return phoneNumber;
  };

  const setCommentPrice = (priceRange, e) => {
    if (priceRange) {
      const { lowerBound, upperBound } = priceRange;
      setComment(`Budgetrahmen: ${lowerBound}€ - ${upperBound}€\n`);
    }
  };

  const fetchLeadActivities = useCallback(async (leadId) => {
    try {
      setIsLoadingActivities(true);
      const response = await getLeadActivities(leadId);
      setActivities(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingActivities(false);
    }
  }, []);
  const fetchLead = useCallback(
    async ({ leadId = null, funnelId = null, searchType }) => {
      setFunnelAppointment(false);
      try {
        setIsLoading(true);
        resetSearchFieldAndOpts();
        const { data } = await getLeadForAgent({ leadId, funnelId });
        setEmailsToSend(data.data.emailsToSend);
        setLead(data.data.lead);
        if (data.data.lead?.funnelId?.customerAppointmentLink) {
          setFunnelAppointment(true);
        }
        if (data.data.lead) {
          if (data.data.lead.funnelId.leadServicePlus) {
            toggleDialog();
          }
          const { assignedVia } = data.data.lead;
          const _searchVia = assignedVia ? assignedVia : searchType;
          setLeadSearchVia(_searchVia);
          if (_searchVia === SEARCH_LEAD_BY.FUNNEL) {
            setSelectedFunnel(data.data.lead.funnelId);
          }
          const { leadName, leadEmail, leadPhone } = data.data.lead.leadDetails;
          reset({ leadName, leadEmail, leadPhone });
          const isInitiated =
            data.data.lead.interactionStatus ===
            LEAD_INTERACTION_STATUS.INITIATED;
          setValue("isInCall", isInitiated, { shouldDirty: isInitiated });
          setInteractionStatus(data.data.lead.interactionStatus);
          setIsQualified(data.data.lead.leadQualified);
          if (checkLeadAvailability(data.data.lead.availableAfter)) {
            setAvailableAfterFlag(true);
          } else {
            setAvailableAfterFlag(false);
          }
          if (shouldContactLater(data.data.lead.contactLater)) {
            setContactLaterFlag(true);
          } else {
            setContactLaterFlag(false);
          }
          await fetchLeadActivities(data.data.lead._id);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [reset, setValue, fetchLeadActivities]
  );
  const pageIndex = useRef(1);
  const searchLoader = useRef(false);

  const fetchFunnelOptions = useMemo(
    () =>
      _debounce(async (inputValue) => {
        try {
          if (inputValue) {
            const { data } = await getFunnelsForLeadsBySearch({
              funnelName: inputValue,
              pageIndex: pageIndex.current
            });
            const { totalDocs, rows } = data.data;
            setPaginateOptions({
              totalDocs: rows.length ? totalDocs : 0
            });
            rows.map((row) => {
              if (row.leadsCount === 0) {
                row.disabled = true;
                row.iconType = ICON_TYPE.NO_LEADS;
              }
              if (interactionStatus === LEAD_INTERACTION_STATUS.INITIATED) {
                row.disabled = true;
              }
              return row;
            });
            setOptions((prev) => [...prev, ...rows]);
          }
        } catch (err) {
          console.log(err);
          showError(
            err?.response?.data?.message ||
              t("toastMessage.errorInGetting", { what: "funnels" })
          );
        } finally {
          searchLoader.current = false;
        }
      }, 500),
    [t, interactionStatus]
  );
  const fetchLeadOptions = useMemo(
    () =>
      _debounce(async (inputValue) => {
        try {
          if (inputValue) {
            const { data } = await getLeadsBySearch({
              phone: searchType === SEARCH_LEAD_BY.PHONE ? inputValue : null,
              email: searchType === SEARCH_LEAD_BY.EMAIL ? inputValue : null,
              pageIndex: pageIndex.current
            });
            const { totalDocs, rows } = data.data;
            const docs = rows.length ? totalDocs : 0;
            setPaginateOptions({
              totalDocs: docs
            });
            rows.map((row) => {
              if (interactionStatus === LEAD_INTERACTION_STATUS.INITIATED) {
                row.disabled = true;
              }

              if (row.interactionStatus === LEAD_INTERACTION_STATUS.INITIATED) {
                if (
                  row.leadAgentTimestamp &&
                  new Date(row.leadAgentTimestamp) < leadAgentSessionExpiry()
                ) {
                  return row;
                }
                row.disabled = true;
                row.iconType = ICON_TYPE.ALREADY_INITIATED;
              } else if (row.appointmentOn) {
                row.iconType = ICON_TYPE.APPOINTMENT_TAKEN;
                row.disabled = true;
              } else if (row.leadQualified) {
                row.iconType = ICON_TYPE.ALREADY_QUALIFIED;
              } else if (
                row.availableAfter &&
                checkLeadAvailability(row.availableAfter)
              ) {
                row.iconType = ICON_TYPE.NOT_AVAILABLE_NOW;
              } else if (
                row.serviceAgent &&
                user._id !== row.serviceAgent._id
              ) {
                row.disabled = true;
                row.iconType = ICON_TYPE.ALREADY_ASSIGNED;
              } else if (shouldContactLater(row.contactLater)) {
                row.iconType = ICON_TYPE.CONTACT_LATER;
              } else {
                row.iconType = null;
              }
              return row;
            });
            setOptions((prev) => [...prev, ...rows]);
          }
        } catch (err) {
          console.log(err);
          showError(
            err?.response?.data?.message ||
              t("toastMessage.errorInGetting", { what: "leads" })
          );
        } finally {
          searchLoader.current = false;
        }
      }, 500),
    [t, user, searchType, interactionStatus]
  );
  const activityHandler = async ({
    type,
    result,
    comment,
    responses,
    contactLaterVal = "",
    appointmentOn = null
  }) => {
    try {
      if (activityLoader) return;
      setActivityLoader(true);
      setCurrentActivityLoader({
        ...defaultCurrentActivityLoader,
        [result]: true
      });
      // If lead is coming by search, then check for service agent before call
      if (result === CALL) {
        if (lead.serviceAgent !== user._id) {
          await validateAndUpdateLeadAgentInfo(lead._id, {
            shouldAssignAgent: true,
            searchVia: leadSearchVia
          });
        }
        // click on hidden call anchor element
        phoneNumberClickRef.current?.click();
      }
      const { data } = await createActivity(
        {
          type,
          result,
          comment,
          searchVia: leadSearchVia,
          contactLaterVal,
          appointmentOn,
          responses
        },
        lead._id
      );
      showSuccess(data?.message);
      if (result === CALL && isQualified) return;
      setComment("");
      setResponses({});

      if (data.data.contactAttempts === 10 && result !== CALL) {
        fetchLead({});
        return;
      }
      if (result === CALL) {
        setInteractionStatus(LEAD_INTERACTION_STATUS.INITIATED);
        resetSearchFieldAndOpts();
        setValue("isInCall", true, { shouldDirty: true });
        handleCallStart();
      } else if (
        [
          NAME_WRONG,
          NUMBER_WRONG,
          NOT_REACHED,
          QUALIFIED,
          NOT_SUITABLE,
          CONTACT_LATER
        ].includes(result)
      ) {
        fetchLead({ funnelId: selectedFunnel?._id, searchType });
        setInteractionStatus(LEAD_INTERACTION_STATUS.HANDLED);
        handleCallEnd();
      } else {
        setInteractionStatus(LEAD_INTERACTION_STATUS.HANDLED);
        handleCallEnd();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data?.data?.isAlreadyTaken) {
        showError(t("leadAgent.alreadyTakenMsg"));
        navigate(0);
      } else {
        showError(error.response?.data?.message);
      }
    } finally {
      setCurrentActivityLoader(defaultCurrentActivityLoader);
      setActivityLoader(false);
    }
  };
  const handleUpdateLead = async (formValues) => {
    try {
      if (lead._id) {
        if (updateLoader) return;
        setUpdateLoader(true);
        const response = await updateLeadById(lead._id, formValues);
        setLead({ ...lead, notCallable: response.data?.data?.notCallable });
        showSuccess(response.data.message);
        setEditMode(defaultEditMode);
      }
    } catch (error) {
      showError(
        error?.response?.data?.message || t("toastMessage.updateLeadError")
      );
      console.log(error);
    } finally {
      setUpdateLoader(false);
    }
  };
  const handleZipCodeValue = (_zipCode) => {
    let zipCode = _zipCode;
    if (
      zipCode?.length === 4 &&
      lead?.funnelId?.companyInfo?.country === BASE_COUNTRY
    ) {
      // concating zero to zipCode if length is 4 and country is germany!
      zipCode = `0${zipCode}`;
    }
    return zipCode;
  };
  const generateGoogleMapLink = (_lead) => {
    const customerZipCode =
      lead?.funnelId?.alternativeAddress?.enabled &&
      lead?.funnelId?.alternativeAddress?.zipCode
        ? lead?.funnelId?.alternativeAddress?.zipCode
        : _lead?.funnelId?.customer?.zipCode;
    const leadZipCode = handleZipCodeValue(_lead?.leadDetails?.leadZip);
    return `https://www.google.com/maps/dir/+${customerZipCode},/+${leadZipCode}`;
  };
  const resetSearchFieldAndOpts = () => {
    setOptions([]);
    setInputValue("");
  };
  const onSelectSearchType = (type) => {
    if (searchType === type) return;
    resetSearchFieldAndOpts();
    setSearchType(type);
  };
  const generateOptLabelString = (opt) => {
    if (!opt) return;
    return `${
      searchType === SEARCH_LEAD_BY.PHONE
        ? `${opt.leadDetails?.leadName} (${opt.leadDetails?.leadPhone})`
        : searchType === SEARCH_LEAD_BY.EMAIL
          ? `${opt.leadDetails?.leadEmail} (${opt.leadDetails?.leadPhone})`
          : searchType === SEARCH_LEAD_BY.FUNNEL
            ? `${opt.general?.funnelName} (${opt.general?.funnelType})`
            : `---`
    }`;
  };
  const onSearchInputChange = (searchVal, isnum) => {
    if (
      searchType === SEARCH_LEAD_BY.FUNNEL ||
      searchType === SEARCH_LEAD_BY.EMAIL ||
      (searchType === SEARCH_LEAD_BY.PHONE && isnum)
    ) {
      pageIndex.current = 1;
      searchLoader.current = searchVal ? true : false;
      setOptions([]);
      setInputValue(searchVal);
    }
  };
  const handleInputChange = (action, _inputValue) => {
    if (action === "input-change") {
      const isnum = /^\d+$/.test(_inputValue);
      onSearchInputChange(_inputValue, isnum);
      if (searchType === SEARCH_LEAD_BY.FUNNEL) {
        fetchFunnelOptions(_inputValue);
      } else if (
        searchType === SEARCH_LEAD_BY.EMAIL ||
        (searchType === SEARCH_LEAD_BY.PHONE && isnum)
      ) {
        fetchLeadOptions(_inputValue);
      } else {
        resetSearchFieldAndOpts();
      }
    }
  };
  const isEditing = (_editMode) => _editMode === true;
  const handleEditMode = (property) =>
    setEditMode({
      ...editMode,
      [property]: true
    });
  const cancelEdit = () => {
    setEditMode(defaultEditMode);
    reset({ ...getValues() }, { keepDirty: watch("isInCall") });
  };
  const checkIfZipCodeIsMissing = (_lead) => {
    const alternativeZip = _lead.funnelId?.alternativeAddress?.zipCode;
    const customerZip = _lead.funnelId?.customer?.zipCode;
    const leadZip = _lead?.leadDetails?.leadZip;

    if (_lead.funnelId?.alternativeAddress?.enabled) {
      if ((alternativeZip || customerZip) && leadZip) {
        return false;
      }
    }
    if (customerZip && leadZip) {
      return false;
    }
    return true;
  };
  const renderCustomerInfo = (
    leadFunnel,
    objProperty,
    nestedObjProperty = ""
  ) => {
    const { alternativeAddress, customer } = leadFunnel;
    let info = "---";
    if (alternativeAddress?.enabled && alternativeAddress?.[objProperty]) {
      if (nestedObjProperty) {
        info = alternativeAddress[objProperty][nestedObjProperty];
      } else {
        info = alternativeAddress[objProperty];
      }
    } else if (customer?.[objProperty]) {
      if (nestedObjProperty) {
        info = customer[objProperty][nestedObjProperty];
      } else {
        info = customer[objProperty];
      }
    }
    return info;
  };
  const renderRestrictedCallTooltip = (lead) => {
    return availableAfterFlag ? (
      <div>
        <div>{`${t("leadAgent.available")} ${t("leadAgent.after")}`}:</div>
        <div>{generateDateTime(lead.availableAfter)}</div>
      </div>
    ) : contactLaterFlag ? (
      <div>
        <div>{t("leadAgent.contactLater")}:</div>
        <div>{generateDate(lead.contactLater)}</div>
      </div>
    ) : null;
  };
  const handleAnswerChange = (questionName, value) => {
    setResponses((prev) => {
      const updated = { ...prev };
      if (value.trim() === "") {
        delete updated[questionName];
      } else {
        updated[questionName] = value;
      }
      return updated;
    });
  };
  useEffect(() => {
    clearInterval(timer.current);
    const updateLeadAgentTimestampHandler = async () => {
      try {
        await validateAndUpdateLeadAgentInfo(lead._id, {
          shouldAssignAgent: false
        });
      } catch (error) {
        console.log(error);
        if (error.response?.data?.data?.isAlreadyTaken) {
          showError(t("leadAgent.alreadyTakenMsg"));
          navigate(0);
        } else {
          showError(error.response?.data?.message);
        }
      }
    };
    if (lead?._id) {
      timer.current = setInterval(() => {
        updateLeadAgentTimestampHandler();
      }, LEAD_AGENT_TIMESTAMP_UPDATE_DELAY);
    } else {
      clearInterval(timer.current);
    }
    // eslint-disable-next-line
  }, [lead, navigate]);
  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);
  useEffect(() => {
    fetchLead({});
  }, [fetchLead]);

  return (
    <>
      <FormProvider {...methods}>
        <PreventReload message="Please mark the result of the call" />
        <div className="d-flex justify-content-end my-3">
          <Col xs={12} sm={8} md={5} lg={3}>
            <InputGroup>
              <SplitButton
                id="search-by-lead-info"
                title={searchType.toLowerCase()}
                bsPrefix={"text-capitalize btn btn-outline-secondary"}
              >
                {Object.keys(leadSearchOptions).map((type, idx) => (
                  <DropdownItem
                    key={idx}
                    className="text-capitalize"
                    onClick={() => onSelectSearchType(type)}
                  >
                    {type.toLowerCase()}
                  </DropdownItem>
                ))}
              </SplitButton>
              <SelectContainer
                isClearable
                className="flex-fill"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: "0 4px 4px 0",
                    borderColor: "var(--field-border-color-regular)"
                  }),
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 1021 })
                }}
                placeholder={`${t("leadAgent.searchBy")}...`}
                isLoading={searchLoader.current}
                onChange={(option, action) => {
                  if (action?.action === "select-option") {
                    if (searchType === SEARCH_LEAD_BY.FUNNEL) {
                      setSelectedFunnel(option);
                      fetchLead({ funnelId: option._id, searchType });
                    } else if (
                      searchType === SEARCH_LEAD_BY.EMAIL ||
                      searchType === SEARCH_LEAD_BY.PHONE
                    ) {
                      fetchLead({ leadId: option._id, searchType });
                    }
                  }
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                  Option,
                  MenuList,
                  MenuListFooter: (
                    <MenuListFooter
                      showing={options?.length}
                      total={paginateOptions.totalDocs}
                      onClick={() => {
                        searchLoader.current = true;
                        pageIndex.current = pageIndex.current + 1;
                        if (searchType === SEARCH_LEAD_BY.FUNNEL) {
                          fetchFunnelOptions(inputValue);
                        } else if (
                          searchType === SEARCH_LEAD_BY.EMAIL ||
                          searchType === SEARCH_LEAD_BY.PHONE
                        ) {
                          fetchLeadOptions(inputValue);
                        }
                      }}
                      isDisabled={
                        interactionStatus === LEAD_INTERACTION_STATUS.INITIATED
                      }
                    />
                  )
                }}
                isOptionDisabled={(option) => option.disabled}
                getOptionLabel={(option) => generateOptLabelString(option)}
                getOptionValue={(option) => option._id}
                inputValue={inputValue}
                value={inputValue}
                options={options}
                onInputChange={(inputValue, { action }) =>
                  handleInputChange(action, inputValue)
                }
              />
            </InputGroup>
          </Col>
        </div>
        <AnimationContainer key={lead?._id} className="mb-4">
          {!isLoading ? (
            lead?._id ? (
              <CustomCard className="p-4">
                <Row>
                  <Col md={12} lg={6}>
                    <CustomDiv className="mb-4 position-relative">
                      {leadSearchVia !== SEARCH_LEAD_BY.AUTO && (
                        <AbsoluteInfoContainer>
                          <ExtraInfoCustomBadge className="position-absolute bg-primary text-white fw-bold text-center">
                            {`${t("leadAgent.searchBy")}: ${leadSearchVia}`}
                          </ExtraInfoCustomBadge>
                        </AbsoluteInfoContainer>
                      )}
                      <form>
                        <div className="d-flex mb-2">
                          <div className="d-flex align-items-center text-break">
                            {editMode.leadName ? (
                              <Form.Group>
                                <Form.Control
                                  required
                                  placeholder={t("leadAgent.enterValue")}
                                  {...register("leadName")}
                                  isInvalid={errors?.leadName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors?.leadName?.message}
                                </Form.Control.Feedback>
                              </Form.Group>
                            ) : (
                              <>
                                <h3 className="fw-bold mb-0">
                                  {leadData.leadName}
                                </h3>
                                <Button
                                  variant="outlined"
                                  disabled={isQualified}
                                  className="text-secondary border-0"
                                  onClick={() => handleEditMode("leadName")}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </Button>
                              </>
                            )}
                          </div>
                          <div className="ms-4 d-flex align-self-center">
                            <CustomBadge
                              className="px-3 py-2 d-flex align-items-center fw-normal"
                              btnbg={`--${lead?.leadDetails?.leadType?.toLowerCase()}-badge-bg`}
                              btncolor={`--${lead?.leadDetails?.leadType?.toLowerCase()}-color`}
                            >
                              {lead?.leadDetails?.leadType?.toLowerCase()}
                            </CustomBadge>
                          </div>
                          <div className="ms-3 align-self-center">
                            <CustomBadge className="p-2 bg-light text-dark fw-normal border">
                              {isQualified ? (
                                <>
                                  <FontAwesomeIcon
                                    className="text-success me-2"
                                    icon={faCircleCheck}
                                  />
                                  {t("leadAgent.successfullyQualified")}
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    className="text-danger me-2"
                                    icon={faXmarkCircle}
                                  />
                                  {t("leadAgent.notQualifiedYet")}
                                </>
                              )}
                            </CustomBadge>
                          </div>
                          {!lead.notCallable && (
                            <div className="ms-3 align-self-center">
                              <CustomBadge className="p-2 bg-light border text-dark fw-normal">
                                {lead.availableAfter &&
                                checkLeadAvailability(lead.availableAfter) ? (
                                  <AvailabilityBadgeContent
                                    icon={faClock}
                                    generator={generateDateTime}
                                    dateValue={lead.availableAfter}
                                    tooltipText={`${t(
                                      "leadAgent.available"
                                    )} ${t("leadAgent.after")}`}
                                  />
                                ) : shouldContactLater(lead.contactLater) ? (
                                  <AvailabilityBadgeContent
                                    icon={faCalendarDays}
                                    generator={generateDate}
                                    dateValue={lead.contactLater}
                                    tooltipText={t("leadAgent.contactLater")}
                                  />
                                ) : (
                                  <>{t("leadAgent.available")}!</>
                                )}
                              </CustomBadge>
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <div className="align-self-center text-break">
                            ID: {lead?._id}
                          </div>
                          <DotSeparator></DotSeparator>
                          <div className="align-self-center">
                            {generateDateTime(lead.createdAt)} Uhr
                          </div>
                          <DotSeparator></DotSeparator>
                          {editMode.leadEmail ? (
                            <Form.Group>
                              <Form.Control
                                required
                                placeholder={t("leadAgent.enterValue")}
                                {...register("leadEmail")}
                                isInvalid={errors?.leadEmail}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors?.leadEmail?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : (
                            <div className="text-break d-flex align-items-center justify-content-between">
                              <span>{leadData.leadEmail}</span>
                              <Button
                                variant="outlined"
                                onClick={() => handleEditMode("leadEmail")}
                                className="text-secondary border-0 pe-0"
                                disabled={isQualified}
                                size="sm"
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </Button>
                            </div>
                          )}
                          <DotSeparator></DotSeparator>
                          {editMode.leadPhone ? (
                            <Form.Group>
                              <Form.Control
                                required
                                placeholder={t("leadAgent.enterValue")}
                                {...register("leadPhone")}
                                isInvalid={errors?.leadPhone}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors?.leadPhone?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : (
                            <div className="text-break d-flex align-items-center justify-content-between">
                              {generatePhoneNumber(leadData.leadPhone)}
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setValue(
                                    "leadPhone",
                                    generatePhoneNumber(leadData.leadPhone),
                                    { shouldDirty: true }
                                  );
                                  handleEditMode("leadPhone");
                                }}
                                className="border-0 text-secondary"
                                disabled={isQualified}
                                size="sm"
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </Button>
                            </div>
                          )}
                        </div>
                        <Collapse in={Object.values(editMode).some(isEditing)}>
                          <div>
                            <div className="mt-3 text-end">
                              <Button
                                className="me-2"
                                variant="secondary"
                                onClick={cancelEdit}
                              >
                                {t("common.cancel")}
                              </Button>
                              <Button
                                type="button"
                                disabled={updateLoader || isQualified}
                                onClick={handleSubmit(handleUpdateLead)}
                              >
                                {updateLoader
                                  ? `${t("common.update")}...`
                                  : t("common.update")}
                              </Button>
                            </div>
                          </div>
                        </Collapse>
                      </form>
                    </CustomDiv>
                    <CustomDiv className="mb-4 py-4 position-relative">
                      <Row>
                        <AddressColumn xs={6}>
                          <h5 className="fw-bold mb-0">
                            {lead?.funnelId?.companyNameForLeadAgent ||
                              lead?.funnelId?.customer?.companyName ||
                              "---"}
                          </h5>
                          <div>
                            <div>
                              {renderCustomerInfo(lead?.funnelId, "address")}
                            </div>
                            <span className="me-1">
                              {renderCustomerInfo(lead?.funnelId, "zipCode")},
                            </span>
                            <span className="text-capitalize">
                              {renderCustomerInfo(
                                lead?.funnelId,
                                "city",
                                "name"
                              )}
                            </span>
                            <div>
                              {renderCustomerInfo(lead?.funnelId, "country")}
                            </div>
                          </div>
                          {user.role === USER_ROLES.ADMIN &&
                          emailsToSend.length ? (
                            <div className="mt-2">
                              <div className="d-inline-block fw-bold mb-1 fs-6 border-dark border-bottom">
                                {t("leadAgent.emailsToSendHeading")}:
                              </div>
                              <div>
                                {emailsToSend.map((email, idx) => (
                                  <div key={idx} className="ms-1 mb-1">
                                    {email.emailAddress}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </AddressColumn>
                        <Col xs={6} className="py-1 px-3">
                          <AbsoluteInfoContainer>
                            {checkIfZipCodeIsMissing(lead) && (
                              <ExtraInfoCustomBadge className="position-absolute bg-danger text-white fw-bold text-center">
                                {t("leadAgent.postalCodeMissingMsg")}
                              </ExtraInfoCustomBadge>
                            )}
                            <MapLink
                              href={generateGoogleMapLink(lead)}
                              target="_blank"
                              rel="noreferrer"
                              className={`d-flex align-items-center text-decoration-none text-dark ${
                                checkIfZipCodeIsMissing(lead)
                                  ? "pe-none text-muted"
                                  : ""
                              }`}
                            >
                              <FontAwesomeIcon
                                size="xl"
                                icon={faLocationDot}
                                className="me-2 text-secondary"
                              />
                              <CalcDistanceText
                                className={`fw-bold ${
                                  checkIfZipCodeIsMissing(lead)
                                    ? "text-muted"
                                    : "text-dark"
                                }`}
                              >
                                {t("leadAgent.calcDistance")}
                              </CalcDistanceText>
                            </MapLink>
                          </AbsoluteInfoContainer>
                          <CustomFontSizeDiv
                            fs="12"
                            className="text-secondary fw-bold"
                          >
                            {lead?.leadDetails?.leadType ===
                            FUNNEL_TYPE_FILTER.EMPLOYEE
                              ? t("common.job").toUpperCase()
                              : t("common.product").toUpperCase()}
                          </CustomFontSizeDiv>
                          <CustomFontSizeDiv fs="18" className="fw-bold">
                            {lead?.funnelId?.leadServiceProductName ||
                              lead?.funnelId?.category?.name}
                          </CustomFontSizeDiv>
                          {lead?.appointmentOn && (
                            <>
                              <CustomFontSizeDiv
                                fs="12"
                                className="text-secondary fw-bold"
                              >
                                {t("common.appointmentDetail").toUpperCase()}
                              </CustomFontSizeDiv>
                              <CustomFontSizeDiv fs="18" className="fw-bold">
                                {generateDateTime(
                                  lead.appointmentOn,
                                  DATE_TIME_WITH_MONTH_FORMAT
                                ) || "No Appointment"}
                              </CustomFontSizeDiv>
                            </>
                          )}
                        </Col>
                      </Row>
                    </CustomDiv>
                    <CustomDiv>
                      <Row>
                        <Col xs={6} className="align-self-center">
                          {interactionStatus !==
                          LEAD_INTERACTION_STATUS.INITIATED ? (
                            <div className="text-center">
                              {(availableAfterFlag || contactLaterFlag) && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {renderRestrictedCallTooltip(lead)}
                                    </Tooltip>
                                  }
                                >
                                  <ForceCallContainer
                                    className="me-2"
                                    onClick={() => {
                                      setContactLaterFlag(false);
                                      setAvailableAfterFlag(false);
                                    }}
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        className="me-1 text-danger"
                                        icon={faBellSlash}
                                      />
                                      {t("leadAgent.callAnyway")}?
                                    </div>
                                  </ForceCallContainer>
                                </OverlayTrigger>
                              )}

                              <ActivityButton
                                activityHandler={activityHandler}
                                currentActivityLoader={currentActivityLoader}
                                ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                ACTIVITY_RESULT_TYPE={CALL}
                                isDisabled={
                                  activityLoader ||
                                  availableAfterFlag ||
                                  contactLaterFlag
                                }
                                btnText={t("leadAgent.calling")}
                                btnStyle="btn-success w-100"
                                icon={faPhone}
                                btnTextPrimary={
                                  interactionStatus ===
                                    LEAD_INTERACTION_STATUS.INITIATED ||
                                  activityLoader
                                    ? t("leadAgent.callInProgress")
                                    : t("leadAgent.callContact")
                                }
                                className={"btn btn-success text-white"}
                              />
                              <a
                                hidden
                                ref={phoneNumberClickRef}
                                href={`tel:${generatePhoneNumber(
                                  leadData.leadPhone
                                )}`}
                              >
                                <></>
                              </a>

                              {lead?.funnelId?.companyInfo?.country !==
                                BASE_COUNTRY && (
                                <CountryFlagEmoji
                                  fs="26"
                                  countryName={
                                    lead?.funnelId?.companyInfo?.country
                                  }
                                />
                              )}
                            </div>
                          ) : (
                            <div>
                              <Row>
                                <Col className="pe-0">
                                  <ActivityButton
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_RESULT_TYPE={NOT_REACHED}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      activityLoader
                                    }
                                    btnText={t("leadAgent.sending")}
                                    btnStyle="btn-danger w-100"
                                    icon={faPersonCircleExclamation}
                                    btnTextPrimary={t("leadAgent.notReached")}
                                    variant="danger"
                                    className="text-start mb-2 w-100"
                                  />
                                </Col>
                                <Col className="pe-0">
                                  <ActivityButton
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_RESULT_TYPE={COMPLETED}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      activityLoader
                                    }
                                    btnText={t("leadAgent.completing")}
                                    btnStyle="btn-dark w-100"
                                    icon={faXmark}
                                    btnTextPrimary={t("leadAgent.complete")}
                                    variant="dark"
                                    className="text-start mb-2 w-100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pe-0">
                                  <ActivityButton
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_RESULT_TYPE={NUMBER_WRONG}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      activityLoader
                                    }
                                    btnText={t("leadAgent.sending")}
                                    btnStyle="btn-warning w-100"
                                    icon={faPhoneSlash}
                                    btnTextPrimary={t("leadAgent.wrongNum")}
                                    variant="warning"
                                    className="text-start mb-2 w-100"
                                  />
                                </Col>
                                <Col className="pe-0">
                                  <ActivityButton
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_RESULT_TYPE={NAME_WRONG}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      activityLoader
                                    }
                                    btnText={t("leadAgent.sending")}
                                    btnStyle="btn-warning w-100"
                                    icon={faUser}
                                    btnTextPrimary={t("leadAgent.wrongName")}
                                    variant="warning"
                                    className="text-start mb-2 w-100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pe-0" sm={12} md={6}>
                                  <AgentViewActivityBtn
                                    variant="secondary"
                                    className="text-start w-100"
                                    onClick={togglePickerDialog}
                                  >
                                    <FontAwesomeIcon
                                      className="ms-1 me-2"
                                      icon={faCalendarDays}
                                    />
                                    {t("leadAgent.contactLater")}
                                  </AgentViewActivityBtn>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                        <Col xs={6}>
                          <CustomFontSizeDiv
                            fs="12"
                            className="text-secondary fw-bold"
                          >
                            {t("leadAgent.notes")}
                          </CustomFontSizeDiv>
                          <div>
                            <Form.Control
                              as="textarea"
                              className="mt-1 p-3"
                              onChange={({ target }) =>
                                setComment(target.value)
                              }
                              placeholder={t("leadAgent.notesPlaceholder")}
                              value={comment}
                              disabled={
                                interactionStatus !==
                                  LEAD_INTERACTION_STATUS.INITIATED ||
                                activityLoader
                              }
                            />
                          </div>
                          {interactionStatus ===
                            LEAD_INTERACTION_STATUS.INITIATED && (
                            <>
                              <Row className="mt-2">
                                <Col>
                                  <ActivityButton
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    ACTIVITY_RESULT_TYPE={QUALIFIED}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      !comment ||
                                      activityLoader ||
                                      Object.keys(responses).length !==
                                        lead?.funnelId?.leadServiceQuestions
                                          .length
                                    }
                                    variant="success"
                                    btnText={t("leadAgent.sending")}
                                    btnStyle="btn-success flex-fill w-100"
                                    btnTextPrimary={t("leadAgent.qualified")}
                                    comment={comment}
                                    responses={responses}
                                    className="w-100"
                                    funnelAppointment={funnelAppointment}
                                    appointmentDialog={toggleAppointmentDialog}
                                  />
                                </Col>
                                <Col>
                                  <ActivityButton
                                    activityHandler={activityHandler}
                                    currentActivityLoader={
                                      currentActivityLoader
                                    }
                                    ACTIVITY_TYPE={ACTIVITY_TYPES.CALL}
                                    ACTIVITY_RESULT_TYPE={NOT_SUITABLE}
                                    isDisabled={
                                      interactionStatus !==
                                        LEAD_INTERACTION_STATUS.INITIATED ||
                                      !comment ||
                                      activityLoader
                                    }
                                    variant="danger"
                                    btnText={t("leadAgent.sending")}
                                    btnStyle="btn-danger flex-fill w-100"
                                    btnTextPrimary={t("leadAgent.notSuitable")}
                                    comment={comment}
                                    className="w-100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                {lead?.funnelId?.customerAppointmentLink && (
                                  <Col>
                                    <Card
                                      bg="warning"
                                      className="mt-3 p-4 border-0"
                                    >
                                      <h6 className="text-center">
                                        {t(
                                          "leadAgent.appointmentWitHtheCustomer"
                                        )}
                                      </h6>
                                      <HorizontalDivider />
                                      <Button
                                        as={"a"} // anchor
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                          lead.funnelId?.customerAppointmentLink
                                        }
                                        // onClick={toggleAppointmentDialog}
                                        disabled={isQualified}
                                      >
                                        {t("leadAgent.appointmentInCalendy")}
                                      </Button>
                                    </Card>
                                  </Col>
                                )}
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </CustomDiv>
                    {lead?.funnelId?.leadServicePlus &&
                      interactionStatus ===
                        LEAD_INTERACTION_STATUS.INITIATED && (
                        <AgentPricePrompt
                          setCommentPrice={setCommentPrice}
                          leadFunnel={lead?.funnelId}
                        />
                      )}

                    {lead?.funnelId?.leadServiceQuestions.length > 0 && (
                      <CustomDiv className="mt-4">
                        {lead?.funnelId?.leadServiceQuestions.map(
                          (question, index) => (
                            <div className="mb-2" key={index}>
                              <Form.Label className="text-secondary mb-0">
                                {question.name}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="mt-1"
                                onChange={({ target }) =>
                                  handleAnswerChange(
                                    question.name,
                                    target.value
                                  )
                                }
                                placeholder={t("leadAgent.answer")}
                                value={responses[question.name] || ""}
                                disabled={
                                  interactionStatus !==
                                    LEAD_INTERACTION_STATUS.INITIATED ||
                                  activityLoader
                                }
                              />
                            </div>
                          )
                        )}
                      </CustomDiv>
                    )}
                  </Col>
                  <Col md={12} lg={2} className="mt-4 mt-lg-0">
                    <CustomCard>
                      <ControlledHeightContainer>
                        <CustomCardHeader className="sticky-top bg-light fw-bold">
                          {t("leadActivities.title")}
                        </CustomCardHeader>
                        {isLoadingActivities ? (
                          <ControlledHeightLoaderContainer className="d-flex align-items-center justify-content-center">
                            <Loader />
                          </ControlledHeightLoaderContainer>
                        ) : (
                          <div className="p-3">
                            <LeadActivities activities={activities} />
                          </div>
                        )}
                      </ControlledHeightContainer>
                    </CustomCard>
                  </Col>
                  <Col md={12} lg={4} className="mt-4 mt-lg-0">
                    <CustomCard>
                      <ControlledHeightContainer>
                        <CustomCardHeader className="sticky-top bg-light fw-bold">
                          {t("script")}
                        </CustomCardHeader>
                        <div className="p-2">
                          {lead?.funnelId?.leadServicePlus ? (
                            <PDFViewer
                              objHeight="650"
                              ariaLabel="ls-plus"
                              scriptUrl={generateScriptUrl({
                                workspace: user.workspace,
                                scriptProp: "leadServiceScript",
                                localFileName: "default.pdf"
                              })}
                            />
                          ) : (
                            <PDFViewer
                              objHeight="650"
                              ariaLabel="default"
                              scriptUrl={generateScriptUrl({
                                workspace: user.workspace,
                                scriptProp: "leadServicePlusScript",
                                localFileName: "leadservice_plus.pdf"
                              })}
                            />
                          )}
                        </div>
                      </ControlledHeightContainer>
                    </CustomCard>
                  </Col>
                </Row>
              </CustomCard>
            ) : searchType === SEARCH_LEAD_BY.FUNNEL && selectedFunnel ? (
              <div className="text-center fs-6">
                <Trans
                  i18nKey={t("leadAgent.allOpenLeadsContactedMsg", {
                    funnelName: selectedFunnel.general?.funnelName
                  })}
                />
                <div className="mt-2">
                  <Button size="sm" onClick={() => fetchLead({})}>
                    <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
                    {t("leadAgent.backToNormalBtn")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-5 text-center fs-4">
                {t("leadAgent.noLeads")}
              </div>
            )
          ) : (
            <Loader />
          )}
        </AnimationContainer>
      </FormProvider>
      <PickerDialog
        show={showPickerDialog}
        onHide={togglePickerDialog}
        activityHandler={activityHandler}
      />
      <AppointmentPickerDialog
        show={showAppointmentDialog}
        onHide={toggleAppointmentDialog}
        activityHandler={activityHandler}
        allowClose={false}
        comment={comment}
        responses={responses}
      />
    </>
  );
};

export default LeadAgent;
