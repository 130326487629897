import { useFormContext } from "react-hook-form";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ColorPickerWithTextField from "../../../../reusable-form-fields/color-picker-with-text-field";
import { useRef } from "react";

const BenefitsGridLabelAndButtonRow = ({ fields = [], addNew, gridLimit }) => {
  const { t } = useTranslation();
  const benefitTextColorPickerRef = useRef("");
  const {
    watch,
    formState: { errors }
  } = useFormContext();
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className={`fw-bold ${
                errors?.data?.benefitsGrid ? "text-danger" : ""
              }`}
            >
              {`${t("preLandingPageOne.benefits.benefitsGridLabel")}
              ${errors?.data?.benefitsGrid ? "*" : ""}`}
            </div>
            <div className="ms-3">
              <Button
                className="w-100"
                onClick={addNew}
                disabled={fields.length === gridLimit}
              >
                {t("preLandingPageOne.benefits.addNewBenefitBtn")}
                {fields.length ? (
                  <Badge bg="secondary" className="rounded-circle ms-2">
                    {fields.length}
                  </Badge>
                ) : (
                  ""
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {fields.length ? (
        <Row className="mt-3">
          <Col xs={12} md={6}>
            <ColorPickerWithTextField
              label={t("preLandingPageOne.benefits.benefitsGridTextColor")}
              fieldName="data.benefitsGridTextColor"
              fieldError={errors?.data?.benefitsGridTextColor}
              fieldRef={benefitTextColorPickerRef}
              fieldVal={watch("data.benefitsGridTextColor")}
              isInGroup
              noSpacing
              hideErrMsg
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
export default BenefitsGridLabelAndButtonRow;
