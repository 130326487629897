import _debounce from "lodash/debounce";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Form, Button, Container, Alert } from "react-bootstrap";
import {
  faExclamationCircle,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {
  CustomCard,
  ValiditySpinner,
  HorizontalDivider
} from "../common-styling";
import Loader from "../loader/loader";
import {
  showError,
  showSuccess,
  showWarning
} from "../../services/toast-service";
import {
  updateTag,
  createTag,
  getSingleTag,
  getTagByName
} from "../../services/api-declaration";
import NavigateBackButton from "../back-button";
import TextArea from "../reusable-form-fields/text-area";
import PreventReload from "../funnel-form/prevent-reload";
import SpinnerButton from "../spinner-button/spinner-button";
import { TagFormSchema } from "../../services/validation-schema";
import ValidationMessage from "../validation-message/validation-message";
import { useTranslation, Trans } from "react-i18next";

const TagsForm = ({ initialValue = null }) => {
  const { t } = useTranslation();
  const params = useParams();
  const saveBtnRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = { name: initialValue, description: "" };
  const methods = useForm({
    resolver: yupResolver(TagFormSchema),
    defaultValues: initialValues
  });
  const {
    formState: { errors },
    reset,
    register,
    getValues,
    handleSubmit
  } = methods;

  const handleNameChange = (fieldVal) => {
    if (fieldVal) {
      saveBtnRef.current.disabled = true;
      setIsNameChanged(true);
    }
  };
  const handleDebounceCb = useMemo(
    () =>
      _debounce(async (name) => {
        try {
          if (name) {
            const response = await getTagByName({ name });
            if (response.data.data && response.data.data._id !== params.id) {
              setIsNameValid(false);
              saveBtnRef.current.disabled = true;
              showWarning(`Tag: ${name} ${t("common.isAlreadyTaken")}`);
            } else {
              saveBtnRef.current.disabled = false;
              setIsNameValid(true);
            }
          }
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message || "Error in loading tag by name!"
          );
        } finally {
          setIsNameChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsNameValid, saveBtnRef, params.id]
  );
  const navigateBack = () => navigate(-1);
  const onSubmit = (formValues) => handleSubmitTag(formValues);
  const handleSubmitTag = async (formData) => {
    try {
      setIsSubmitting(true);
      if (params.id) {
        const response = await updateTag(params.id, formData);
        if (response) {
          showSuccess(response.data.message);
        }
      } else {
        const response = await createTag(formData);
        if (response) {
          showSuccess(response.data.message);
          navigateBack();
        }
      }
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || "Error in saving tag!");
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    const fetchTag = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleTag(params.id);
        console.log("response:", response);
        reset(response.data.data);
        console.log(getValues("systemTag"));
      } catch (err) {
        console.log(err);
        showError(err.response.data.message || "Error in loading tag by id!");
      } finally {
        setIsLoading(false);
      }
    };
    if (params.id) {
      fetchTag();
    }
  }, [params.id, reset, getValues]);

  return params.id && isLoading ? (
    <Loader />
  ) : (
    <>
      {/* Container on above fragment */}
      <FormProvider {...methods}>
        <PreventReload />
        <form
          onSubmit={(event) => {
            handleSubmit(onSubmit)(event);
          }}
        >
          <div className="py-4 px-0 d-flex align-items-center">
            {params.id && (
              <div className="me-3">
                <NavigateBackButton />
              </div>
            )}
            <h5 className="mb-0 me-4">Tag Form</h5>
          </div>
          <CustomCard className="mb-4">
            <Container fluid className="p-4">
              {getValues("systemTag") ? (
                <div className="mb-4">
                  <Alert
                    key="warning"
                    variant="warning"
                    className="w-100 mb-0"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px"
                    }}
                  >
                    <FontAwesomeIcon className="me-2" icon={faWarning} />
                    <Trans i18nKey={t("tagform.uneditableSystemTagMessage")} />
                  </Alert>
                </div>
              ) : (
                ""
              )}
              <Row className="mb-4">
                <Col xs={12} md={12}>
                  <Form.Group controlId="tagName">
                    <Form.Label className={errors?.name && "text-danger"}>
                      Tag Name
                      {errors?.name && "*"}
                    </Form.Label>
                  </Form.Group>
                  <Row className="align-items-center">
                    <Col xs={isNameChanged ? 11 : 12}>
                      <Form.Control
                        type="text"
                        placeholder={t("common.name")}
                        className={`form-control ${
                          errors?.name && "border-danger"
                        }`}
                        disabled={getValues("systemTag")}
                        {...(register && {
                          ...register("name", {
                            onChange: ({ target: { value } }) => {
                              handleNameChange(value);
                              handleDebounceCb(value);
                              return value;
                            }
                          })
                        })}
                      />
                    </Col>
                    {isNameChanged && (
                      <Col xs={1}>
                        <ValiditySpinner
                          animation="border"
                          variant="secondary"
                          className="rounded-circle"
                        />
                      </Col>
                    )}
                  </Row>
                  {!isNameValid && !isNameChanged && (
                    <small className="mt-1 text-danger fw-bold">
                      <FontAwesomeIcon
                        className="me-2"
                        icon={faExclamationCircle}
                      />
                      {t("common.alreadyTaken")}
                    </small>
                  )}
                  {errors?.name && (
                    <ValidationMessage error={errors?.name.message} />
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12} md={12}>
                  <TextArea
                    label="Tag Description"
                    name="description"
                    placeholder="Enter Tag Description..."
                    disabled={getValues("systemTag")}
                    register={register}
                  />
                </Col>
              </Row>
              <HorizontalDivider />
              <div className="mt-4 d-flex justify-content-end">
                <Button
                  className="me-2"
                  onClick={navigateBack}
                  variant="outline-secondary"
                  disabled={getValues("systemTag")}
                >
                  {t("tagform.cancel")}
                </Button>
                {isSubmitting ? (
                  <SpinnerButton
                    btnText="Tag speichern"
                    btnStyle="btn-success"
                  />
                ) : (
                  <Button
                    type="submit"
                    ref={saveBtnRef}
                    variant="outline-success"
                    disabled={getValues("systemTag")}
                  >
                    {t("tagform.save")}
                  </Button>
                )}
              </div>
            </Container>
          </CustomCard>
        </form>
      </FormProvider>
    </>
  );
};
export default TagsForm;
