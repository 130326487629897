import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";
import TextArea from "../../reusable-form-fields/text-area";

const HeroSection = ({
  parseResourceSrc,
  parsePreviewResourceSrc,
  index,
  isFormChanged
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    register
  } = useFormContext();

  const HeroBGColorDisplay = watch(`sections[${index}].data.bgColorDisplay`);
  const HeroBtnBGColorDisplay = watch(
    `sections[${index}].data.btn.bgColorDisplay`
  );
  const heroImage = watch(`sections[${index}].data.image`);

  const heroBgColorPickerRef = useRef();
  const heroHeadingColorPickerRef = useRef();
  const heroParaColorPickerRef = useRef();
  const heroBulletsColorPickerRef = useRef();
  const heroButtonColorPickerRef = useRef();
  const heroButtonBgColorPickerRef = useRef();

  return (
    <>
      <Row className="mt-1">
        <Col md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.BGColor")}
            fieldName={`sections[${index}].data.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bgColor
            }
            fieldRef={heroBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bgColor`)}
            displayFieldWatcher={HeroBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
            isDisabled={Boolean(!HeroBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <TextField
            label={t("customerWebsite.heading")}
            placeholder={t("customerWebsite.heading")}
            type="text"
            name={`sections[${index}].data.heading.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.heading?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.headingColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.heading.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.heading?.color
            }
            fieldRef={heroHeadingColorPickerRef}
            fieldVal={watch(`sections[${index}].data.heading.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <TextArea
            label={t("customerWebsite.para")}
            name={`sections[${index}].data.para.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.para?.text
            }
            register={register}
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.paraColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.para.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.para?.color
            }
            fieldRef={heroParaColorPickerRef}
            fieldVal={watch(`sections[${index}].data.para.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={12} md={4}>
          <TextField
            label={`${t("customerWebsite.bullet")} #1`}
            placeholder={`${t("customerWebsite.bullet")} #1`}
            type="text"
            name={`sections[${index}].data.bullets.data[0]`}
            errors={
              errors?.sections &&
              errors?.sections[index]?.data?.bullets?.data &&
              errors?.sections[index]?.data?.bullets?.data[0]
            }
            register={register}
          />
        </Col>
        <Col sm={12} md={4}>
          <TextField
            label={`${t("customerWebsite.bullet")} #2`}
            placeholder={`${t("customerWebsite.bullet")} #2`}
            type="text"
            name={`sections[${index}].data.bullets.data[1]`}
            errors={
              errors?.sections &&
              errors?.sections[index]?.data?.bullets?.data &&
              errors?.sections[index]?.data?.bullets?.data[1]
            }
            register={register}
          />
        </Col>
        <Col sm={12} md={4}>
          <TextField
            label={`${t("customerWebsite.bullet")} #3`}
            placeholder={`${t("customerWebsite.bullet")} #3`}
            type="text"
            name={`sections[${index}].data.bullets.data[2]`}
            errors={
              errors?.sections &&
              errors?.sections[index]?.data?.bullets?.data &&
              errors?.sections[index]?.data?.bullets?.data[2]
            }
            register={register}
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.bulletsColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.bullets.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bullets?.color
            }
            fieldRef={heroBulletsColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bullets.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
        <Col xs={12} md={6}>
          <FileInput
            label={t("customerWebsite.image")}
            name={`sections[${index}].data.image`}
            // resourceId={resourceId}
            parentFolderName={"customer"}
            url={parseResourceSrc(heroImage)}
            urlForPreview={parsePreviewResourceSrc(heroImage)}
            asset={heroImage?.asset}
            error={errors?.sections && errors?.sections[index]?.data?.image}
            isFormChanged={isFormChanged}
            tagName="website_startbereich"
            // selectedCategory={getValues("category")}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={12} md={3}>
          <TextField
            label={t("customerWebsite.catBtnText")}
            placeholder={t("customerWebsite.catBtnText")}
            type="text"
            name={`sections[${index}].data.btn.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.btn?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col sm={12} md={3}>
          <TextField
            label={t("customerWebsite.catBtnLink")}
            placeholder="CAT Button Link"
            type="text"
            name={`sections[${index}].data.btn.link`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.btn?.link
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.catBtnColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.btn.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.btn?.color
            }
            fieldRef={heroButtonColorPickerRef}
            fieldVal={watch(`sections[${index}].data.btn.color`)}
            defaultColorValue="#ffffff"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
        <Col xs={12} md={3}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.catBtnBGColor")}
            fieldName={`sections[${index}].data.btn.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.btn?.bgColor
            }
            fieldRef={heroButtonBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.btn.bgColor`)}
            displayFieldWatcher={HeroBtnBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.btn.bgColorDisplay`}
            isDisabled={Boolean(!HeroBtnBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
    </>
  );
};

export default HeroSection;
