import { useState } from "react";
import {
  faEdit,
  faCheck,
  faXmark,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { Col, Collapse } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useParams, useOutletContext } from "react-router";
import {
  updateCompanyNameForAgent,
  updateProductNameForAgent,
  updateBudgetQueriesForAgent,
  updatePricesPerAreaForAgent,
  updatePricePerInvoiceUnitForAgent,
  updateNameOfAccountingUniForAgent,
  updateFixedPricePerInvoiceUnitForAgent,
  updateLeadServicePlusPriceTypeForAgent,
  updateLeadServiceQuestionForAgent
} from "../../../services/api-declaration";
import { useDialog } from "../../../hooks/useDialog";
import LeadServiceActionButton from "./lead-service-action-button";
import { LEAD_SERVICE_PLUS_EDIT_MODES, PRICE_TYPE } from "../../strings";
import { showError, showSuccess } from "../../../services/toast-service";
import { useTranslation } from "react-i18next";

const LeadServiceActionsSection = ({
  editMode,
  actionsFor,
  isBtnDisabled,
  handleEditMode,
  hideDelete = false
}) => {
  const id = useParams().id;
  const { t } = useTranslation();
  const { funnel, setFunnel } = useOutletContext();
  const {
    FOR_PRICE,
    FOR_FIXED_PRICE,
    FOR_COMPANY,
    FOR_PRODUCT,
    FOR_UNIT,
    FOR_BUDGET_QUERY,
    FOR_PRICES_PER_AREA,
    FOR_QUESTIONS
  } = LEAD_SERVICE_PLUS_EDIT_MODES;
  const { reset, watch, getValues, handleSubmit, setValue } = useFormContext();
  const [initialPrice, setInitialPrice] = useState(0);
  const [initialFixedPrice, setFixedInitialPrice] = useState(0);
  const [initialUnit, setInitialUnit] = useState("");
  const [initialMinBudgetQuery, setInitialMinBudgetQuery] = useState(0);
  const [initialMaxBudgetQuery, setInitialMaxBudgetQuery] = useState(0);
  const [initialPriceBelowMinBudget, setInitialPriceBelowMinBudget] =
    useState(0);
  const [initialPriceBetweenBudget, setInitialPriceBetweenBudget] = useState(0);
  const [initialPriceAboveMaxBudget, setInitialPriceAboveMaxBudget] =
    useState(0);
  const [initialCompanyName, setInitialCompanyName] = useState("");
  const [initialProductName, setInitialProductName] = useState("");
  const [initialQuestion, setInitialQuestion] = useState([]);

  const [isSubmittingCompany, setIsSubmittingCompany] = useState({
    forAdd: false,
    forReset: false
  });
  const [isSubmittingProduct, setIsSubmittingProduct] = useState({
    forAdd: false,
    forReset: false
  });
  const [isSubmittingPrice, setIsSubmittingPrice] = useState(false);
  const [isSubmittingFixedPrice, setIsSubmittingFixedPrice] = useState(false);
  const [isSubmittingBudgetQueryLimits, setIsSubmittingBudgetQueryLimits] =
    useState(false);
  const [isSubmittingPricesPerArea, setIsSubmittingPricesPerArea] =
    useState(false);
  const [isSubmittingUnit, setIsSubmittingUnit] = useState({
    forAdd: false,
    forReset: false
  });
  const [isSubmittingQuestion, setIsSubmittingQuestion] = useState(false);

  const { showConfirmationDialogBox } = useDialog();

  const afterSubmit = ({ msg, values, forReset, field: { prop, val } }) => {
    showSuccess(msg);
    setFunnel({
      ...funnel,
      [prop]: val
    });
    reset(
      {
        ...values,
        [prop]: val
      },
      { keepDirtyValues: !forReset }
    );
    handleEditMode({ ...editMode, [actionsFor]: false });
  };
  const onError = (error) => {
    showError(error.response?.data?.message || t("leadServiceError"));
  };
  const onSubmitCompanyName = async (values, { forReset }) => {
    try {
      !forReset
        ? setIsSubmittingCompany({ ...isSubmittingCompany, forAdd: true })
        : setIsSubmittingCompany({ ...isSubmittingCompany, forReset: true });
      const companyName = forReset ? "" : values.companyNameForLeadAgent;
      const response = await updateCompanyNameForAgent(id, {
        companyNameForLeadAgent: companyName
      });
      afterSubmit({
        values,
        forReset,
        msg: response.data.message,
        field: { prop: "companyNameForLeadAgent", val: companyName }
      });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingCompany({ forAdd: false, forReset: false });
    }
  };
  const onSubmitProductName = async (values, { forReset }) => {
    try {
      !forReset
        ? setIsSubmittingProduct({ ...isSubmittingProduct, forAdd: true })
        : setIsSubmittingProduct({ ...isSubmittingProduct, forReset: true });
      const productName = forReset ? "" : values.leadServiceProductName;
      const response = await updateProductNameForAgent(id, {
        leadServiceProductName: productName
      });
      afterSubmit({
        values,
        forReset,
        msg: response.data.message,
        field: { prop: "leadServiceProductName", val: productName }
      });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingProduct({ forAdd: false, forReset: false });
    }
  };
  const onSubmitPrice = async (values) => {
    try {
      setIsSubmittingPrice(true);
      const { pricePerInvoiceUnitForLeadAgent, leadServicePlusPriceType } =
        values;
      const response = await updatePricePerInvoiceUnitForAgent(id, {
        leadServicePlusPriceType,
        pricePerInvoiceUnitForLeadAgent
      });
      showSuccess(response.data.message);
      handleEditMode({ ...editMode, [actionsFor]: false });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingPrice(false);
    }
  };
  const onSubmitFixedPrice = async (values) => {
    try {
      setIsSubmittingFixedPrice(true);
      const { fixedPricePerInvoiceUnitForLeadAgent, leadServicePlusPriceType } =
        values;
      const response = await updateFixedPricePerInvoiceUnitForAgent(id, {
        leadServicePlusPriceType,
        fixedPricePerInvoiceUnitForLeadAgent
      });
      showSuccess(response.data.message);
      handleEditMode({ ...editMode, [actionsFor]: false });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingFixedPrice(false);
    }
  };
  const handleUpdatePriceTypeForAgent = async ({ value }) => {
    try {
      await updateLeadServicePlusPriceTypeForAgent(id, {
        leadServicePlusPriceType: value,
        resetAreaQueries: true
      });
      const areaQueriesData = {
        leadServicePlusPriceType: value,
        minBudgetQuery: 0,
        maxBudgetQuery: 0,
        pricePerAreaBelowMinBudget: 0,
        pricePerAreaBetweenBudget: 0,
        pricePerAreaAboveMaxBudget: 0
      };
      setFunnel({ ...funnel, ...areaQueriesData });
      reset({ ...getValues(), ...areaQueriesData });
    } catch (error) {
      onError(error);
    }
  };
  const handleUpdateAccountingUnit = async ({ values, forReset }) => {
    try {
      !forReset
        ? setIsSubmittingUnit({ ...isSubmittingUnit, forAdd: true })
        : setIsSubmittingUnit({ ...isSubmittingUnit, forReset: true });
      const unit = forReset ? "" : values.nameOfAccountingUnitForLeadAgent;
      const response = await updateNameOfAccountingUniForAgent(id, {
        nameOfAccountingUnitForLeadAgent: unit
      });
      afterSubmit({
        values,
        forReset,
        msg: response.data.message,
        field: {
          prop: "nameOfAccountingUnitForLeadAgent",
          val: unit
        }
      });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingUnit({ forAdd: false, forReset: false });
    }
  };
  const onSubmitUnit = (values, { forReset }) => {
    if (
      (forReset || !values.nameOfAccountingUnitForLeadAgent) &&
      watch("leadServicePlusPriceType") === PRICE_TYPE.AREA_QUERY
    ) {
      // show dialog for confirmation if deleting, resetting name of accounting unit and price type is AREA_QUERY
      showConfirmationDialogBox({
        title: "Warning",
        dialogType: "warning",
        dialogMessage: renderConfirmationMsg(),
        responseAction: (action) => {
          if (action) {
            handleUpdateAccountingUnit({ values, forReset });
            handleUpdatePriceTypeForAgent({ value: PRICE_TYPE.DYNAMIC });
          }
        }
      });
      return;
    }
    handleUpdateAccountingUnit({ values, forReset });
  };
  const renderConfirmationMsg = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t(
          "funnelIntegration.leadServicePlus.removeUnitNameConfirmationMsg"
        )
      }}
    ></div>
  );
  const onSubmitBudgetQueryLimits = async (values) => {
    try {
      setIsSubmittingBudgetQueryLimits(true);
      const { leadServicePlusPriceType, minBudgetQuery, maxBudgetQuery } =
        values;
      const response = await updateBudgetQueriesForAgent(id, {
        leadServicePlusPriceType,
        minBudgetQuery,
        maxBudgetQuery
      });
      showSuccess(response.data.message);
      handleEditMode({ ...editMode, [actionsFor]: false });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingBudgetQueryLimits(false);
    }
  };
  const onSubmitPricesPerArea = async (values) => {
    try {
      setIsSubmittingPricesPerArea(true);
      const {
        leadServicePlusPriceType,
        pricePerAreaBelowMinBudget,
        pricePerAreaBetweenBudget,
        pricePerAreaAboveMaxBudget
      } = values;
      const response = await updatePricesPerAreaForAgent(id, {
        leadServicePlusPriceType,
        pricePerAreaBelowMinBudget,
        pricePerAreaBetweenBudget,
        pricePerAreaAboveMaxBudget
      });
      showSuccess(response.data.message);
      handleEditMode({ ...editMode, [actionsFor]: false });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingBudgetQueryLimits(false);
    }
  };

  const onSubmitQuestion = async (values, { forReset }) => {
    try {
      setIsSubmittingQuestion(true);
      if (forReset) {
        setValue("leadServiceQuestions", []);
        setValue("question1", null);
        setValue("question2", null);
        setValue("question3", null);
      }
      const question1 = watch("question1");
      const question2 = watch("question2");
      const question3 = watch("question3");

      const leadServiceQuestions = [question1, question2, question3].filter(
        (q) => q !== null && q !== undefined
      );
      // console.log("leadServiceQuestions", leadServiceQuestions);

      const response = await updateLeadServiceQuestionForAgent(id, {
        leadServiceQuestions: leadServiceQuestions
      });
      afterSubmit({
        values,
        forReset,
        msg: response.data.message || "Questions updated successfully",
        field: { prop: "leadServiceQuestions", val: leadServiceQuestions }
      });
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmittingQuestion(false);
    }
  };

  const toggleEditMode = () =>
    handleEditMode({ ...editMode, [actionsFor]: !editMode[actionsFor] });

  const handleEditClick = () => {
    if (actionsFor === FOR_COMPANY) {
      setInitialCompanyName(watch("companyNameForLeadAgent"));
    } else if (actionsFor === FOR_PRODUCT) {
      setInitialProductName(watch("leadServiceProductName"));
    } else if (actionsFor === FOR_PRICE) {
      setInitialPrice(watch("pricePerInvoiceUnitForLeadAgent"));
    } else if (actionsFor === FOR_FIXED_PRICE) {
      setFixedInitialPrice(watch("fixedPricePerInvoiceUnitForLeadAgent"));
    } else if (actionsFor === FOR_UNIT) {
      setInitialUnit(watch("nameOfAccountingUnitForLeadAgent"));
    } else if (actionsFor === FOR_BUDGET_QUERY) {
      setInitialMinBudgetQuery(watch("minBudgetQuery"));
      setInitialMaxBudgetQuery(watch("maxBudgetQuery"));
    } else if (actionsFor === FOR_PRICES_PER_AREA) {
      setInitialPriceBelowMinBudget("pricePerAreaBelowMinBudget");
      setInitialPriceBetweenBudget("pricePerAreaBetweenBudget");
      setInitialPriceAboveMaxBudget("pricePerAreaAboveMaxBudget");
    } else if (actionsFor === FOR_QUESTIONS) {
      setInitialQuestion(watch("leadServiceQuestions"));
    }
    toggleEditMode();
  };
  const handleCancelEdit = () => {
    let resetValues = {
      ...getValues()
    };
    if (actionsFor === FOR_COMPANY) {
      resetValues.companyNameForLeadAgent = initialCompanyName;
    } else if (actionsFor === FOR_PRODUCT) {
      resetValues.leadServiceProductName = initialProductName;
    } else if (actionsFor === FOR_PRICE) {
      resetValues.pricePerInvoiceUnitForLeadAgent = initialPrice;
    } else if (actionsFor === FOR_FIXED_PRICE) {
      resetValues.fixedPricePerInvoiceUnitForLeadAgent = initialFixedPrice;
    } else if (actionsFor === FOR_UNIT) {
      resetValues.nameOfAccountingUnitForLeadAgent = initialUnit;
    } else if (actionsFor === FOR_BUDGET_QUERY) {
      resetValues.minBudgetQuery = initialMinBudgetQuery;
      resetValues.maxBudgetQuery = initialMaxBudgetQuery;
    } else if (actionsFor === FOR_PRICES_PER_AREA) {
      resetValues.pricePerAreaBelowMinBudget = initialPriceBelowMinBudget;
      resetValues.pricePerAreaBetweenBudget = initialPriceBetweenBudget;
      resetValues.pricePerAreaAboveMaxBudget = initialPriceAboveMaxBudget;
    } else if (actionsFor === FOR_QUESTIONS) {
      resetValues.leadServiceQuestions = initialQuestion;
      resetValues.question1 = initialQuestion[0];
      resetValues.question2 = initialQuestion[1];
      resetValues.question3 = initialQuestion[2];
    }
    reset(resetValues);
    toggleEditMode();
  };
  const handleSubmitClick = ({ forReset }) => {
    if (actionsFor === FOR_COMPANY) {
      handleSubmit(onSubmitCompanyName)({
        forReset
      });
    } else if (actionsFor === FOR_PRODUCT) {
      handleSubmit(onSubmitProductName)({
        forReset
      });
    } else if (actionsFor === FOR_PRICE) {
      handleSubmit(onSubmitPrice)();
    } else if (actionsFor === FOR_FIXED_PRICE) {
      handleSubmit(onSubmitFixedPrice)();
    } else if (actionsFor === FOR_UNIT) {
      handleSubmit(onSubmitUnit)({
        forReset
      });
    } else if (actionsFor === FOR_BUDGET_QUERY) {
      handleSubmit(onSubmitBudgetQueryLimits)();
    } else if (actionsFor === FOR_PRICES_PER_AREA) {
      handleSubmit(onSubmitPricesPerArea)();
    } else if (actionsFor === FOR_QUESTIONS) {
      handleSubmit(onSubmitQuestion)({ forReset });
    }
  };
  const checkIsSubmitting = ({ forReset }) => {
    if (actionsFor === FOR_COMPANY) {
      return forReset
        ? isSubmittingCompany.forReset
        : isSubmittingCompany.forAdd;
    } else if (actionsFor === FOR_PRODUCT) {
      return forReset
        ? isSubmittingProduct.forReset
        : isSubmittingProduct.forAdd;
    } else if (actionsFor === FOR_PRICE) {
      return isSubmittingPrice;
    } else if (actionsFor === FOR_FIXED_PRICE) {
      return isSubmittingFixedPrice;
    } else if (actionsFor === FOR_UNIT) {
      return forReset ? isSubmittingUnit.forReset : isSubmittingUnit.forAdd;
    } else if (actionsFor === FOR_BUDGET_QUERY) {
      return isSubmittingBudgetQueryLimits;
    } else if (actionsFor === FOR_PRICES_PER_AREA) {
      return isSubmittingPricesPerArea;
    } else if (actionsFor === FOR_QUESTIONS) {
      return isSubmittingQuestion;
    } else {
      return false;
    }
  };
  const checkIsDeleteDisabled = () => {
    if (
      (actionsFor === FOR_COMPANY && funnel?.companyNameForLeadAgent) ||
      (actionsFor === FOR_PRODUCT && funnel?.leadServiceProductName) ||
      (actionsFor === FOR_PRICE && funnel?.pricePerInvoiceUnitForLeadAgent) ||
      (actionsFor === FOR_FIXED_PRICE &&
        funnel?.fixedPricePerInvoiceUnitForLeadAgent) ||
      (actionsFor === FOR_UNIT && funnel?.nameOfAccountingUnitForLeadAgent) ||
      (actionsFor === FOR_BUDGET_QUERY &&
        funnel?.minBudgetQuery &&
        funnel?.maxBudgetQuery) ||
      (actionsFor === FOR_PRICES_PER_AREA &&
        funnel?.pricePerAreaBelowMinBudget &&
        funnel?.pricePerAreaBetweenBudget &&
        funnel?.pricePerAreaAboveMaxBudget) ||
      (actionsFor === FOR_QUESTIONS && funnel?.leadServiceQuestions?.length > 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Col>
      <div className="d-flex align-items-center">
        <Collapse dimension="width" in={editMode[actionsFor]}>
          <div>
            <div className="d-flex align-items-center">
              <LeadServiceActionButton
                faIcon={faCheck}
                isDisabled={isBtnDisabled}
                btnVariant="outline-success"
                tooltipMsg={t("common.save")}
                isLoading={checkIsSubmitting({ forReset: false })}
                clickHandler={() => handleSubmitClick({ forReset: false })}
              />
              <LeadServiceActionButton
                btnStyles="ms-2"
                faIcon={faXmark}
                btnVariant="outline-danger"
                tooltipMsg={t("common.cancel")}
                clickHandler={() => handleCancelEdit(actionsFor)}
              />
            </div>
          </div>
        </Collapse>
        <Collapse dimension="width" in={!editMode[actionsFor]}>
          <div>
            <div className="d-flex align-items-center">
              <LeadServiceActionButton
                faIcon={faEdit}
                btnVariant="outline-primary"
                tooltipMsg={t("common.edit")}
                clickHandler={() => handleEditClick(actionsFor)}
              />
              {!hideDelete && (
                <LeadServiceActionButton
                  btnStyles="ms-2"
                  faIcon={faTrashAlt}
                  tooltipMsg={t("delete")}
                  btnVariant="outline-danger"
                  isLoading={checkIsSubmitting({ forReset: true })}
                  clickHandler={() => handleSubmitClick({ forReset: true })}
                  isDisabled={checkIsDeleteDisabled()}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </Col>
  );
};
export default LeadServiceActionsSection;
