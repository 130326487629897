import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ModalHead } from "../../common-styling";
import SpinnerButton from "../../spinner-button/spinner-button";
import { useTranslation } from "react-i18next";
import ImagePreviewModal from "../../funnel-form/preview-image-modal";
import { THUMBNAIL_TYPE } from "../../strings";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../../reusable-form-fields/text-field";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const AddSectionDialog = ({
  show,
  toggleDialog,
  data,
  addSection,
  selectedTemplate
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hoverActive, setHoverActive] = useState(-1);
  const [selectedSection, setSelectedSection] = useState(
    data[0].component ?? "hero"
  );
  const [showPreviewTemplate, setShowPreviewTemplate] = useState(-1);

  const addSectionSchema = Yup.object().shape({
    name: Yup.string().required("Required")
  });

  const methods = useForm({
    resolver: yupResolver(addSectionSchema)
    // defaultValues: initialValues
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const parseResourceSrc = (type) =>
    type
      ? `${process.env.REACT_APP_ASSET_CDN_URL}/customer-website/${selectedTemplate}/${type}.jpg/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`
      : "";
  const parsePreviewResourceSrc = (type) =>
    type
      ? `${process.env.REACT_APP_ASSET_CDN_URL}/customer-website/${selectedTemplate}/${type}.jpg`
      : "";

  const onSubmit = (formValues) => {
    setIsSubmitting(true);
    addSection({
      name: formValues.name,
      component: selectedSection
    });
    setIsSubmitting(false);
    toggleDialog();
  };

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static" centered>
      <ModalHead className="bg-primary text-white">
        <Modal.Title>{t("customerWebsite.selectSection")}</Modal.Title>
      </ModalHead>
      <FormProvider {...methods} id="section-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <TextField
                  label={t("customerWebsite.name")}
                  placeholder={t("customerWebsite.name")}
                  type="text"
                  name="name"
                  errors={errors?.name}
                  register={register}
                  noSpacing
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p>{t("customerWebsite.section")}</p>
              </Col>
              <Col>
                <Swiper
                  modules={[Navigation, Pagination, Mousewheel]}
                  spaceBetween={30}
                  slidesPerView={2}
                  mousewheel={true}
                  pagination={{ clickable: true }}
                  className="pb-4"
                >
                  {data.length > 0 &&
                    data
                      .filter(
                        (section) => section.templateType === selectedTemplate
                      )
                      .map((section, index) => {
                        return (
                          <SwiperSlide key={section.component}>
                            <div style={{ width: "100%" }}>
                              <div
                                className="position-relative rounded cursor-pointer"
                                style={{
                                  height: "100px",
                                  border: `5px solid ${
                                    selectedSection === section.component
                                      ? "blue"
                                      : "black"
                                  }`
                                }}
                                onMouseEnter={() => setHoverActive(index)}
                                onClick={() =>
                                  setSelectedSection(section.component)
                                }
                                onMouseLeave={() => setHoverActive(-1)}
                              >
                                <img
                                  alt={section.component}
                                  className="rounded cursor-pointer"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                  }}
                                  src={parseResourceSrc(section.component)}
                                />
                                {hoverActive === index && (
                                  <div
                                    className="cursor-pointer position-absolute top-0 left-0 d-flex justify-content-center align-items-center"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      background: "rgba(0,0,0,0.3)"
                                    }}
                                  >
                                    <Button
                                      variant="outline-light"
                                      size="sm"
                                      onClick={() =>
                                        setShowPreviewTemplate(index)
                                      }
                                    >
                                      {t("customerWebsite.preview")}
                                    </Button>

                                    {showPreviewTemplate === index && (
                                      <ImagePreviewModal
                                        source={parsePreviewResourceSrc(
                                          section.component
                                        )}
                                        toggleModal={() =>
                                          setShowPreviewTemplate(-1)
                                        }
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                              <h5 className="text-center mt-1">
                                {t(
                                  `customerWebsite.sections.${section.component}`
                                )}
                              </h5>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                </Swiper>

                <div
                  className="d-flex flex-wrap justify-content-center"
                  style={{ gap: "20px" }}
                ></div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex flex-row-reverse">
              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={() => {
                  toggleDialog();
                }}
              >
                {t("common.cancel")}
              </Button>
              {isSubmitting ? (
                <SpinnerButton
                  btnText={t("customerWebsite.replaceWarning.saving")}
                  btnStyle="btn-outline-success"
                />
              ) : (
                <Button type="submit" variant="outline-success">
                  {t("customerWebsite.add")}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddSectionDialog;
