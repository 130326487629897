import moment from "moment";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Modal, Button, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  DATE_TIME_COMPLETE_FORMAT,
  DATE_TIME_FORMAT,
  ACTIVITY_TYPES,
  ACTIVITY_RESULT_TYPES
} from "../strings";
import { ContactLaterDialogSchema } from "../../services/validation-schema";
import { useTranslation } from "react-i18next";
import { showError } from "../../services/toast-service";

const AppointmentPickerDialog = ({
  show,
  onHide,
  activityHandler,
  allowClose = true,
  comment,
  responses
}) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const errorBorderClasses = "border border-2 border-danger";
  const initialValues = { date: "" };
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(ContactLaterDialogSchema)
  });
  const {
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = methods;

  const submitAppointment = async (values) => {
    try {
      if (isUpdating) return;
      setIsUpdating(true);
      const resultType = allowClose
        ? ACTIVITY_RESULT_TYPES.APPOINTMENT_POSTPONED
        : ACTIVITY_RESULT_TYPES.QUALIFIED;
      const note = allowClose
        ? `${t("appointmentNote.postpone")} ${moment(values.date).format(
            DATE_TIME_FORMAT
          )}`
        : `${t("appointmentNote.book")} ${moment(values.date).format(
            DATE_TIME_FORMAT
          )}. ${comment}`;
      await activityHandler({
        type: ACTIVITY_TYPES.CALL,
        result: resultType,
        comment: note,
        responses: responses,
        appointmentOn: moment(values.date).format(DATE_TIME_COMPLETE_FORMAT)
      });
      handleCloseDialog();
    } catch (error) {
      console.error("Error in creating appointment activity", error);
      showError(error.response?.data?.message || t("common.errorOccurred"));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCloseDialog = () => {
    reset(initialValues);
    onHide();
  };

  return (
    <Modal
      show={show}
      centered
      size="md"
      backdrop="static"
      className="text-center"
      onEscapeKeyDown={(e) => e.preventDefault()}
    >
      <Modal.Header className="justify-content-center">
        <h5 className="mb-0">{t("leadAgent.appointmentDialogHeading")}</h5>
      </Modal.Header>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(submitAppointment)}>
          <div className="text-center my-4">
            <ReactDatePicker
              selected={methods.getValues("date")}
              minDate={moment().toDate()}
              dateFormat="MMMM d, yyyy h:mm aa"
              onChange={(selectedDate) => {
                setValue("date", selectedDate);
                clearErrors("date");
              }}
              calendarClassName={errors?.date ? errorBorderClasses : ""}
              showTimeInput // Enables time selection
              timeInputLabel="Time:"
              inline
            />
            {errors?.date && (
              <div className="text-danger fw-bold">
                {errors?.date?.message}*
              </div>
            )}
          </div>
          <Modal.Footer>
            {allowClose && (
              <Button
                disabled={isUpdating}
                onClick={handleCloseDialog}
                variant="outline-secondary"
              >
                {t("common.close")}
              </Button>
            )}
            <Button disabled={isUpdating} type="submit" variant="success">
              {t("common.save")}
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AppointmentPickerDialog;
