import { useState } from "react";
import ReactQuill from "react-quill";
import {
  Modal,
  ModalTitle,
  Button,
  FormControl,
  FormGroup,
  Dropdown,
  DropdownButton,
  Row,
  Col
} from "react-bootstrap";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

const Title = styled(ModalTitle)`
  text-transform: capitalize;
`;

const EditorModal = ({
  title,
  onSave,
  onCancel,
  quillValue = "",
  subjectValue = "",
  className
}) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState(null);
  const [quillContent, setQuillContent] = useState(quillValue);
  const [subject, setSubject] = useState(subjectValue);

  const placeholders = [
    { key: "leadName", label: t("emailEdit.leadName") },
    { key: "leadEmail", label: t("emailEdit.leadEmail") },
    { key: "appointmentDate", label: t("emailEdit.appointmentDate") },
    { key: "appointmentTime", label: t("emailEdit.appointmentTime") }
  ];

  const handleQuillChange = (content) => {
    const quillString =
      content === "<p><br></p>" || content === "<p> </p>" ? "" : content;
    setQuillContent(quillString);
  };

  const handleSave = () => {
    onSave({
      subject,
      content: quillContent
    });
  };

  const insertPlaceholder = (placeholder) => {
    if (editor) {
      const cursorPosition = editor.getSelection()?.index || 0;
      editor.clipboard.dangerouslyPasteHTML(
        cursorPosition,
        `{{${placeholder}}}`
      );
      editor.setSelection(cursorPosition + placeholder.length + 4);
      setQuillContent(editor.root.innerHTML);
    }
  };

  return (
    <Modal size="lg" show={true} centered>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={8}>
            <FormGroup>
              <FormControl
                type="text"
                placeholder={t("emailEdit.subjectPlaceholder")}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="d-flex justify-content-end">
            <DropdownButton
              title={t("emailEdit.placeholder")}
              variant="outline-secondary"
            >
              {placeholders.map((item) => (
                <Dropdown.Item
                  key={item.key}
                  onClick={() => insertPlaceholder(item.key)}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>

        {/* Quill Editor */}
        <ReactQuill
          className={className}
          value={quillContent}
          theme="snow"
          onChange={handleQuillChange}
          ref={(el) => el && setEditor(el.getEditor())}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onCancel} variant="secondary">
          {t("common.cancel")}
        </Button>
        <Button variant="success" onClick={handleSave}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditorModal;
