import React from "react";
import { useParams } from "react-router";
import { CustomCard } from "../common-styling";
import FunnelsTable from "../funnels-table/funnels-table";

const CustomerFunnels = () => {
  const params = useParams();
  return (
    <div className="my-4">
      <CustomCard className="px-4">
        <FunnelsTable customerId={params.id} limit="20" asSubComponent />
      </CustomCard>
    </div>
  );
};
export default CustomerFunnels;
