import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomCard, FieldSet, Legend } from "../../common-styling";
import { Col, Form, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import SelectField from "../../reusable-form-fields/select-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";
import EditorModalController from "../../funnel-form/editor-modal-controller";

const FooterSection = ({
  logoTypes,
  parseResourceSrc,
  parsePreviewResourceSrc,
  sections,
  customerData,
  isFormChanged
}) => {
  console.log("customer data in foooterr:", customerData?.email);
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    register,
    getValues
  } = useFormContext();
  console.log("from all get values:", getValues());

  const FooterBGColorDisplay = watch("footer.bgColorDisplay");

  const ContactCardBtnBGColorDisplay = watch(
    "footer.contact_card.btn.bgColorDisplay"
  );
  const footerLogoType = watch("footer.logo.type");

  const footerLogoImage = watch("footer.logo.image");
  const footerContactCardImage = watch("footer.contact_card.image");

  const footerBgColorPickerRef = useRef();
  const footerLogoColorPickerRef = useRef();
  const footerTaglineColorPickerRef = useRef();
  const facebookColorPickerRef = useRef();
  const instaColorPickerRef = useRef();
  const youtubeColorPickerRef = useRef();
  const footerQuickLinksColorPickerRef = useRef();
  const footerPolicyLinksColorPickerRef = useRef();
  const contactCardNameColorPickerRef = useRef();
  const contactCardTitleColorPickerRef = useRef();
  const contactCardCATButtonColorPickerRef = useRef();
  const contactCardCATButtonBgColorPickerRef = useRef();

  const maxContentLength = 50000;

  return (
    <CustomCard className="mb-3 pt-1">
      <FieldSet>
        <Row>
          <Col md={12}>
            <Legend>
              <h4 className="mb-0 fw-bold">{t("customerWebsite.footer")}</h4>
            </Legend>
          </Col>
          <Col md={3} className="mt-1">
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.BGColor")}
              fieldName="footer.bgColor"
              fieldError={errors?.footer?.bgColor}
              fieldRef={footerBgColorPickerRef}
              fieldVal={watch("footer.bgColor")}
              displayFieldWatcher={FooterBGColorDisplay}
              fieldWatcherName="footer.bgColorDisplay"
              isDisabled={Boolean(!FooterBGColorDisplay)}
              setDisplay
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} md={footerLogoType?._id === logoTypes[0]._id ? 4 : 6}>
            <Form.Group className="mb-4" controlId="land">
              <Form.Label>{t("customerWebsite.logo.logoType")}</Form.Label>
              <SelectField
                control={control}
                name="footer.logo.type"
                options={logoTypes}
                getValues={getValues}
                errors={errors?.footer?.logo?.type}
              />
            </Form.Group>
          </Col>

          {footerLogoType?._id === logoTypes[0]._id ? (
            <>
              <Col sm={12} md={4}>
                <TextField
                  label={t("customerWebsite.logo.logoText")}
                  placeholder={t("customerWebsite.logo.logoText")}
                  type="text"
                  name="footer.logo.text"
                  errors={errors?.footer?.logo?.text}
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={4}>
                <ColorPickerWithTextField
                  hideButton
                  label={t("customerWebsite.logo.logoColor")}
                  placeholder="#000000"
                  fieldName="footer.logo.color"
                  fieldError={errors?.footer?.logo?.color}
                  fieldRef={footerLogoColorPickerRef}
                  fieldVal={watch("footer.logo.color")}
                  defaultColorValue="#000000"
                  isInGroup
                  noSpacing
                  hideErrMsg
                  isFormChanged={isFormChanged}
                />
              </Col>
            </>
          ) : footerLogoType?._id === logoTypes[1]._id ? (
            <Col xs={12} md={6}>
              <FileInput
                label={t("customerWebsite.logo.logoImage")}
                name="footer.logo.image"
                // resourceId={resourceId}
                parentFolderName={"customer"}
                url={parseResourceSrc(footerLogoImage)}
                urlForPreview={parsePreviewResourceSrc(footerLogoImage)}
                asset={footerLogoImage?.asset}
                isFormChanged={isFormChanged}
                // selectedCategory={getValues("category")}
                // tagName="backgroundimage"
              />
            </Col>
          ) : null}
        </Row>

        <Row>
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.taglineText")}
              placeholder={t("customerWebsite.taglineText")}
              type="text"
              name="footer.tag_line.text"
              errors={errors?.footer?.tag_line?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.taglineColor")}
              placeholder="#000000"
              fieldName="footer.tag_line.color"
              fieldError={errors?.footer?.tag_line?.color}
              fieldRef={footerTaglineColorPickerRef}
              fieldVal={watch("footer.tag_line.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={12} className="mb-3">
            <Legend>
              <h6 className="mb-0 fw-bold mb-1">
                {t("customerWebsite.socialLinks")}
              </h6>
            </Legend>
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={`${t("customerWebsite.socialLinks")} ${t(
                "customerWebsite.text"
              )}`}
              placeholder={`${t("customerWebsite.socialLinks")} ${t(
                "customerWebsite.text"
              )}`}
              type="text"
              name="footer.footer_links.social_link.text"
              errors={errors?.footer?.footer_links?.social_link?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col md={3}>
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.linksTextColor")}
              placeholder="#000000"
              fieldName="footer.footer_links.social_link.color"
              fieldError={errors?.footer?.footer_links?.social_link?.color}
              fieldRef={footerQuickLinksColorPickerRef}
              fieldVal={watch("footer.footer_links.social_link.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.fbLink")}
              placeholder={t("customerWebsite.fbLink")}
              type="text"
              errors={errors?.footer?.facebook?.link}
              name="footer.facebook.link"
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.fbColor")}
              placeholder="#000000"
              fieldName="footer.facebook.color"
              fieldError={errors?.footer?.facebook?.color}
              fieldRef={facebookColorPickerRef}
              fieldVal={watch("footer.facebook.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.instaLink")}
              placeholder={t("customerWebsite.instaLink")}
              type="text"
              name="footer.instagram.link"
              errors={errors?.footer?.instagram?.link}
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.instaColor")}
              placeholder="#000000"
              fieldName="footer.instagram.color"
              fieldError={errors?.footer?.instagram?.color}
              fieldRef={instaColorPickerRef}
              fieldVal={watch("footer.instagram.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.ytLink")}
              placeholder={t("customerWebsite.ytLink")}
              type="text"
              name="footer.youtube.link"
              errors={errors?.footer?.youtube?.link}
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.ytColor")}
              placeholder="#000000"
              fieldName="footer.youtube.color"
              fieldError={errors?.footer?.youtube?.color}
              fieldRef={youtubeColorPickerRef}
              fieldVal={watch("footer.youtube.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12} className="mb-3">
            <Legend>
              <h6 className="mb-0 fw-bold mb-1">
                {t("customerWebsite.quickLinks")}
              </h6>
            </Legend>
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={`${t("customerWebsite.quickLinks")} ${t(
                "customerWebsite.text"
              )}`}
              placeholder={`${t("customerWebsite.quickLinks")} ${t(
                "customerWebsite.text"
              )}`}
              type="text"
              name="footer.footer_links.quick_links.text"
              errors={errors?.footer?.footer_links?.quick_links?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col md={3}>
            <div>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.linksTextColor")}
                placeholder="#000000"
                fieldName="footer.footer_links.quick_links.color"
                fieldError={errors?.footer?.footer_links?.quick_links?.color}
                fieldRef={footerQuickLinksColorPickerRef}
                fieldVal={watch("footer.footer_links.quick_links.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </div>
          </Col>
          <Col md={12}>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.quick_links.links[0].text"
                  errors={
                    errors?.footer?.footer_links?.quick_links?.links &&
                    errors?.footer?.footer_links?.quick_links?.links[0]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="footer.footer_links.quick_links.links[0].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.footer?.footer_links?.quick_links?.links &&
                      errors?.footer?.footer_links?.quick_links?.links[0]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.quick_links.links[1].text"
                  errors={
                    errors?.footer?.footer_links?.quick_links?.links &&
                    errors?.footer?.footer_links?.quick_links?.links[1]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="footer.footer_links.quick_links.links[1].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.footer?.footer_links?.quick_links?.links &&
                      errors?.footer?.footer_links?.quick_links?.links[1]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.quick_links.links[2].text"
                  errors={
                    errors?.footer?.footer_links?.quick_links?.links &&
                    errors?.footer?.footer_links?.quick_links?.links[2]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="footer.footer_links.quick_links.links[2].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.footer?.footer_links?.quick_links?.links &&
                      errors?.footer?.footer_links?.quick_links?.links[2]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.quick_links.links[3].text"
                  errors={
                    errors?.footer?.footer_links?.quick_links?.links &&
                    errors?.footer?.footer_links?.quick_links?.links[3]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="footer.footer_links.quick_links.links[3].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.footer?.footer_links?.quick_links?.links &&
                      errors?.footer?.footer_links?.quick_links?.links[3]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12} className="mb-3">
            <Legend>
              <h6 className="mb-0 fw-bold mb-1">
                {t("customerWebsite.policyLinks")}
              </h6>
            </Legend>
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={`${t("customerWebsite.policyLinks")} ${t(
                "customerWebsite.text"
              )}`}
              placeholder={`${t("customerWebsite.policyLinks")} ${t(
                "customerWebsite.text"
              )}`}
              type="text"
              name="footer.footer_links.pp_links.text"
              errors={errors?.footer?.footer_links?.pp_links?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col md={3}>
            <div>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.linksTextColor")}
                placeholder="#000000"
                fieldName="footer.footer_links.pp_links.color"
                fieldError={errors?.footer?.footer_links?.pp_links?.color}
                fieldRef={footerPolicyLinksColorPickerRef}
                fieldVal={watch("footer.footer_links.pp_links.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </div>
          </Col>
          <Col md={12}>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.privacyPolicy")} ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.privacyPolicy")} ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.pp_links.links[0].text"
                  errors={
                    errors?.footer?.footer_links?.pp_links?.links &&
                    errors?.footer?.footer_links?.pp_links?.links[0]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.privacyPolicy")} ${t(
                    "customerWebsite.link"
                  )}`}
                  placeholder={`${t("customerWebsite.privacyPolicy")} ${t(
                    "customerWebsite.link"
                  )}`}
                  type="text"
                  name="footer.footer_links.pp_links.links[0].link"
                  errors={
                    errors?.footer?.footer_links?.pp_links?.links &&
                    errors?.footer?.footer_links?.pp_links?.links[0]?.link
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col md={12}>
                <EditorModalController
                  title={`${t("customerWebsite.privacyPolicy")}`}
                  context="footer.footer_links.pp_links.links[0].content"
                  maxLength={maxContentLength}
                />
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.legalNotice")} ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.legalNotice")} ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="footer.footer_links.pp_links.links[1].text"
                  errors={
                    errors?.footer?.footer_links?.pp_links?.links &&
                    errors?.footer?.footer_links?.pp_links?.links[1]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.legalNotice")} ${t(
                    "customerWebsite.link"
                  )}`}
                  placeholder={`${t("customerWebsite.legalNotice")} ${t(
                    "customerWebsite.link"
                  )}`}
                  type="text"
                  name="footer.footer_links.pp_links.links[1].link"
                  errors={
                    errors?.footer?.footer_links?.pp_links?.links &&
                    errors?.footer?.footer_links?.pp_links?.links[1]?.link
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col md={12}>
                <EditorModalController
                  title={`${t("customerWebsite.legalNotice")}`}
                  context="footer.footer_links.pp_links.links[1].content"
                  maxLength={maxContentLength}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Legend>
            <h6 className="mb-3 fw-bold">{t("customerWebsite.contact")}</h6>
          </Legend>
          <Row>
            <Col sm={12} md={3}>
              <TextField
                label={`${t("customerWebsite.contact")} ${t(
                  "customerWebsite.text"
                )}`}
                placeholder={`${t("customerWebsite.contact")} ${t(
                  "customerWebsite.text"
                )}`}
                type="text"
                name="footer.contact_card.text"
                errors={errors?.footer?.footer_links?.pp_links?.text}
                register={register}
                noSpacing
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={3}>
              {<div>{console.log(getValues())}</div>}
              <TextField
                label={t("customerWebsite.name")}
                placeholder={t("customerWebsite.name")}
                type="text"
                name="footer.contact_card.name.text"
                errors={errors?.footer?.contact_card?.name?.text}
                register={register}
                defaultValue={customerData?.firstName}
                noSpacing
              />
            </Col>
            <Col xs={12} md={3}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.nameColor")}
                placeholder="#000000"
                fieldName="footer.contact_card.name.color"
                fieldError={errors?.footer?.contact_card?.name?.color}
                fieldRef={contactCardNameColorPickerRef}
                fieldVal={watch("footer.contact_card.name.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
            <Col sm={12} md={3}>
              <TextField
                label={t("customerWebsite.title")}
                placeholder={t("customerWebsite.title")}
                type="text"
                name="footer.contact_card.title.text"
                errors={errors?.footer?.contact_card?.title?.text}
                register={register}
                noSpacing
              />
            </Col>
            <Col xs={12} md={3}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.titleColor")}
                placeholder="#000000"
                fieldName="footer.contact_card.title.color"
                fieldError={errors?.footer?.contact_card?.title?.color}
                fieldRef={contactCardTitleColorPickerRef}
                fieldVal={watch("footer.contact_card.title.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={3}>
              <TextField
                label={t("customerWebsite.catBtnText")}
                placeholder={t("customerWebsite.catBtnText")}
                type="text"
                name="footer.contact_card.btn.text"
                errors={errors?.footer?.contact_card?.btn?.text}
                register={register}
                noSpacing
              />
            </Col>
            <Col sm={12} md={3}>
              <TextField
                label={t("customerWebsite.catBtnLink")}
                placeholder={t("customerWebsite.catBtnLink")}
                type="text"
                name="footer.contact_card.btn.link"
                errors={errors?.footer?.contact_card?.btn?.link}
                register={register}
                noSpacing
              />
            </Col>
            <Col xs={12} md={3}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.catBtnColor")}
                placeholder="#000000"
                fieldName="footer.contact_card.btn.color"
                fieldError={errors?.footer?.contact_card?.btn?.color}
                fieldRef={contactCardCATButtonColorPickerRef}
                fieldVal={watch("footer.contact_card.btn.color")}
                defaultColorValue="#ffffff"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
            <Col xs={12} md={3}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.catBtnBGColor")}
                placeholder="#000000"
                fieldName="footer.contact_card.btn.bgColor"
                fieldError={errors?.footer?.contact_card?.btn?.bgColor}
                fieldRef={contactCardCATButtonBgColorPickerRef}
                fieldVal={watch("footer.contact_card.btn.bgColor")}
                displayFieldWatcher={ContactCardBtnBGColorDisplay}
                fieldWatcherName="footer.contact_card.btn.bgColorDisplay"
                isDisabled={Boolean(!ContactCardBtnBGColorDisplay)}
                setDisplay
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={3}>
              <TextField
                label={t("customerWebsite.email")}
                placeholder={t("customerWebsite.email")}
                type="email"
                name="footer.contact_card.email.text"
                errors={errors?.footer?.contact_card?.email?.text}
                register={register}
                defaultValue={customerData?.email}
                noSpacing
              />
            </Col>
            <Col xs={12} md={3}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.emailColor")}
                placeholder="#000000"
                fieldName="footer.contact_card.email.color"
                fieldError={errors?.footer?.contact_card?.email?.color}
                fieldRef={contactCardTitleColorPickerRef}
                fieldVal={watch("footer.contact_card.email.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
            <Col xs={12} md={6}>
              <FileInput
                label={t("customerWebsite.image")}
                name="footer.contact_card.image"
                // resourceId={resourceId}
                parentFolderName={"customer"}
                url={parseResourceSrc(footerContactCardImage)}
                urlForPreview={parsePreviewResourceSrc(footerContactCardImage)}
                error={errors?.footer?.contact_card?.image}
                asset={footerContactCardImage?.asset}
                isFormChanged={isFormChanged}
                // selectedCategory={getValues("category")}
                // tagName="backgroundimage"
              />
            </Col>
          </Row>
        </Row>
      </FieldSet>
    </CustomCard>
  );
};

export default FooterSection;
