import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  const storedState = localStorage.getItem("tabStatus");
  return storedState
    ? JSON.parse(storedState)
    : {
        isAgentPanelDisabled: false,
        isAppointmentTabDisabled: false
      };
};

const saveState = (state) => {
  localStorage.setItem("tabStatus", JSON.stringify(state));
};

const initialState = loadState();

export const tabStatusSlice = createSlice({
  name: "tabStatus",
  initialState,
  reducers: {
    disableAgentPanel: (state) => {
      state.isAgentPanelDisabled = true;
      saveState(state);
    },
    enableAgentPanel: (state) => {
      state.isAgentPanelDisabled = false;
      saveState(state);
    },
    disableAppointmentTab: (state) => {
      state.isAppointmentTabDisabled = true;
      saveState(state);
    },
    enableAppointmentTab: (state) => {
      state.isAppointmentTabDisabled = false;
      saveState(state);
    }
  }
});

export const {
  disableAgentPanel,
  enableAgentPanel,
  disableAppointmentTab,
  enableAppointmentTab
} = tabStatusSlice.actions;

export default tabStatusSlice.reducer;
