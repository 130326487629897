import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import TextArea from "../../reusable-form-fields/text-area";

const MapSection = ({ index }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register
  } = useFormContext();
  return (
    <Row>
      <Col xs={12} md={12}>
        <TextArea
          label={`${t("customerWebsite.mapLink")}`}
          name={`sections[${index}].data.link`}
          register={register}
          errors={errors?.sections && errors?.sections[index]?.data?.link}
        />
      </Col>
    </Row>
  );
};

export default MapSection;
