import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth";
import tagsSlice from "./slices/tags";
import missingInfoSlice from "./slices/missingInfo";
import integrationsInfoSlice from "./slices/integrationsInfo";
import performanceReportQueryParamsSlice from "./slices/performanceReportQueryParams";
import tabStatusSlice from "./slices/tabStatus";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    tags: tagsSlice,
    integrationsInfo: integrationsInfoSlice,
    missingInfo: missingInfoSlice,
    performanceReportQueryParams: performanceReportQueryParamsSlice,
    tabStatus: tabStatusSlice
  }
});
