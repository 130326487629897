import { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  ProgressBar,
  Row,
  Spinner
} from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getPixabayImages } from "../../services/api-declaration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import CustomDropdown from "./dropdown";
import { showError } from "../../services/toast-service";
import { updateAssetEntry } from "../../services/common-service";
import { createAssetEntry } from "../../services/api-declaration";
import { axioxFileUpload } from "../../services/axios-rest-methods";

const CardContainer = styled.div`
  height: 250px;
  width: 100%;
  & .card {
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
  & .img-container {
    pointer-events: auto;
    & img {
      cursor: pointer;
      height: 100%;
      object-fit: contain;
    }
    height: 211px;
  }
`;

const ViewIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0px;
  justify-content: end;
  align-self: stretch;
`;

const SearchImageModal = ({ onClose, onFinish }) => {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalHits, setTotalHits] = useState(20);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [url, setUrl] = useState(null);
  const [isApiFetching, setIsApiFetching] = useState(true);
  const [progress, setProgress] = useState(0);
  const [uploadingImage, setUploadingImage] = useState(null);
  const observerRef = useRef(null);
  const progressRef = useRef(null);

  const toggleModal = (url) => {
    setUrl(url || null);
    setShowPreview(!showPreview);
  };

  const handleUploadFile = async (blob) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      const draftAsset = await createAssetEntry({
        contentType: blob.type,
        fileSize: blob.size,
        fileName: "Pixabay Image"
      });
      const bodyData = {
        "Content-Type": draftAsset.data.fields["Content-Type"],
        key: draftAsset.data.fields["key"],
        bucket: draftAsset.data.fields["bucket"],
        "X-Amz-Algorithm": draftAsset.data.fields["X-Amz-Algorithm"],
        "X-Amz-Credential": draftAsset.data.fields["X-Amz-Credential"],
        "X-Amz-Date": draftAsset.data.fields["X-Amz-Date"],
        Policy: draftAsset.data.fields["Policy"],
        "X-Amz-Signature": draftAsset.data.fields["X-Amz-Signature"],
        file: blob
      };
      const upload = await axioxFileUpload(
        draftAsset.data.url,
        bodyData,
        function (progress) {
          setProgress(progress);
        }
      );
      console.log(upload);
      const assetData = {
        _id: draftAsset.data._id,
        ...draftAsset.data,
        ETag: upload.headers.etag,
        draft: false
      };
      await updateAssetEntry(assetData);
      // const category = formValues.category
      //   ? { name: formValues.category.name, _id: formValues.category._id }
      //   : null;
      await onFinish({
        ...assetData
        // category
      });
      setUploadingImage(null);
    } catch (err) {
      console.log(err);
      showError(
        err?.response?.data?.message ||
          // || t("toastMessage.uploadItemError")
          "Error"
      );
    } finally {
      setIsUploading(false);
    }
  };

  const handleLibraryImageUpload = async (image_url, index) => {
    if (isUploading) return;
    try {
      setUploadingImage(index);
      const response = await fetch(image_url);
      if (!response.ok) {
        throw new Error("Bild konnte nicht abgerufen werden");
      }
      const blob = await response.blob();
      await handleUploadFile(blob);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchImages = useCallback(
    async (search, page) => {
      setLoading(true);
      if (page > Math.ceil(totalHits / 20)) {
        setIsApiFetching(false);
        setLoading(false);
        return;
      }
      try {
        const params = {
          q: search || "",
          image_type: "photo",
          per_page: 20,
          page
        };
        const response = await getPixabayImages(params);
        if (response.status === 200) {
          const hits = response.data.data.hits;
          setTotalHits(response.data.data.totalHits);
          if (hits.length > 0) {
            setRecords((prev) => {
              const existingIds = new Set(prev.map((item) => item.id));
              const newRecords = hits.filter(
                (item) => !existingIds.has(item.id)
              );
              return [...prev, ...newRecords];
            });
          }
        }
      } catch (e) {
        setIsApiFetching(false);
        showError(e.response.data.message);
        console.error("Error fetching images:", e);
      }
      setLoading(false);
    },
    [totalHits]
  );

  useEffect(() => {
    setTotalHits(20);
  }, [searchTerm]);

  useEffect(() => {
    setIsApiFetching(true);
    fetchImages(searchTerm, page);
  }, [fetchImages, searchTerm, page]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loading && isApiFetching) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );
    if (document.getElementById("loadMoreTrigger")) {
      observerRef.current.observe(document.getElementById("loadMoreTrigger"));
    }
  }, [loading, isApiFetching]);

  const methods = useForm({ defaultValues: { search: "" } });

  const { control, handleSubmit } = methods;

  const handleFetchImages = async (formVals) => {
    setSearchTerm(formVals.search);
    setIsApiFetching(true);
    setRecords([]);
    setPage(1);
  };

  return (
    <>
      <Modal centered size="xl" show={true} scrollable>
        <Modal.Header className="bg-primary text-light">
          <Modal.Title>Search Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0" style={{ height: "800px" }}>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(handleFetchImages)}>
              <Row className="align-items-center sticky-top bg-white py-2">
                <Col md={12}>
                  <FormGroup>
                    <Form.Label>Search</Form.Label>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="search"
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control {...field} placeholder="Search" />
                        )}
                      />
                      <Button type="submit" variant="light">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                {records.length > 0 &&
                  records.map((item, index) => (
                    <div className="col-lg-3 mb-3" key={item.id}>
                      <CardContainer>
                        <Card>
                          <Card.Header className="p-0 ps-2 d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <ViewIconContainer
                                onClick={() => toggleModal(item.largeImageURL)}
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="text-secondary"
                                />
                              </ViewIconContainer>
                              <CustomDropdown item={item} />
                            </div>
                          </Card.Header>
                          <div
                            className={`position-absolute ${
                              isUploading && uploadingImage === index
                                ? "d-flex"
                                : "d-none"
                            } justify-content-center align-items-center w-100 h-100 top-0 left-0 rounded`}
                            style={{ background: "rgba(0,0,0,0.5)" }}
                          >
                            <p
                              className="text-white fw-bold mt-3"
                              style={{ fontSize: "20px" }}
                            >
                              {Math.round(progress)}%
                            </p>
                          </div>
                          <div
                            className="img-container p-2"
                            onClick={() => {
                              handleLibraryImageUpload(
                                item.largeImageURL,
                                index
                              );
                            }}
                          >
                            <Card.Img src={item.webformatURL} />
                          </div>
                        </Card>
                      </CardContainer>
                    </div>
                  ))}
              </Row>
              <ProgressBar
                className={`mt-2 ${!isUploading ? "d-none" : "d-flex"}`}
                ref={progressRef}
                striped
                animated
              />
            </Form>
          </FormProvider>

          <div id="loadMoreTrigger" className="text-center mt-3">
            {loading && <Spinner animation="border" />}
          </div>
          {!loading && records.length === 0 && <p>No Record Found</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose} disabled={isUploading}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showPreview && (
        <ImagePreviewModal source={url} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default SearchImageModal;
