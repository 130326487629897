import moment from "moment";
import styled from "styled-components";
import { useOutletContext, useParams } from "react-router";
import React, { useRef, useEffect, useState, useCallback } from "react";
import { ButtonGroup, ToggleButton, CardGroup, Card } from "react-bootstrap";
import {
  RADIOS,
  RADIO_RANGE,
  transformData,
  amountFormatter,
  DATE_RANGE_MODE,
  generateCustomDateRange
} from "./analytics-helper";
import Overlay from "../overlay";
import KPITile from "./kpi-tile";
import Loader from "../loader/loader";
import {
  fetchFunnelInsights,
  fetchLeadServiceInsight,
  fetchFbAdLeadsByFunnelId,
  fetchLeadResponseInsight,
  fetchFunnelInsightVersions
} from "../../services/api-declaration";
import SunburstChart from "./sunburst-chart";
import { CustomCard } from "../common-styling";
import { SiteVisitsChart } from "./site-visits-chart";
import DatePickerLimitRange from "./date-range-picker";
import { showError } from "../../services/toast-service";
import FunnelVersionsAnalytics from "./funnel-versions-analytics";
import BlockedPostalCode from "../blocked-postal-code/blocked-postal-code";
import BlockedPostalCodeCount from "../blocked-postal-code/blocked-postal-code-count";
import VersionDropdown from "../reusable-form-fields/version-dropdown";
import { useTranslation } from "react-i18next";
import "./lead-details.css";
import "./funnel-analytics.css";
import LeadServiceAnalytics from "./lead-service-analytics";
import FunnelAdsEvaluation from "./funnel-ads-evaluation";
import Drawer from "../drawer";

const ToggleBtn = styled(ToggleButton)`
  z-index: 0 !important;
`;

const graphColor = {
  allLandingVisitsCount: {
    color: "rgb(156 204 101)",
    areaColor: "rgb(241 248 233)"
  },
  landingPage: { color: "rgb(0 120 255)", areaColor: "rgba(0,120,255,0.06)" },
  leadCreated: { color: "rgb(32 201 151)", areaColor: "rgba(32,201,151,0.06)" },
  fbAdLeads: { color: "rgb(38, 198, 218)", areaColor: "rgb(224 247 250)" },
  websiteVisits: {
    color: "rgb(173 0 255)",
    areaColor: "rgba(173,0,255,0.06)"
  },
  phoneVisits: {
    color: "rgb(255, 173, 0)",
    areaColor: "rgba(255, 173, 0, 0.06)"
  },
  emailVisits: {
    color: "rgb(0, 255, 173)",
    areaColor: "rgba(0, 255, 173, 0.06)"
  }
};

const LEAD_COLOR = {
  COCKPIT: "#20c997",
  FB_COCKPIT: "#1878f3",
  FB_AD: "#053F5C",
  NOT_CONVERTED: "grey",
  UNCATEGORIZED: "#ff8a25"
};

const FunnelAnalytics = () => {
  const { t } = useTranslation();
  //refs
  const rootRef = useRef();
  const chartRef = useRef();
  const dateRangeModeRef = useRef(DATE_RANGE_MODE.ALL_TIME);

  // params
  const funnelId = useParams().id;
  const { funnel, funnelVersions } = useOutletContext();

  // states
  const [dataState, setDataState] = useState([]);
  const [leadDetailsData, setLeadDetailsData] = useState();
  const [leadServiceData, setLeadServiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [allLandingVisitsCount, setAllLandingVisitsCount] = useState();
  const [landingPage, setLandingPage] = useState();
  const [startedAt, setStartedAt] = useState("---");
  const [leadsCreated, setLeadsCreated] = useState();
  const [fbAdLeads, setFbAdLeads] = useState();
  const [websiteVisits, setWebsiteVisits] = useState();
  const [emailVisits, setEmailVisits] = useState();
  const [phoneVisits, setPhoneVisits] = useState();
  const [conversionRate, setConversionRate] = useState();
  const [allVersions, setAllVersions] = useState();
  const [radioValue, setRadioValue] = useState("1");
  const [hasData, setHasData] = useState(true);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedVersion, setSelectedVersion] = useState(
    funnelVersions?.currentVersion
  );
  const [showDrawer, setshowDrawer] = useState(false);

  const handleRadioSelect = async (value) => {
    setRadioValue(value);
    dateRangeModeRef.current = RADIO_RANGE[value];
    const [startDate, endDate] = _handleDateChange(new Date());
    if (RADIO_RANGE[value] !== DATE_RANGE_MODE.RANGE) {
      await fetchFunnelInsightsData({
        startDate,
        endDate
      });
    }
  };

  const convertKPIs = (kpis, startDate) => {
    setStartedAt(
      kpis.landingPage.graph[0]
        ? moment(kpis.landingPage.graph[0].startAt).format("Do MMMM YY")
        : startDate
    );
    const allLandingVisitsCount = createLineGraphConfig({
      graph: kpis.landingPage.graph,
      color: graphColor.allLandingVisitsCount.color,
      areaColor: graphColor.allLandingVisitsCount.areaColor,
      unit: kpis.landingPage.unit || "",
      count: kpis.landingPage.allLandingVisitsCount
    });
    const landingPage = createLineGraphConfig({
      graph: kpis.landingPage.graph,
      color: graphColor.landingPage.color,
      areaColor: graphColor.landingPage.areaColor,
      unit: kpis.landingPage.unit || "",
      count: kpis.landingPage.count
    });
    const leadsCreated = createLineGraphConfig({
      graph: kpis.leadCreated.graph,
      color: graphColor.leadCreated.color,
      areaColor: graphColor.leadCreated.areaColor,
      unit: kpis.leadCreated.unit || "",
      count: kpis.leadCreated.count
    });
    const fbAdLeads = createLineGraphConfig({
      graph: kpis.fbAdLeads.graph,
      color: graphColor.fbAdLeads.color,
      areaColor: graphColor.fbAdLeads.areaColor,
      unit: kpis.fbAdLeads.unit || "",
      count: kpis.fbAdLeads.count
    });
    const websiteVisits = createLineGraphConfig({
      graph: kpis.websiteVisits.graph,
      color: graphColor.websiteVisits.color,
      areaColor: graphColor.websiteVisits.areaColor,
      unit: kpis.websiteVisits.unit || "",
      count: kpis.websiteVisits.count
    });

    const emailVisits = createLineGraphConfig({
      graph: kpis.emailVisits.graph,
      color: graphColor.emailVisits.color,
      areaColor: graphColor.emailVisits.areaColor,
      unit: kpis.emailVisits.unit || "",
      count: kpis.emailVisits.count
    });

    const phoneVisits = createLineGraphConfig({
      graph: kpis.phoneVisits.graph,
      color: graphColor.phoneVisits.color,
      areaColor: graphColor.phoneVisits.areaColor,
      unit: kpis.phoneVisits.unit || "",
      count: kpis.phoneVisits.count
    });
    const dataset = kpis.conversionRate.graph.map((mp) => ({
      ...mp,
      color: LEAD_COLOR[mp._id]
    }));
    const conversionRate = createPieGraphConfig({
      dataset,
      count: kpis.conversionRate.count,
      unit: kpis.conversionRate.unit || ""
    });
    setHasData(Boolean(landingPage.count || leadsCreated.count));
    setAllLandingVisitsCount(allLandingVisitsCount);
    setLandingPage(landingPage);
    setLeadsCreated(leadsCreated);
    setFbAdLeads(fbAdLeads);
    setWebsiteVisits(websiteVisits);
    setEmailVisits(emailVisits);
    setPhoneVisits(phoneVisits);
    setConversionRate(conversionRate);
  };

  const _findRangeMode = ({ startDate, endDate }) => {
    new Date();
    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    if (daysDiff < 1) {
      return DATE_RANGE_MODE.TODAY;
    } else if (daysDiff >= 1 && daysDiff < 32) {
      return DATE_RANGE_MODE.MONTH;
    } else {
      return DATE_RANGE_MODE.YEAR;
    }
  };

  const handleLeftBtn = async () => {
    let date = new Date(dateRange[0].toDateString());
    switch (dateRangeModeRef.current) {
      case DATE_RANGE_MODE.DAY:
        date.setDate(date.getDate() - 1);
        break;
      case DATE_RANGE_MODE.WEEK:
        date.setDate(date.getDate() - 7);
        break;
      case DATE_RANGE_MODE.MONTH:
        date.setMonth(date.getMonth() - 1);
        break;
      default:
        date.setFullYear(date.getFullYear() - 1);
        break;
    }

    const [startDate, endDate] = _handleDateChange(date);
    await fetchFunnelInsightsData({
      startDate,
      endDate
    });
  };
  const handleRightBtn = async () => {
    let date = new Date(dateRange[1].toDateString());
    date.setDate(date.getDate() + 1);

    if (date < new Date()) {
      const [startDate, endDate] = _handleDateChange(date);
      await fetchFunnelInsightsData({
        startDate,
        endDate
      });
    }
  };

  const fetchFunnelInsightsData = async ({
    startDate = new Date(),
    endDate = new Date(),
    version = funnelVersions?.currentVersion
  } = {}) => {
    if (!funnelVersions) {
      return;
    }
    setLoading(true);
    let dateRangeMode = dateRangeModeRef.current;
    if (dateRangeMode === DATE_RANGE_MODE.RANGE) {
      dateRangeMode = _findRangeMode({ startDate, endDate });
    }
    endDate = new Date(endDate);
    endDate.setDate(endDate.getDate() + 1);
    if (dateRangeModeRef.current === DATE_RANGE_MODE.ALL_TIME) {
      startDate = endDate = undefined;
    }
    setSelectedVersion(version);
    try {
      const [
        { value: versionInsight },
        { value: funnelInsight },
        { value: fbAdLeadsInfo },
        { value: leadRespInsight },
        { value: leadServiceInsights }
      ] = await Promise.allSettled([
        fetchFunnelInsightVersions(funnelId, {
          startDate,
          endDate,
          dateRangeMode
        }),
        fetchFunnelInsights(funnelId, {
          startDate,
          endDate,
          dateRangeMode,
          funnelVersion: version ? version._id : selectedVersion._id
        }),
        fetchFbAdLeadsByFunnelId(funnelId, {
          startDate,
          endDate,
          dateRangeMode
        }),
        fetchLeadResponseInsight(funnelId, {
          startDate,
          endDate,
          dateRangeMode,
          funnelVersion: version ? version._id : selectedVersion._id
        }),
        fetchLeadServiceInsight(funnelId, {
          startDate,
          endDate,
          dateRangeMode,
          funnelVersion: version ? version._id : selectedVersion._id
        })
      ]);
      let kpis = undefined;
      createBarChartConfig(versionInsight.data.data);
      setLeadDetailsData(leadRespInsight.data.data);
      setLeadServiceData(leadServiceInsights.data.data);
      if (funnelInsight.data.data) {
        setDataState(funnelInsight.data.data.analyticsData);
        kpis = { ...funnelInsight.data.data.kpis };
      }
      if (fbAdLeadsInfo.data.data) {
        kpis = { ...kpis, fbAdLeads: fbAdLeadsInfo.data.data };
      }
      if (kpis) {
        convertKPIs(kpis, startDate?.toDateString());
      }
    } catch (error) {
      showError(
        error.response?.data.message || t("toastMessage.fetchInsightsError")
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchFunnelInsightsDataCB = useCallback(
    fetchFunnelInsightsData,
    // eslint-disable-next-line
    [funnelId, funnelVersions]
  );

  const calcDelta = (graph) => {
    let delta = 0;
    const elements = [...graph].slice(-2);
    if (elements.length === 2) {
      const maxVal = Math.max(elements[1].count, elements[0].count, 1);
      delta = elements[1].count - elements[0].count;
      delta = Math.round((delta / maxVal) * 100);
    }
    return delta;
  };

  const createPieGraphConfig = ({ dataset, unit, count }) => {
    const data = {
      datasets: [
        {
          data: dataset.map((d) => d.count),
          backgroundColor: dataset.map((d) => d.color)
        }
      ],
      labels: dataset.map((d) => ` ${d.label}`)
    };
    const options = {
      legend: {
        display: false
      },
      interaction: {
        intersect: false
      }
    };

    const config = {
      type: "pie",
      data: data,
      options: options
    };
    const amount = `${count || "---"}${unit}`;
    return { config, unit, count, amount };
  };

  const createLineGraphConfig = ({ graph, color, areaColor, unit, count }) => {
    const newGraph = transformData({
      graph,
      dateRangeMode: dateRangeModeRef.current
    });
    const delta = calcDelta(newGraph);

    const formatToolTipDate = (date) => {
      switch (dateRangeModeRef.current) {
        case DATE_RANGE_MODE.TODAY:
          return `${moment(date).format("MMM Do YY HH")}h`;
        case DATE_RANGE_MODE.WEEK:
        case DATE_RANGE_MODE.MONTH:
          return moment(date).format("MMM Do YY");
        default:
          return moment(date).format("MMM YY");
      }
    };

    const data = {
      labels: newGraph.map((g) => formatToolTipDate(g.date)),
      datasets: [
        {
          label: `${unit ? " percent" : " count"}`,
          data: newGraph.map((g) => g.count),
          borderColor: color,
          backgroundColor: areaColor,
          fill: "start",
          pointStyle: "dash"
        }
      ]
    };
    const options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              callback: function () {
                return "";
              },
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ],
        y: {
          min: 0,
          max: 50
        },
        yAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              callback: function () {
                return "";
              },
              color: "rgba(0, 0, 0, 0)"
            }
          }
        ]
      },
      legend: {
        display: false
      },
      plugins: {
        filler: {
          propagate: false
        },
        title: {
          display: false,
          text: (ctx) => "Fill: " + ctx.chart.data.datasets[0].fill
        }
      },
      interaction: {
        intersect: false
      }
    };

    const config = {
      type: "line",
      data: data,
      options: options
    };

    const amount = count ? `${amountFormatter(count)}${unit}` : `---${unit}`;
    return { config, delta, unit, count, amount };
  };

  const createBarChartConfig = (versionsData) => {
    const dataSet = new Set();
    versionsData
      .flat()
      .forEach((mp) => dataSet.add(`Version ${mp.funnelVersion}`));
    const baseData = versionsData.map((mp) => mp.map((a) => a.count));
    const config = {
      type: "bar",
      data: {
        labels: Array.from(dataSet.values()),
        datasets: [
          {
            label: t("createdLeads"),
            data: baseData.map((v) => v[1]),
            backgroundColor: "#20c997"
          },
          {
            label: t("numberOfVisits"),
            data: baseData.map((v) => v[0]),
            backgroundColor: "#0078ff"
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        layout: { padding: 20 },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.1,
              barThickness: 56
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
    setAllVersions({ config, versionsData });
    // return { config };
  };

  const _handleDateChange = (date) => {
    const [startDate, endDate] = generateCustomDateRange(
      dateRangeModeRef.current,
      date
    );
    setDateRange([startDate, endDate]);
    setSelectedDate(startDate);
    return [startDate, endDate];
  };

  const _handleVersionChange = async (version) => {
    const [startDate, endDate] = dateRange;
    await fetchFunnelInsightsData({ startDate, endDate, version });
  };

  const onRangeChange = async (range) => {
    if (dateRangeModeRef.current !== DATE_RANGE_MODE.RANGE) {
      const [startDate, endDate] = _handleDateChange(range);
      await fetchFunnelInsightsData({
        startDate,
        endDate
      });
      return;
    }
    setDateRange(range);
    if (range[0] && range[1]) {
      const [startDate, endDate] = range;
      setSelectedDate(startDate);
      await fetchFunnelInsightsData({
        startDate,
        endDate
      });
    }
  };
  const toggleDrawer = () => {
    setshowDrawer((showDrawer) => !showDrawer);
  };

  useEffect(() => {
    if (dateRangeModeRef.current !== DATE_RANGE_MODE.RANGE) {
      fetchFunnelInsightsDataCB();
    }
  }, [fetchFunnelInsightsDataCB]);

  useEffect(() => {
    if (!chartRef.current || !dataState) {
      return;
    }
    const chart = new SiteVisitsChart(chartRef.current);
    chart.draw(dataState, rootRef.current.clientWidth);
    return () => chart.destroy();
  }, [dataState]);

  return (
    <div ref={rootRef} className="w-100">
      <>
        <CustomCard className="sticky-top mt-4 kpi-date-range-header">
          <Card.Header className="bg-white kpi-tiles-header">
            <div className="kpi-date">( Started From: {startedAt} )</div>
            <div className="d-flex justify-content-end justify-self-end">
              {dateRangeModeRef.current !== DATE_RANGE_MODE.ALL_TIME && (
                <DatePickerLimitRange
                  mode={DATE_RANGE_MODE}
                  className="w-50"
                  placeholderText={RADIOS[radioValue - 1].name}
                  dateRangeMode={dateRangeModeRef.current}
                  maxDate={moment.now()}
                  selectedDate={selectedDate}
                  handleLeftBtn={handleLeftBtn}
                  handleRightBtn={handleRightBtn}
                  dateRange={dateRange}
                  onRangeChange={onRangeChange}
                />
              )}
              <ButtonGroup>
                {RADIOS.map((radio, idx) => (
                  <ToggleBtn
                    disabled={loading}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    className="kpi-toggle-button"
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => handleRadioSelect(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleBtn>
                ))}
              </ButtonGroup>
              <div className="kpi-version">
                <span className="kpi-version-label">Version:</span>
                <div className="mx-2">
                  <VersionDropdown
                    selectedVersion={selectedVersion}
                    funnelVersions={funnelVersions}
                    handleVersionChange={_handleVersionChange}
                  />
                </div>
              </div>
            </div>
          </Card.Header>
        </CustomCard>
        {/* <h5 className="my-4">Analytics</h5> */}
        <CustomCard className="mt-4">
          <CardGroup>
            {!loading &&
            leadsCreated &&
            conversionRate &&
            landingPage &&
            websiteVisits ? (
              <>
                <KPITile
                  bulletColor={graphColor.allLandingVisitsCount.color}
                  title={t("funnelInsights.allLandingVisits")}
                  data={allLandingVisitsCount.amount}
                />
                <KPITile
                  bulletColor={graphColor.landingPage.color}
                  title={t("funnelInsights.visits")}
                  data={landingPage.amount}
                  config={landingPage.config}
                  delta={landingPage.delta}
                />
                <KPITile
                  bulletColor={graphColor.leadCreated.color}
                  title="Funnel Leads"
                  data={leadsCreated.amount}
                  config={leadsCreated.config}
                  delta={leadsCreated.delta}
                />
                <KPITile
                  bulletColor={graphColor.fbAdLeads.color}
                  title="Lead Ads"
                  data={fbAdLeads?.amount}
                  config={fbAdLeads?.config}
                  delta={fbAdLeads?.delta}
                />
                <KPITile
                  bulletColor={graphColor.websiteVisits.color}
                  title={t("funnelInsights.websiteVisits")}
                  data={websiteVisits.amount}
                  config={websiteVisits.config}
                  delta={websiteVisits.delta}
                />
                <KPITile
                  bulletColor={graphColor.phoneVisits.color}
                  title={t("funnelInsights.phoneVisits")}
                  data={phoneVisits.amount}
                  config={phoneVisits.config}
                  delta={phoneVisits.delta}
                />
                <KPITile
                  bulletColor={graphColor.emailVisits.color}
                  title={t("funnelInsights.emailVisits")}
                  data={emailVisits.amount}
                  config={emailVisits.config}
                  delta={emailVisits.delta}
                />
                {/* commented tiles as they are not functional */}
                {/* <KPITile title={t("contact")} data={"---%"} />
                <KPITile title={t("completionRate")} data={"---%"} /> */}
                <KPITile
                  bulletColors={[
                    LEAD_COLOR.COCKPIT,
                    LEAD_COLOR.FB_AD,
                    LEAD_COLOR.FB_COCKPIT,
                    LEAD_COLOR.UNCATEGORIZED
                  ]}
                  title={t("conversionRate")}
                  data={conversionRate.amount}
                  config={conversionRate.config}
                />
              </>
            ) : (
              <Card style={{ height: 200 }}>
                <Overlay show={loading} />
              </Card>
            )}
          </CardGroup>
        </CustomCard>
        <BlockedPostalCodeCount
          funnelId={funnelId}
          toggleDrawer={toggleDrawer}
        />
        <CustomCard className="my-4">
          {loading ? (
            <Loader />
          ) : hasData ? (
            <div ref={chartRef} className="funnel-analytic" />
          ) : (
            <Card.Header className="bg-white kpi-tiles-header justify-content-center">
              <h6 className="mb-0 fw-bold p-5 align-self-center">
                Not Enough Data to show
              </h6>
            </Card.Header>
          )}
        </CustomCard>

        <FunnelAdsEvaluation
          funnelId={funnelId}
          funnelVersion={selectedVersion?._id}
        />

        {funnel.leadService ? (
          <LeadServiceAnalytics data={leadServiceData} startedAt={startedAt} />
        ) : (
          <></>
        )}

        <CustomCard className="my-4">
          <Card.Header className="bg-white kpi-tiles-header">
            <h5 className="mb-0 fw-bold">All Versions Insight</h5>
            <div className="kpi-date">( Started From: {startedAt} )</div>
          </Card.Header>
          {allVersions && allVersions.versionsData.length ? (
            <FunnelVersionsAnalytics config={allVersions.config} />
          ) : (
            <h6 className="mb-0 fw-bold p-5 align-self-center">
              Not Enough Data to show
            </h6>
          )}
        </CustomCard>
        <CustomCard className="my-4">
          <Card.Header className="bg-white kpi-tiles-header">
            <h5 className="mb-0 fw-bold">Lead Ad Details</h5>
            <div className="kpi-date">( Started From: {startedAt} )</div>
          </Card.Header>
          <SunburstChart data={leadDetailsData} />
        </CustomCard>
        <Drawer
          showDrawer={showDrawer}
          toggleDrawer={toggleDrawer}
          drawerTitle={t("common.blockedPostalCode")}
          width="25"
        >
          <BlockedPostalCode />
        </Drawer>
      </>
    </div>
  );
};
export default FunnelAnalytics;
