import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Accordion, Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import TextArea from "../../reusable-form-fields/text-area";

const KeyPointSection = ({ index, isFormChanged }) => {
  const [keypointAccActiveKey, setKeypointAccActiveKey] = useState("0");
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    register,
    control,
    setFocus
  } = useFormContext();

  const { fields: keyPoints } = useFieldArray({
    control,
    name: `sections[${index}].data.keyPoints` // Path to the nested array
  });

  const KeyPointBGColorDisplay = watch(
    `sections[${index}].data.bgColorDisplay`
  );

  const keypointBgColorPickerRef = useRef();
  const keypointHeadingColorPickerRef = useRef();
  const keypointParaColorPickerRef = useRef();

  // Key Points Accordion
  const toggleKeyPointsAccordion = (key) => {
    if (key === keypointAccActiveKey) {
      setKeypointAccActiveKey("");
    } else {
      setKeypointAccActiveKey(key);
    }
  };

  const handleKeyPointsAccordionClick = (key) => {
    toggleKeyPointsAccordion(key?.toString());
  };

  const keyPointSectionError = errors.sections && errors?.sections[index]?.data;

  useEffect(() => {
    const activeKey = keyPointSectionError?.keyPoints?.findIndex(
      (key_point) => key_point
    );
    if (activeKey !== undefined && activeKey !== -1) {
      setKeypointAccActiveKey(activeKey?.toString());
      setFocus(`sections[${index}].heading.text`);
    }
  }, [keyPointSectionError?.keyPoints, setFocus, index]);

  const refs = useRef({});

  const getRef = (index, i, fieldName) => {
    const refKey = `section-${index}-keyPoint-${i}-${fieldName}`;
    if (!refs.current[refKey]) {
      refs.current[refKey] = React.createRef();
    }
    return refs.current[refKey];
  };

  return (
    <>
      <Row>
        <Col md={3} className="mt-1">
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.BGColor")}
            fieldName={`sections[${index}].data.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bgColor
            }
            fieldRef={keypointBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bgColor`)}
            displayFieldWatcher={KeyPointBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
            isDisabled={Boolean(!KeyPointBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <TextField
            label={t("customerWebsite.heading")}
            placeholder={t("customerWebsite.heading")}
            type="text"
            name={`sections[${index}].data.heading.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.heading?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.headingColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.heading.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.heading?.color
            }
            fieldRef={keypointHeadingColorPickerRef}
            fieldVal={watch(`sections[${index}].data.heading.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <TextArea
            label={t("customerWebsite.para")}
            name={`sections[${index}].data.para.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.para?.text
            }
            register={register}
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.paraColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.para.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.para?.color
            }
            fieldRef={keypointParaColorPickerRef}
            fieldVal={watch(`sections[${index}].data.para.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row>
        <Accordion defaultActiveKey="0" activeKey={keypointAccActiveKey}>
          {keyPoints.map((point, i) => (
            <Accordion.Item
              key={point.id}
              eventKey={i.toString()}
              className={
                errors.sections && errors?.sections[index]?.data?.keyPoints[i]
                  ? "border-danger"
                  : ""
              }
            >
              <Accordion.Header
                onClick={() => handleKeyPointsAccordionClick(i)}
              >
                <h6>
                  {t("customerWebsite.sections.keyPoint")} #{i + 1}{" "}
                  {watch(
                    `sections[${index}].data.keyPoints[${i}].heading.text`
                  )}
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col sm={12} md={6}>
                    <TextField
                      label={t("customerWebsite.heading")}
                      placeholder={t("customerWebsite.heading")}
                      type="text"
                      name={`sections[${index}].data.keyPoints[${i}].heading.text`}
                      errors={
                        errors?.sections &&
                        errors?.sections[index]?.data?.keyPoints &&
                        errors?.sections[index]?.data?.keyPoints[i]?.heading
                          ?.text
                      }
                      register={register}
                      noSpacing
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ColorPickerWithTextField
                      hideButton
                      label={t("customerWebsite.headingColor")}
                      placeholder="#000000"
                      fieldName={`sections[${index}].data.keyPoints[${i}].heading.color`}
                      fieldError={
                        errors?.sections &&
                        errors?.sections[index]?.data?.keyPoints &&
                        errors?.sections[index]?.data?.keyPoints[i]?.heading
                          ?.color
                      }
                      fieldRef={getRef(index, i, "headingColor")}
                      fieldVal={watch(
                        `sections[${index}].data.keyPoints[${i}].heading.color`
                      )}
                      defaultColorValue="#000000"
                      isInGroup
                      noSpacing
                      hideErrMsg
                      isFormChanged={isFormChanged}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <TextArea
                      label={t("customerWebsite.para")}
                      name={`sections[${index}].data.keyPoints[${i}].para.text`}
                      errors={
                        errors?.sections &&
                        errors?.sections[index]?.data?.keyPoints &&
                        errors?.sections[index]?.data?.keyPoints[i]?.para?.text
                      }
                      register={register}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ColorPickerWithTextField
                      hideButton
                      label={t("customerWebsite.paraColor")}
                      placeholder="#000000"
                      fieldName={`sections[${index}].data.keyPoints[${i}].para.color`}
                      fieldError={
                        errors?.sections &&
                        errors?.sections[index]?.data?.keyPoints &&
                        errors?.sections[index]?.data?.keyPoints[i]?.para?.color
                      }
                      fieldRef={getRef(index, i, "paraColor")}
                      fieldVal={watch(
                        `sections[${index}].data.keyPoints[${i}].para.color`
                      )}
                      defaultColorValue="#000000"
                      isInGroup
                      noSpacing
                      hideErrMsg
                      isFormChanged={isFormChanged}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
    </>
  );
};

export default KeyPointSection;
