import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import {
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  LastHeaderColumn,
  FirstHeaderColumn
} from "../common-styling";
import { LEAD_VIEW } from "../strings";
import LeadsTableRow from "./leads-table-row";
import TableLoader from "../loader/table-loader";
import ViewMoreButton from "../view-more-button";
import {
  getAllLeads,
  getAllPartialLeads,
  getSystemHealthErrorByApi
} from "../../services/api-declaration";
import {
  generateSystemIssueText,
  generateSystemActionText
} from "../../services/common-service";
import {
  leadFilterConfig,
  upcomingLeadFilterConfig
} from "../funnels-table/filter-config";
import { showError } from "../../services/toast-service";
import AdvancedFilters from "../table-filter/advanced-filter";
import CustomPagination from "../pagination/custom-pagination";
import { useTranslation } from "react-i18next";

const ErrorNotificationBanner = styled.div`
  background: orange;
  padding: 2px;
  padding-left: 20px;
  color: white;
  font-size: 12px;
  font-weight: bold;
`;

const LeadsTable = ({ customerId = null, asSubComponent = false, limit }) => {
  const { t } = useTranslation();
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [shouldResetFilters, setShouldResetFilters] = useState(false);
  const [systemHealthData, setSystemHealthData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { COMPLETED, PARTIAL } = LEAD_VIEW;
  const isPartialTabSelected = searchParams.get("view") === PARTIAL;

  const fetchLeads = async () => {
    try {
      setIsLoading(true);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      if (customerId) {
        params.customer = customerId;
      }
      if (limit) {
        params.limit = limit;
      }
      let response = null;
      if (isPartialTabSelected) {
        response = await getAllPartialLeads(params);
      } else {
        response = await getAllLeads(params);
      }
      const data = response.data.data;
      setLeads(data.rows);
      setPaginateOpt({
        totalPages: data.totalPages,
        hasPrevPage: data.hasPrevPage,
        hasNextPage: data.hasNextPage,
        totalDocs: data.totalDocs,
        rowsOnPage: data.rows.length
      });
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "leads" })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const handleSelectTab = (evKey) => {
    searchParams.set("view", evKey);
    searchParams.set("pageIndex", "1");
    // removing following because these are only available in completed leads advanced filters
    searchParams.delete("source");
    searchParams.delete("status");
    searchParams.delete("archive");
    setSearchParams(searchParams);
    setShouldResetFilters(true);
  };
  const leadsCallback = useCallback(
    fetchLeads,
    // eslint-disable-next-line
    [searchParams, customerId, limit]
  );

  useEffect(() => {
    const fetchLeadError = async () => {
      try {
        const sysHealthRes = await getSystemHealthErrorByApi({
          apiName: "UPDATE_LEAD_ON_CC"
        });
        setSystemHealthData(sysHealthRes.data.data[0]);
      } catch (error) {
        console.log("Error in fetch system health alert", error);
        showError("Error in fetch system health alert!");
      }
    };
    fetchLeadError();
  }, []);
  useEffect(() => {
    leadsCallback();
  }, [leadsCallback]);

  return (
    <div>
      <PageHeading className="">Leads</PageHeading>
      <div className="mt-3">
        {!asSubComponent && (
          <div>
            <AdvancedFilters
              components={
                isPartialTabSelected
                  ? upcomingLeadFilterConfig
                  : leadFilterConfig
              }
              heading="Leads"
              pageName="search"
              placeholder={t("userSearchPlaceholder")}
              btnText="Lead"
              hideAddNew
              downloadLeadsButton={!isPartialTabSelected}
              isDownloadLeadsButtonDisabled={
                !searchParams.get("funnel") || !paginateOpts.totalDocs
              }
              resetFiltersForcefully={shouldResetFilters}
              setResetFiltersForcefully={setShouldResetFilters}
            />
          </div>
        )}
      </div>
      <Tabs
        id="justify-tabs"
        className="mt-4 mb-2"
        onSelect={(evKey) => handleSelectTab(evKey)}
        activeKey={isPartialTabSelected ? PARTIAL : COMPLETED}
      >
        <Tab eventKey={COMPLETED} title={t("leadTabs.leads")}></Tab>
        <Tab eventKey={PARTIAL} title={t("leadTabs.incompleteLeads")}></Tab>
      </Tabs>
      {systemHealthData && (
        <ErrorNotificationBanner>
          Alert! {generateSystemIssueText(systemHealthData.issue)} error
          occurred in {generateSystemActionText(systemHealthData.action)}.
        </ErrorNotificationBanner>
      )}
      <CustomCard className="mb-3 p-3 rounded-0">
        <BootstrapTable responsive>
          <thead>
            <tr>
              <FirstHeaderColumn>{t("common.name")}</FirstHeaderColumn>
              {!isPartialTabSelected && (
                <HeaderColumn className="text-center">
                  {t("common.syncStatus")}
                </HeaderColumn>
              )}
              <HeaderColumn>Funnel / {t("common.customer")}</HeaderColumn>
              {!isPartialTabSelected && (
                <>
                  <HeaderColumn className="text-center">CC Stage</HeaderColumn>
                  <HeaderColumn minWidth="230" className="text-center">
                    {t("forwardedOnContactCloud")}
                  </HeaderColumn>
                </>
              )}
              <HeaderColumn>
                {t("common.created")} {t("common.on")}
              </HeaderColumn>
              {!isPartialTabSelected && (
                <HeaderColumn>{t("common.telephone")}</HeaderColumn>
              )}
              <HeaderColumn>{t("common.postalCode")}</HeaderColumn>
              <LastHeaderColumn empty></LastHeaderColumn>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoader colSpan={9} />
            ) : (
              <LeadsTableRow leads={leads} fetchLeads={fetchLeads} />
            )}
          </tbody>
        </BootstrapTable>
      </CustomCard>
      {!asSubComponent ? (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      ) : paginateOpts.totalDocs > 0 ? (
        <ViewMoreButton
          id={customerId}
          path="../../../leads"
          searchParams={searchParams}
          resultsLength={leads.length}
          totalDocs={paginateOpts.totalDocs}
          handleQueryParams={handleQueryParams}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default LeadsTable;
