import { Col, Spinner } from "react-bootstrap";
import { CustomCard } from "../common-styling";
import { ReportStatistics } from "./report-statistics";
import { useTranslation } from "react-i18next";

const GroupedReportStats = ({ report, label, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <h6 className="text-capitalize fw-bold">{label}</h6>
      <CustomCard className="px-3 py-4">
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : report ? (
          <ReportStatistics
            leadCount={report.totalLeadCount}
            costPerLead={report.costPerLead}
            spend={report.spend}
            costPerQualifiedLead={report.costPerQualifiedLead}
            leadQualifiedCount={report.leadQualifiedCount}
          />
        ) : (
          <div className="fw-bold">{t("noData")}</div>
        )}
      </CustomCard>
    </Col>
  );
};
export default GroupedReportStats;
