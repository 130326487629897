import styled from "styled-components";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Filter from "../../components/table-filter/filter";
import {
  TableRow,
  CustomCard,
  PageHeading,
  HeaderColumn,
  BootstrapTable,
  TableDetailCell,
  LastHeaderColumn,
  FirstHeaderColumn,
  TableDetailNameCell
} from "../../components/common-styling";
import { THUMBNAIL_TYPE } from "../strings";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { showError } from "../../services/toast-service";
import { getAllAssets } from "../../services/api-declaration";
import AdvancedFilters from "../table-filter/advanced-filter";
import ImagePreviewModal from "../funnel-form/preview-image-modal";
import { galleryFilterConfig } from "../funnels-table/filter-config";
import CustomPagination from "../../components/pagination/custom-pagination";
import { bytesToSize, generateDateTime } from "../../services/common-service";
import { useTranslation } from "react-i18next";
import CreateMultipleGalleryModal from "../gallery/create-multiple-gallery-modal";

const ImageContainer = styled.div`
  height: 40px;
  width: 40px;
  & img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
`;

const GalleryTable = () => {
  const { t } = useTranslation();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPreview, setShowPreview] = useState(false);
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const parseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (err) {
      return [];
    }
  };
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          if (key === "tags") {
            params[key] = parseJSON(value);
          } else {
            params[key] = value;
          }
        }
        const response = await getAllAssets(params);
        const data = response.data.data;
        setAssets(data.rows);
        setPaginateOpt({
          totalPages: data.totalPages,
          hasPrevPage: data.hasPrevPage,
          hasNextPage: data.hasNextPage,
          totalDocs: data.totalDocs,
          rowsOnPage: data.rows.length
        });
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", { what: "assets" })
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssets();
    // eslint-disable-next-line
  }, [searchParams]);
  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOnUploadFinish = async (assetData) => {
    try {
      if (assets.length > 9) {
        const allAssets = [...assets];
        allAssets.pop();
        allAssets.splice(0, 0, assetData);
        setAssets(allAssets);
        setPaginateOpt({
          ...paginateOpts,
          totalDocs: paginateOpts.totalDocs + 1,
          hasNextPage: true
        });
      } else {
        setAssets((prev) => [assetData, ...prev]);
      }
      // handleClose();
    } catch (error) {
      showError(
        error?.response?.data?.message || t("toastMessage.createAssetError")
      );
    }
  };
  const toggleModal = (item) => {
    if (item?.url) {
      setUrl(`${item.url}/${item.key}`);
    } else {
      setUrl(null);
    }
    setShowPreview(!showPreview);
  };
  return (
    <>
      <div>
        <PageHeading className="">{t("sidebar.gallery")}</PageHeading>
        <div className="mt-3">
          <AdvancedFilters
            components={galleryFilterConfig}
            heading={t("sidebar.gallery")}
            pageName="title"
            placeholder={t("searchByNamePlaceholder")}
            btnText={t("common.createNew.title", {
              name: "asset"
            })}
            handleModal={handleShow}
          />
        </div>
        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <BootstrapTable responsive>
            <thead>
              <tr>
                <FirstHeaderColumn>{t("common.name")}</FirstHeaderColumn>
                <HeaderColumn>{t("common.type")}</HeaderColumn>
                <HeaderColumn>Size</HeaderColumn>
                <HeaderColumn>{t("fileType")}</HeaderColumn>
                <HeaderColumn>{t("common.category")}</HeaderColumn>
                <HeaderColumn>{t("uploadedOn")}</HeaderColumn>
                <LastHeaderColumn empty></LastHeaderColumn>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoader colSpan={8} />
              ) : assets.length ? (
                assets.map((item) => (
                  <TableRow key={item._id} title={item.title}>
                    <TableDetailNameCell className="py-3">
                      <div className="d-flex flex-row">
                        <div className="d-flex align-items-center me-2">
                          <Link to={`${item._id}`}>
                            <ImageContainer className="me-1">
                              <img
                                src={`${process.env.REACT_APP_ASSET_CDN_URL}/${item.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`}
                                alt=""
                              />
                            </ImageContainer>
                          </Link>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link to={`${item._id}`}>
                            <p className="m-0 fw-bold">{item.title}</p>
                          </Link>
                        </div>
                      </div>
                    </TableDetailNameCell>
                    <TableDetailCell className="py-3">
                      {item.type || "N/A"}
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {bytesToSize(item.fileSize)}
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {item.fileType}
                    </TableDetailCell>
                    <TableDetailCell className="py-3">
                      {item?.category?.name || "N/A"}
                    </TableDetailCell>
                    <TableDetailCell>
                      {generateDateTime(item?.createdAt)}
                    </TableDetailCell>
                    <TableDetailCell className="d-flex justify-content-end py-3">
                      <Button
                        variant="outline-secondary me-2"
                        onClick={() => toggleModal(item)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </TableDetailCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsRow lengthOfColumns="7" />
              )}
            </tbody>
          </BootstrapTable>
        </CustomCard>
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      </div>
      {show && (
        <CreateMultipleGalleryModal
          onFinish={handleOnUploadFinish}
          onClose={handleClose}
        />
      )}
      {showPreview && (
        <ImagePreviewModal source={url} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default GalleryTable;
