import styled from "styled-components";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Card, Button, Container } from "react-bootstrap";
import PDFViewer from "./pdf-viewer";
import { SCRIPT_ROUTE } from "../strings";
import { setMe } from "../../store/slices/auth";
import UploadScriptDialog from "./upload-script-dialog";
import { showError, showSuccess } from "../../services/toast-service";
import { CustomCard, PageHeading } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";
import LeadserviceOverview from "../funnel-leadservice-overview";
import { generateScriptUrl } from "../../services/common-service";
import {
  getAppointmentEmailTemplates,
  updateAppointmentEmailTemplates,
  updateLeadBlockTime
} from "../../services/api-declaration";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import EditorModal from "./email-editor-modal";
import { APPOINTMENT_EMAIL_TEMPLATES } from "../strings";
import Loader from "../loader/loader";

const PlusSpan = styled.span`
  font-size: 20px;
`;
const EmailPreview = styled.div`
  height: 115px;
  overflow-y: auto;
  white-space: pre-wrap;
`;
const SubjectPreview = styled.div`
  height: 20px;
  white-space: pre-wrap;
`;
const HorizontalLine = styled.hr`
  margin-bottom: 0;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  color: #959ba1;
`;

const { CONFIRM_EMAIL, CANCEL_EMAIL, POSTPONE_EMAIL, LEAD_NOT_REACHED } =
  APPOINTMENT_EMAIL_TEMPLATES;

const LeadservicePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingLeadBlock, setLoadingLeadBlock] = useState(false);
  const [availableAfter, setAvailableAfter] = useState();
  const [showScriptDialog, setShowScriptDialog] = useState(false);
  const [scriptTitle, setScriptTitle] = useState("");
  const [modalType, setModalType] = useState(null);

  const toggleUploadDialog = () => setShowScriptDialog(!showScriptDialog);

  const onEditClick = (title) => {
    toggleUploadDialog();
    setScriptTitle(title);
  };

  const fetchAvailableAfter = async () => {
    try {
      const resp = localStorage.getItem("userData");
      if (resp) {
        const data = JSON.parse(resp).workspace.availableAfterDelay;
        setAvailableAfter(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateLeadBlockTimeFunc = async () => {
    try {
      setLoadingLeadBlock(true);
      const resp = await updateLeadBlockTime({ availableAfter });
      if (resp.data.data) {
        dispatch(
          setMe({
            userData: {
              ...user,
              workspace: {
                ...user.workspace,
                availableAfterDelay: availableAfter
              }
            }
          })
        );
        showSuccess(resp.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLeadBlock(false);
    }
  };

  const methods = useForm({
    defaultValues: {
      CONFIRM_EMAIL: { subject: "", content: "" },
      CANCEL_EMAIL: { subject: "", content: "" },
      POSTPONE_EMAIL: { subject: "", content: "" },
      LEAD_NOT_REACHED: { subject: "", content: "" }
    }
  });

  const { setValue, getValues } = methods;

  const fetchEmailTemplates = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getAppointmentEmailTemplates();
      const templates = data.data;
      const templateMap = templates.reduce((acc, template) => {
        acc[template.type] = {
          subject: template.subject || "",
          content: template.template || ""
        };
        return acc;
      }, {});
      Object.keys(templateMap).forEach((type) => {
        setValue(type, templateMap[type]);
      });
    } catch (error) {
      showError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [setValue]);

  const updateEmailTemplate = async (type, { subject, template }) => {
    try {
      setIsLoading(true);
      const payload = { type, subject, template };
      const { data } = await updateAppointmentEmailTemplates(payload);
      showSuccess(data?.message);
    } catch (error) {
      showError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = (type) => {
    setModalType(type);
  };
  const handleCloseModal = () => {
    setModalType(null);
  };

  const getTitle = (type) => {
    switch (type) {
      case CONFIRM_EMAIL:
        return t("emailEdit.confirm");
      case CANCEL_EMAIL:
        return t("emailEdit.cancel");
      case POSTPONE_EMAIL:
        return t("emailEdit.postpone");
      case LEAD_NOT_REACHED:
        return t("emailEdit.notReached");
      default:
        return "";
    }
  };

  const handleSaveContent = ({ subject, content }) => {
    if (modalType) {
      const currentValues = getValues(modalType) || {};
      setValue(modalType, { ...currentValues, subject, content });
      updateEmailTemplate(modalType, { subject, template: content });
    }
    handleCloseModal();
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, [fetchEmailTemplates]);

  useEffect(() => {
    fetchAvailableAfter();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div>
          <PageHeading>Leadservice Panel</PageHeading>

          <Container fluid className="mt-4 px-0">
            <Form.Group controlId="available-after-delay">
              <Form.Label>{t("availableAfter")}</Form.Label>
              <div className="d-flex gap-2">
                <Form.Control
                  type="number"
                  className="w-25"
                  placeholder={`Enter ${t("availableAfter")}`}
                  // defaultValue={availableAfter}
                  value={availableAfter}
                  onChange={(e) => setAvailableAfter(e.target.value)}
                />
                {loadingLeadBlock ? (
                  <SpinnerButton btnText={t("common.save")} />
                ) : (
                  <Button
                    disabled={loadingLeadBlock}
                    onClick={updateLeadBlockTimeFunc}
                  >
                    {t("common.save")}
                  </Button>
                )}
              </div>
            </Form.Group>
            <LeadserviceOverview />

            <div className="mt-5 mb-3">
              <FormProvider {...methods}>
                <Row className="mb-3">
                  {Object.keys(APPOINTMENT_EMAIL_TEMPLATES).map((typeKey) => {
                    const type = APPOINTMENT_EMAIL_TEMPLATES[typeKey];
                    const emailData = getValues(type) || {};
                    return (
                      <Col md={12} lg={3} key={type}>
                        <CustomCard>
                          <Card.Header className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{getTitle(type)}</div>

                            <Button
                              onClick={() => handleOpenModal(type)}
                              size="sm"
                              variant="success"
                            >
                              {t("common.edit")}
                            </Button>
                          </Card.Header>
                          <SubjectPreview className="p-2">
                            <span className="fw-bold">
                              {t("emailEdit.subject")}:
                            </span>{" "}
                            <span>
                              {emailData.subject || (
                                <em>{t("emailEdit.noSubject")}</em>
                              )}
                            </span>
                          </SubjectPreview>
                          <HorizontalLine />
                          <EmailPreview
                            className="mt-0 p-2 email-preview"
                            dangerouslySetInnerHTML={{
                              __html:
                                emailData.content ||
                                `<em>${t("emailEdit.noContent")}</em>`
                            }}
                          ></EmailPreview>
                        </CustomCard>
                      </Col>
                    );
                  })}
                </Row>
                {modalType && (
                  <EditorModal
                    className="email-content"
                    title={getTitle(modalType)}
                    quillValue={getValues(modalType)?.content || ""}
                    subjectValue={getValues(modalType)?.subject || ""}
                    onSave={handleSaveContent}
                    onCancel={handleCloseModal}
                  />
                )}
              </FormProvider>
            </div>

            <Row className="mt-5 mb-3">
              <Col md={12} lg={4}>
                <CustomCard>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <div className="fw-bold">Leadservice {t("script")}</div>
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => onEditClick(SCRIPT_ROUTE.LS)}
                    >
                      {t("common.change")}
                    </Button>
                  </Card.Header>
                  <div className="p-3">
                    <PDFViewer
                      ariaLabel="default"
                      scriptUrl={generateScriptUrl({
                        workspace: user.workspace,
                        scriptProp: "leadServiceScript",
                        localFileName: "default.pdf"
                      })}
                    />
                  </div>
                </CustomCard>
              </Col>
              <Col md={12} lg={4}>
                <CustomCard>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <div className="fw-bold">
                      Leadservice<PlusSpan>+</PlusSpan> {t("script")}
                    </div>
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => onEditClick(SCRIPT_ROUTE.LS_PLUS)}
                    >
                      {t("common.change")}
                    </Button>
                  </Card.Header>
                  <div className="p-3">
                    <PDFViewer
                      ariaLabel="ls-plus"
                      scriptUrl={generateScriptUrl({
                        workspace: user.workspace,
                        scriptProp: "leadServicePlusScript",
                        localFileName: "leadservice_plus.pdf"
                      })}
                    />
                  </div>
                </CustomCard>
              </Col>
              <Col md={12} lg={4}>
                <CustomCard>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <div className="fw-bold">
                      {t("appointmentConfirm")} {t("script")}
                    </div>
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => onEditClick(SCRIPT_ROUTE.AP)}
                    >
                      {t("common.change")}
                    </Button>
                  </Card.Header>
                  <div className="p-3">
                    <PDFViewer
                      ariaLabel="ap"
                      scriptUrl={generateScriptUrl({
                        workspace: user.workspace,
                        scriptProp: "appointmentConfirmationScript",
                        localFileName: "appointment_confirmation.pdf"
                      })}
                    />
                  </div>
                </CustomCard>
              </Col>
            </Row>
            <UploadScriptDialog
              title={scriptTitle}
              show={showScriptDialog}
              toggle={toggleUploadDialog}
            />
          </Container>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default LeadservicePanel;
