import {
  Form,
  Badge,
  Modal,
  Button,
  ListGroup,
  ListGroupItem
} from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { useState, useCallback, useEffect } from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../loader/loader";
import {
  syncManually,
  getIntegrationsByFunnelId
} from "../../services/api-declaration";
import { useDialog } from "../../hooks/useDialog";
import { HorizontalDivider } from "../common-styling";
import { INTEGRATION_TYPE, LEAD_LOGS_STATUS_TYPE } from "../strings";
import { showSuccess, showError } from "../../services/toast-service";
import { generateContactCloudUrl } from "../../services/common-service";
import { useTranslation, Trans } from "react-i18next";

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const SyncingIcon = styled(FontAwesomeIcon)`
  animation-name: ${rotateAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
`;

const SyncLeadDialog = ({ leadInfo, show, fetchLeads, toggleDialog }) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegrationIds, setSelectedIntegrationIds] = useState([]);
  const { showConfirmationDialogBox } = useDialog();

  const toggleSyncConfirmation = () => {
    showConfirmationDialogBox({
      title: "Warning",
      dialogType: "warning",
      dialogMessage: renderDialogMessage(),
      responseAction: dialogResponseAction,
      isSync: true
    });
  };
  const hideLeadSyncDialog = () => {
    toggleDialog();
    setSelectedIntegrationIds([]);
  };
  const renderDialogMessage = () => (
    <div>
      <Trans i18nKey="leadSyncMsg" />
    </div>
  );
  const dialogResponseAction = (action) => {
    if (action && leadInfo) {
      handleSyncManually(leadInfo);
    }
  };
  const handleCheckboxChange = (isChecked, _integrationId) => {
    if (isChecked) {
      setSelectedIntegrationIds((item) => {
        const clone = [...item];
        clone.push(_integrationId);
        return clone;
      });
    } else {
      const filteredData = selectedIntegrationIds.filter(
        (selectedId) => selectedId !== _integrationId
      );
      setSelectedIntegrationIds(filteredData);
    }
  };
  const fetchIntegraions = async (_funnelId) => {
    setIsLoading(true);
    try {
      const response = await getIntegrationsByFunnelId(_funnelId);
      setIntegrations(response.data.data);
    } catch (err) {
      showError(err.response?.data?.message || "Error in fetching lead!");
    } finally {
      setIsLoading(false);
    }
  };
  const handleSyncBtnClick = (_lead) => {
    if (!_lead.syncData.length) {
      handleSyncManually(_lead);
    } else {
      const isNotSynced = _lead.syncData.find(
        (_syncData) => _syncData.status === LEAD_LOGS_STATUS_TYPE.FAIL
      );
      if (isNotSynced) {
        handleSyncManually(_lead);
      } else {
        toggleSyncConfirmation();
      }
    }
  };
  const handleSyncManually = async (_lead) => {
    setIsSyncing(true);
    try {
      const response = await syncManually({
        leadId: _lead._id,
        selectedIntegrationIds
      });
      showSuccess(response.message);
      fetchLeads();
    } catch (error) {
      showError(error.response.data.message || "Error in syncing lead!");
    } finally {
      setIsSyncing(false);
    }
  };
  const getLeadCallback = useCallback(
    (_funnelId) => fetchIntegraions(_funnelId),
    []
  );

  useEffect(() => {
    if (leadInfo.funnelId?._id) {
      getLeadCallback(leadInfo.funnelId._id);
    }
  }, [getLeadCallback, leadInfo.funnelId]);

  return (
    <>
      <Modal show={show} onHide={hideLeadSyncDialog} backdrop="static" centered>
        <Modal.Header className="bg-success text-white" closeButton>
          <h5 className="m-0">{t("sync")} Lead</h5>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loader calcHeight={400} />
          ) : (
            leadInfo && (
              <>
                <div className="d-flex align-items-center justify-content-between px-2 pb-2">
                  <div className="fw-bold">
                    {leadInfo.leadDetails.leadName || "---"}
                  </div>
                  <small className="fw-bold text-muted">
                    {leadInfo.leadDetails.leadEmail || "---"}
                  </small>
                </div>
                <HorizontalDivider className="mb-1" />
                {integrations?.length ? (
                  <>
                    <ListGroup>
                      {integrations.map((integration, idx) => (
                        <ListGroupItem
                          key={idx}
                          className={`border-0 rounded-0 mb-1 ${
                            !integration.active ? "disabled bg-light" : ""
                          }`}
                        >
                          <div className="d-flex">
                            <div className="me-3">
                              <Form.Check
                                id={idx}
                                role="button"
                                className="mt-1"
                                onChange={({ target: { checked } }) => {
                                  handleCheckboxChange(
                                    checked,
                                    integration._id
                                  );
                                }}
                              />
                            </div>
                            <div className="flex-fill text-break">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div>
                                    {integration.type ===
                                    INTEGRATION_TYPE.CONTACT_CLOUD
                                      ? generateContactCloudUrl({
                                          meta: integration.sourceData.meta
                                        })
                                      : integration.sourceData.id}
                                  </div>
                                  <div className="text-muted small">
                                    {integration.description || "---"}
                                  </div>
                                  {!integration.active && (
                                    <Badge bg="secondary">
                                      {t("common.inactive")}
                                    </Badge>
                                  )}
                                </div>
                                <div className="d-flex flex-column justify-content-between">
                                  <div className="text-end">
                                    <Badge
                                      pill
                                      bg="white"
                                      className="ms-2 text-dark border"
                                    >
                                      {integration.type}
                                    </Badge>
                                  </div>
                                  <div className="text-end small text-secondary fw-bold text-nowrap">
                                    {integration.sourceData.meta.sendData}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                    <HorizontalDivider />
                    <div className="text-end">
                      <Button
                        size="sm"
                        className="ms-2"
                        variant="outline-success"
                        onClick={() => handleSyncBtnClick(leadInfo)}
                        disabled={!selectedIntegrationIds.length || isSyncing}
                      >
                        <span className="me-2">
                          Sync{isSyncing && "ing..."}
                        </span>
                        {isSyncing ? (
                          <SyncingIcon size="sm" icon={faSync} />
                        ) : (
                          <FontAwesomeIcon size="sm" icon={faSync} />
                        )}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div>{t("noIntegrationMsg")}</div>
                )}
              </>
            )
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SyncLeadDialog;
