import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgentViewActivityBtn } from "../common-styling";
import SpinnerButton from "../spinner-button/spinner-button";

const ActivityButton = ({
  ACTIVITY_RESULT_TYPE,
  currentActivityLoader,
  isDisabled,
  activityHandler,
  ACTIVITY_TYPE,
  btnText,
  btnStyle,
  icon,
  btnTextPrimary,
  variant = "primary",
  funnelAppointment = false,
  appointmentDialog,
  comment,
  className,
  responses
}) => {
  const action = () => {
    if (funnelAppointment) {
      appointmentDialog();
    } else {
      activityHandler({
        type: ACTIVITY_TYPE,
        result: ACTIVITY_RESULT_TYPE,
        comment,
        responses
      });
    }
  };
  return (
    <>
      {currentActivityLoader[ACTIVITY_RESULT_TYPE] ? (
        <SpinnerButton btnText={btnText} btnStyle={btnStyle} />
      ) : (
        <AgentViewActivityBtn
          variant={variant}
          className={className}
          disabled={isDisabled}
          onClick={action}
        >
          {icon && <FontAwesomeIcon icon={icon} className="ms-1 me-2" />}
          {btnTextPrimary}
        </AgentViewActivityBtn>
      )}
    </>
  );
};

export default ActivityButton;
