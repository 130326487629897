import {
  Row,
  Col,
  Card,
  Spinner,
  ButtonGroup,
  ToggleButton
} from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { faMeta } from "@fortawesome/free-brands-svg-icons";
import {
  CustomCard,
  FunnelTypeBadge,
  PerformanceCardContainer
} from "../common-styling";
import AreaChart from "../charts/areaChart";
import { ReportStatistics } from "./report-statistics";
import { FUNNEL_PERFORMANCE_DURATION_FILTER } from "../strings";
import AbsoluteButtonAnchor from "../funnel-form/absolute-button-anchor";
import { getCustomerReportByFunnelId } from "../../services/api-declaration";
import DatePickerLimitRange from "../analytics/date-range-picker";
import { setFunnelFilterMode } from "../../store/slices/performanceReportQueryParams";
import { useTranslation } from "react-i18next";
import "./performance-card.css";

const CardTitle = styled.h5`
  color: var(--text-gray-muted);
  font-weight: bold;
`;

const Container = styled.div`
  width: 100%;
  height: 287px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const { WEEK, FORTNIGHT, MONTH, BT_LAST_NINETY_DAYS, CUSTOM_RANGE_UPTO_L90D } =
  FUNNEL_PERFORMANCE_DURATION_FILTER;
const CUSTOM_RANGE_MODE = { RANGE: CUSTOM_RANGE_UPTO_L90D };

const RADIOS = [
  { label: "advancedFilter.dateRange.days7", value: WEEK },
  { label: "advancedFilter.dateRange.days14", value: FORTNIGHT },
  { label: "advancedFilter.dateRange.days30", value: MONTH },
  { label: "advancedFilter.dateRange.days90", value: BT_LAST_NINETY_DAYS },
  {
    label: "advancedFilter.dateRange.customRangeUptoNinetyDays",
    value: CUSTOM_RANGE_UPTO_L90D
  }
];

export const PerformanceCard = ({
  report,
  funnelId = null,
  customerId = null,
  fromFunnelOverview = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { funnelParams, generalParams } = useSelector(
    (state) => state.performanceReportQueryParams
  );
  const [radioValue, setRadioValue] = useState(
    funnelParams[funnelId] ? funnelParams[funnelId].filterMode : FORTNIGHT
  );
  const [performance, setPerformance] = useState({ [radioValue]: report });
  const [series, setSeries] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [customRangeUptoLastNinetyDays, setCustomRangeUptoLastNinetyDays] =
    useState([]);

  const getStartAndEndDates = (range, customRange) => {
    if (range === CUSTOM_RANGE_UPTO_L90D) {
      return customRange.map((date) => new Date(moment(date)));
    }
    return [
      new Date(moment().subtract(range, "day").toISOString()),
      new Date(moment().subtract(1, "day").toISOString())
    ];
  };

  const handleRadioSelect = (value) => setRadioValue(value);

  const onRangeChange = async (range) => {
    const start = range[0];
    const end = range[1];
    if (start && end) {
      const startDate = new Date(start);
      let endDate = new Date(end);
      endDate.setDate(endDate.getDate() + 1);
      await fetchCustomerPerformance(startDate, endDate);
    }
    setSeries((prevSeries) =>
      Object.fromEntries(
        Object.entries(prevSeries).filter(
          ([key]) => key !== CUSTOM_RANGE_UPTO_L90D
        )
      )
    );
    setCustomRangeUptoLastNinetyDays((prevRange) =>
      prevRange[0] !== start || prevRange[1] !== end ? [start, end] : prevRange
    );
  };
  const generateSeries = useCallback(
    (rawData, customRangeUptoLastNinetyDays) => {
      const dateRange = fromFunnelOverview
        ? searchParams.get("dateRange") || FORTNIGHT
        : radioValue;
      let range;
      switch (dateRange) {
        case WEEK:
          range = 7;
          break;
        case FORTNIGHT:
          range = 14;
          break;
        case MONTH:
          range = 30;
          break;
        case BT_LAST_NINETY_DAYS:
          range = 90;
          break;
        default:
          range = CUSTOM_RANGE_UPTO_L90D;
      }
      const [startDate, end] = getStartAndEndDates(
        range,
        customRangeUptoLastNinetyDays
      );
      const graphSeries = [];
      for (let dt = startDate; dt <= end; dt.setDate(dt.getDate() + 1)) {
        const currDate = new Date(dt).toISOString().split("T")[0];
        let data = rawData.find((log) => log.date === currDate) || 0;
        if (data) {
          data = data.count;
        }
        graphSeries.push({
          x: new Date(dt).toISOString().split("T")[0],
          y: data
        });
      }
      return graphSeries;
    },
    [radioValue, fromFunnelOverview, searchParams]
  );

  const fetchCustomerPerformance = useCallback(
    async (startDate, endDate) => {
      if (!customerId) return;
      const queryParams = {
        filterMode: radioValue,
        startDate: startDate,
        endDate: endDate,
        ...generalParams
      };
      dispatch(setFunnelFilterMode({ funnelId, value: radioValue }));
      if (radioValue !== CUSTOM_RANGE_UPTO_L90D || (startDate && endDate)) {
        try {
          setIsLoading(true);
          const response = await getCustomerReportByFunnelId(
            customerId,
            funnelId,
            queryParams
          );
          const respData = response.data.data;
          setPerformance((perf) => ({
            ...perf,
            [radioValue]: respData || {}
          }));
        } catch (error) {
          console.log("ERROR: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [funnelId, customerId, radioValue, dispatch, generalParams]
  );

  useEffect(() => {
    if (!performance[radioValue] && radioValue !== CUSTOM_RANGE_UPTO_L90D) {
      fetchCustomerPerformance();
    }
  }, [radioValue, performance, fetchCustomerPerformance]);

  useEffect(() => {
    const performanceData = performance[radioValue];
    if (
      performanceData &&
      (performanceData.leadCounts?.length || performanceData.spend) &&
      !series[radioValue]
    ) {
      const data = generateSeries(
        performanceData.leadCounts,
        customRangeUptoLastNinetyDays
      );
      setSeries({
        ...series,
        [radioValue]: data
      });
    }
  }, [
    series,
    performance,
    radioValue,
    generateSeries,
    customRangeUptoLastNinetyDays
  ]);

  return (
    <PerformanceCardContainer className="p-3 mb-4 rounded-4">
      <Card.Header className="bg-white d-flex justify-content-between align-items-center">
        <div className="d-flex flex-fill align-items-center justify-content-between">
          <div>
            {!fromFunnelOverview && (
              <div className="d-flex align-items-center">
                <CardTitle className="mb-0 me-3 fw-bold leads-header text-[var(--text-gray-muted)]">
                  {t("common.funnel")}
                </CardTitle>
                <FunnelTypeBadge
                  className="d-flex align-items-center px-3"
                  btnbg={`--${report.funnelType?.toLowerCase()}-badge-bg`}
                  btncolor={`--${report.funnelType?.toLowerCase()}-color`}
                >
                  {report.funnelType?.toLowerCase()}
                </FunnelTypeBadge>
              </div>
            )}
            <h4 className="mb-0 fw-bold leads-header">{report.funnelName}</h4>
          </div>
          <div>
            {fromFunnelOverview && (
              <AbsoluteButtonAnchor
                icon={null}
                isDisabled={false}
                classes="me-2 pt-0"
                buttonText={`Funnel ${t("open")}`}
                customButtonStyles={{
                  paddingBottom: 2,
                  background: "#FF7959",
                  borderColor: "#FF7959"
                }}
                link={`/dashboard/funnels/${report._id}/form?funnelType=${report.funnelType}`}
              />
            )}
            <AbsoluteButtonAnchor
              icon={faMeta}
              link={report.advertisingAccountLink}
              isDisabled={!report.advertisingAccountLink}
              buttonText={t("openAdvertisingAccount")}
              customButtonStyles={{ paddingBottom: 2 }}
              classes={`pt-0 ${!fromFunnelOverview ? "me-3" : ""}`}
            />
          </div>
        </div>
        {!fromFunnelOverview && (
          <div className="d-flex justify-content-end performance--filter-div">
            {radioValue === CUSTOM_RANGE_UPTO_L90D && (
              <DatePickerLimitRange
                mode={CUSTOM_RANGE_MODE}
                className="w-50"
                selectedDate={moment().subtract(1, "day").toDate()}
                maxDate={moment().subtract(1, "day").toDate()}
                minDate={moment().subtract(90, "days").toDate()}
                fromCustomerPerformance
                dateRange={customRangeUptoLastNinetyDays}
                dateRangeMode={CUSTOM_RANGE_UPTO_L90D}
                onRangeChange={onRangeChange}
              />
            )}
            <ButtonGroup>
              {RADIOS.map((radio, idx) => (
                <ToggleButton
                  id={`radio-${idx + Math.floor(Math.random() * 10000)}`}
                  size="lg"
                  variant="dark"
                  key={`date-radio-${idx}`}
                  className={`date-filter--toggle ${
                    radioValue === radio.value && "active"
                  }`}
                  value={radio.value}
                  type="radio"
                  name="radio"
                  checked={radioValue === radio.value}
                  onChange={(e) => handleRadioSelect(e.currentTarget.value)}
                >
                  {t(radio.label)}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        )}
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <Container>
            <Spinner animation="border" />
          </Container>
        ) : (
          <Row className="align-items-center">
            <Col xs={12} lg={9} className="px-0">
              {series[radioValue]?.length ? (
                <AreaChart series={series[radioValue]} />
              ) : (
                <Container>
                  <CustomCard className="w-100 mx-3 mt-4 p-3 rounded-0">
                    <h6 className="mb-0 fw-bold p-5 align-self-center">
                      {t("noData")}
                    </h6>
                  </CustomCard>
                </Container>
              )}
            </Col>
            <Col xs={12} lg={3}>
              <ReportStatistics
                leadCount={performance[radioValue]?.totalLeadCount}
                costPerLead={performance[radioValue]?.costPerLead}
                spend={performance[radioValue]?.spend}
                costPerQualifiedLead={
                  performance[radioValue]?.costPerQualifiedLead
                }
                leadQualifiedCount={performance[radioValue]?.leadQualifiedCount}
                conversionRate={performance[radioValue]?.conversionRate}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </PerformanceCardContainer>
  );
};
