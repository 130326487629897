import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Accordion, Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";
import TextArea from "../../reusable-form-fields/text-area";

const ServiceSection = ({
  parseResourceSrc,
  parsePreviewResourceSrc,
  index,
  isFormChanged
}) => {
  const [serviceAccActiveKey, setServiceAccActiveKey] = useState("0");

  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    register,
    setFocus,
    control
  } = useFormContext();

  const { fields: services } = useFieldArray({
    control,
    name: `sections[${index}].data.services` // Path to the nested array
  });

  const ServiceBGColorDisplay = watch(`sections[${index}].data.bgColorDisplay`);

  const serviceBgColorPickerRef = useRef();
  const serviceHeadingColorPickerRef = useRef();
  const serviceParaColorPickerRef = useRef();

  const refs = useRef({});

  const getRef = (index, i, fieldName) => {
    const refKey = `section-${index}-service-${i}-${fieldName}`;
    if (!refs.current[refKey]) {
      refs.current[refKey] = React.createRef();
    }
    return refs.current[refKey];
  };

  const toggleServiceAccordion = (key) => {
    if (key === serviceAccActiveKey) {
      setServiceAccActiveKey("");
    } else {
      setServiceAccActiveKey(key);
    }
  };

  const handleServiceAccordionClick = (key) => {
    toggleServiceAccordion(key?.toString());
  };

  const serviceSectionError = errors.sections && errors?.sections[index]?.data;

  useEffect(() => {
    const activeKey = serviceSectionError?.services?.findIndex(
      (service) => service
    );
    if (activeKey !== undefined && activeKey !== -1) {
      setServiceAccActiveKey(activeKey?.toString());
      setFocus(`sections[${index}].data.heading.text`);
    }
  }, [serviceSectionError?.services, setFocus, index]);

  return (
    <>
      <Row>
        <Col md={3} className="mt-1">
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.BGColor")}
            fieldName={`sections[${index}].data.bgColor`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.bgColor
            }
            fieldRef={serviceBgColorPickerRef}
            fieldVal={watch(`sections[${index}].data.bgColor`)}
            displayFieldWatcher={ServiceBGColorDisplay}
            fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
            isDisabled={Boolean(!ServiceBGColorDisplay)}
            setDisplay
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}>
          <TextField
            label={t("customerWebsite.heading")}
            placeholder={t("customerWebsite.heading")}
            type="text"
            name={`sections[${index}].data.heading.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.heading?.text
            }
            register={register}
            noSpacing
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.headingColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.heading.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.heading?.color
            }
            fieldRef={serviceHeadingColorPickerRef}
            fieldVal={watch(`sections[${index}].data.heading.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <TextArea
            label={t("customerWebsite.para")}
            name={`sections[${index}].data.para.text`}
            errors={
              errors?.sections && errors?.sections[index]?.data?.para?.text
            }
            register={register}
          />
        </Col>
        <Col xs={12} md={6}>
          <ColorPickerWithTextField
            hideButton
            label={t("customerWebsite.paraColor")}
            placeholder="#000000"
            fieldName={`sections[${index}].data.para.color`}
            fieldError={
              errors?.sections && errors?.sections[index]?.data?.para?.color
            }
            fieldRef={serviceParaColorPickerRef}
            fieldVal={watch(`sections[${index}].data.para.color`)}
            defaultColorValue="#000000"
            isInGroup
            noSpacing
            hideErrMsg
            isFormChanged={isFormChanged}
          />
        </Col>
      </Row>
      <Row>
        <Accordion defaultActiveKey="0" activeKey={serviceAccActiveKey}>
          {services.map((service, i) => (
            <Accordion.Item
              key={service.id}
              eventKey={i.toString()}
              className={
                errors.sections && errors?.sections[index]?.data?.services[i]
                  ? "border-danger"
                  : ""
              }
            >
              <Accordion.Header onClick={() => handleServiceAccordionClick(i)}>
                <h6>
                  {t("customerWebsite.sections.service")} #{i + 1}{" "}
                  {watch(`sections[${index}].data.services[${i}].heading.text`)}
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col sm={12} md={6}>
                    <TextField
                      label={t("customerWebsite.heading")}
                      placeholder={t("customerWebsite.heading")}
                      type="text"
                      errors={
                        errors?.sections &&
                        errors?.sections[index]?.data?.services &&
                        errors?.sections[index]?.data?.services[i]?.heading
                          ?.text
                      }
                      name={`sections[${index}].data.services[${i}].heading.text`}
                      register={register}
                      noSpacing
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ColorPickerWithTextField
                      hideButton
                      label={t("customerWebsite.headingColor")}
                      placeholder="#000000"
                      fieldName={`sections[${index}].data.services[${i}].heading.color`}
                      fieldError={
                        errors?.sections &&
                        errors?.sections[index]?.data?.services &&
                        errors?.sections[index]?.data?.services[i]?.heading
                          ?.color
                      }
                      fieldRef={getRef(index, i, "headingColor")}
                      fieldVal={watch(
                        `sections[${index}].data.services[${i}].heading.color`
                      )}
                      defaultColorValue="#000000"
                      isInGroup
                      noSpacing
                      hideErrMsg
                      isFormChanged={isFormChanged}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <TextArea
                      label={t("customerWebsite.para")}
                      name={`sections[${index}].data.services[${i}].para.text`}
                      errors={
                        errors?.sections &&
                        errors?.sections[index]?.data?.services &&
                        errors?.sections[index]?.data?.services[i]?.para?.text
                      }
                      register={register}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ColorPickerWithTextField
                      hideButton
                      label={t("customerWebsite.paraColor")}
                      placeholder="#000000"
                      fieldName={`sections[${index}].data.services[${i}].para.color`}
                      fieldError={
                        errors?.sections &&
                        errors?.sections[index]?.data?.services &&
                        errors?.sections[index]?.data?.services[i]?.para?.color
                      }
                      fieldRef={getRef(index, i, "paraColor")}
                      fieldVal={watch(
                        `sections[${index}].data.services[${i}].para.color`
                      )}
                      defaultColorValue="#000000"
                      isInGroup
                      noSpacing
                      hideErrMsg
                      isFormChanged={isFormChanged}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <FileInput
                      label={t("customerWebsite.image")}
                      name={`sections[${index}].data.services[${i}].image`}
                      // resourceId={resourceId}
                      parentFolderName={"customer"}
                      url={parseResourceSrc(
                        watch(`sections[${index}].data.services[${i}].image`)
                      )}
                      urlForPreview={parsePreviewResourceSrc(
                        watch(`sections[${index}].data.services[${i}].image`)
                      )}
                      error={
                        errors?.sections &&
                        errors?.sections[index]?.data?.services &&
                        errors?.sections[index]?.data?.services[i]?.image?._id
                      }
                      asset={
                        watch(`sections[${index}].data.services[${i}].image`)
                          ?.asset
                      }
                      isFormChanged={isFormChanged}
                      tagName="website_dienstleistungen"
                      // selectedCategory={getValues("category")}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
    </>
  );
};

export default ServiceSection;
