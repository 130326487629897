import React, { useCallback, useState, useRef, useMemo } from "react";
import _debounce from "lodash/debounce";
import {
  Modal,
  Button,
  Row,
  Container,
  Col,
  Spinner,
  Form
} from "react-bootstrap";
import { useParams } from "react-router";
import { ModalHead } from "../common-styling";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextArea from "../reusable-form-fields/text-area";
import TextField from "../reusable-form-fields/text-field";
import {
  getAllCategories,
  getCustomerFunnelCategories,
  getCustomerByEmail
} from "../../services/api-declaration";
import { showError, showWarning } from "../../services/toast-service";
import AsyncCreatableSelectField from "../reusable-form-fields/creatable-async-select";
import ValidationMessage from "../validation-message/validation-message";
import FormModal from "../create-new-modal/new-customer-modal";
import CategoryForm from "../categories/category-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValiditySpinner } from "../common-styling";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const WebsiteAIPromptDialog = ({
  show,
  toggleDialog,
  customerId,
  customerContactData,
  generateContent
}) => {
  console.log("cusomter contact data:", customerContactData);
  const { t } = useTranslation();
  const saveBtnRef = useRef();
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allowAddNewCategory, setAllowAddNewCategory] = useState(false);
  const [createOptionText, setCreateOptionText] = useState("");
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const YupSelectFieldValidation = Yup.lazy((value) => {
    if (value) {
      switch (typeof value) {
        case "string":
          return Yup.string().required("Required").nullable();
        case "object":
          return Yup.object().shape({
            _id: Yup.string().required("Required").nullable()
          });
        default:
          return Yup.mixed();
      }
    } else {
      return Yup.string().required("Required").nullable();
    }
  });

  const AIPromptSchema = Yup.object().shape({
    category: YupSelectFieldValidation,
    prompt: Yup.string().nullable()
  });

  const methods = useForm({
    resolver: yupResolver(AIPromptSchema)
    // defaultValues: initialValues
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = methods;
  const handleEmailChange = (fieldVal) => {
    if (fieldVal) {
      console.log(saveBtnRef.current);
      saveBtnRef.current.disabled = true;
      setIsEmailChanged(true);
    }
  };
  const handleDebounceCb = useMemo(
    () =>
      _debounce(async (email) => {
        console.log("email:", email);
        try {
          if (email) {
            const response = await getCustomerByEmail({ email });
            if (response.data.data && response.data.data._id !== params.id) {
              setIsEmailValid(false);
              saveBtnRef.current.disabled = true;
              showWarning(`Email: ${email} ${t("common.isAlreadyTaken")}`);
            } else {
              saveBtnRef.current.disabled = false;
              setIsEmailValid(true);
            }
          }
        } catch (err) {
          console.log(err);
          showError(
            err.response.data?.message ||
              t("toastMessage.errorInGetting", {
                what: t("toastMessage.categoryByID")
              })
          );
        } finally {
          setIsEmailChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [setIsEmailValid, saveBtnRef, params.id]
  );
  const onSubmit = async (formVals) => {
    setIsSubmitting(true);
    await generateContent(formVals);
    setIsSubmitting(false);
    toggleDialog();
  };

  const closeCategoryModal = () => setAllowAddNewCategory(false);
  const createCategoryCallback = (data) => {
    setValue("category", data);
    closeCategoryModal();
  };

  const handleCategoryOptions = useCallback(
    async (params = {}) => {
      let response;
      let rows;
      try {
        response = await getCustomerFunnelCategories(customerId, params);
        rows = response.data?.data;
        if (rows && rows.length > 0) {
          return rows;
        }
        response = await getAllCategories(params);
        rows = response.data?.data?.rows;
        return rows;
      } catch (error) {
        console.log(error);
        showError(
          error.response.data.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.category", { count: 2 })
            })
        );
      }
    },
    // eslint-disable-next-line
    [setValue]
  );

  return (
    <Modal show={show} onHide={toggleDialog} backdrop="static">
      <ModalHead className={`fw-bold text-white`} bg="primary">
        <Modal.Title>Write with AI</Modal.Title>
      </ModalHead>
      <Modal.Body>
        <FormProvider {...methods} id="ai-prompt-form">
          <Form>
            <Container className="px-0">
              <Row>
                <Col sm={12} md={12}>
                  <Form.Group controlId="category">
                    <Form.Label className={errors?.category && "text-danger"}>
                      {t("common.category")}
                      {errors?.category && "*"}
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <AsyncCreatableSelectField
                        control={control}
                        name="category"
                        fetchData={handleCategoryOptions}
                        onCreate={(e) => {
                          setCreateOptionText(e);
                          setAllowAddNewCategory(true);
                        }}
                        capitalized={false}
                      />
                    </div>
                    <ValidationMessage error={errors?.category?.message} />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <TextArea
                    label="Prompt (Optional)"
                    name="prompt"
                    register={register}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    label={t("common.firstName")}
                    placeholder={t("common.firstName")}
                    type="text"
                    name="firstName"
                    errors={errors?.firstName}
                    register={register}
                    defaultValue={customerContactData?.firstName}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    label={t("common.lastName")}
                    placeholder={t("common.lastName")}
                    type="text"
                    name="lastName"
                    errors={errors?.lastName}
                    defaultValue={customerContactData?.lastName}
                    register={register}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Label className={errors?.email && "text-danger"}>
                    {t("common.email")}
                    {errors?.email && "*"}
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          type="email"
                          placeholder="Email"
                          value={getValues("email")}
                          onChange={(ev) => {
                            field.onChange(ev);
                            handleEmailChange(ev.target.value);
                            handleDebounceCb(ev.target.value);
                          }}
                          defaultValue={customerContactData?.email}
                          className={errors?.email && "border-danger"}
                        />
                      )}
                    />
                    {isEmailChanged && (
                      <div className="align-self-center">
                        <ValiditySpinner
                          animation="border"
                          variant="secondary"
                          className="ms-3 rounded-circle"
                        />
                      </div>
                    )}
                  </div>
                  {!isEmailValid && !isEmailChanged && (
                    <small className="mt-1 text-danger fw-bold">
                      <FontAwesomeIcon
                        className="me-2"
                        icon={faExclamationCircle}
                      />
                      {t("common.alreadyTaken")}
                    </small>
                  )}
                  {errors?.email && (
                    <ValidationMessage error={errors?.email.message} />
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label={t("customerWebsite.phone")}
                    placeholder={t("customerWebsite.phone")}
                    type="text"
                    name="navbar.phone.text"
                    errors={errors?.navbar?.phone?.text}
                    register={register}
                    defaultValue={customerContactData?.phone}
                    noSpacing
                  />
                </Col>
                <Col md={12}>
                  <TextField
                    label={t("customerWebsite.address")}
                    placeholder={t("customerWebsite.address")}
                    type="text"
                    name="navbar.address.text"
                    errors={errors?.navbar?.address?.text}
                    register={register}
                    defaultValue={customerContactData?.address}
                    noSpacing
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </FormProvider>
        <FormModal
          show={allowAddNewCategory}
          onHide={closeCategoryModal}
          modalHeading={t("createNewCategory")}
        >
          <CategoryForm
            fromModal
            initialValue={createOptionText}
            createCategoryCallback={createCategoryCallback}
          />
        </FormModal>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row-reverse">
          <Button
            className="ms-2"
            variant="outline-secondary"
            disabled={isSubmitting}
            onClick={() => {
              toggleDialog();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="primary"
            ref={saveBtnRef}
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
            )}
            {isSubmitting ? "Generating..." : "Generate Content"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default WebsiteAIPromptDialog;
