import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Modal, ModalTitle, Button } from "react-bootstrap";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

const Title = styled(ModalTitle)`
  text-transform: capitalize;
`;

const EditorModal = ({
  title,
  onSave,
  onCancel,
  quillValue = "",
  className,
  maxLength
}) => {
  const { t } = useTranslation();
  const quillContent = useRef(quillValue);
  const [charCount, setCharCount] = useState(0);
  const [maxError, setMaxError] = useState(false);

  const stringToText = (html) =>
    new DOMParser().parseFromString(html, "text/html").body.innerText || "";

  const handleQuillChange = (content) => {
    const cleanedContent = ["<p><br></p>", "<p> </p>"].includes(content)
      ? ""
      : content;
    quillContent.current = cleanedContent;
    setCharCount(stringToText(cleanedContent).length);
  };

  useEffect(() => {
    setCharCount(stringToText(quillValue).length);
  }, [quillValue]);

  useEffect(() => {
    setMaxError(maxLength ? charCount > maxLength : false);
  }, [charCount, maxLength]);

  return (
    <Modal size="lg" show centered>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <ReactQuill
          className={className}
          style={{ border: maxError ? "1px solid red" : "none" }}
          defaultValue={quillValue}
          theme="snow"
          onChange={handleQuillChange}
        />
      </Modal.Body>
      <Modal.Footer>
        {maxLength && (
          <p style={{ color: maxError ? "red" : "black" }}>
            {t("common.charactersCount")}: {charCount}/{maxLength}
          </p>
        )}
        <Button onClick={onCancel} variant="secondary">
          {t("common.cancel")}
        </Button>
        <Button
          onClick={() => onSave(quillContent.current)}
          variant="success"
          disabled={maxError}
        >
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditorModal;
