import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useCallback, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form, FormLabel, Modal, ProgressBar } from "react-bootstrap";
import {
  setLeadServiceScript,
  setLeadServicePlusScript,
  setAppointmentConfirmationScript
} from "../../store/slices/auth";
import PDFViewer from "./pdf-viewer";
import { SCRIPT_ROUTE } from "../strings";
import { changeAgentScript } from "../../services/api-declaration";
import { axioxFileUpload } from "../../services/axios-rest-methods";
import { showError, showSuccess } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const DropArea = styled.div`
  border: 1px dashed #80808099;
  border-color: ${({ fielerror }) => `${fielerror ? "red" : "#80808099"}`};
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  color: #808080bd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: scroll;
  width: 100%;
  background: ${({ drag }) => `${drag && "#8080804f"}`};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const UploadScriptDialog = ({ title, show, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storedFile = useRef();
  const progressRef = useRef();
  const [fileError, setFileError] = useState(false);
  const [displayPdfUrl, setDisplayPdfUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const methods = useForm({ defaultValues: {} });
  const { handleSubmit } = methods;

  const handleCloseDialog = () => {
    toggle();
    setDisplayPdfUrl(null);
    setFileError(false);
  };
  const handleUploadFile = async () => {
    if (isUploading) return;
    const file = storedFile.current;
    if (!file) {
      setFileError(true);
      return;
    }
    const forLS = title === SCRIPT_ROUTE.LS ? true : false;
    const forLSPlus = title === SCRIPT_ROUTE.LS_PLUS ? true : false;
    const forAP = title === SCRIPT_ROUTE.AP ? true : false;
    try {
      setIsUploading(true);
      const uploadResponse = await changeAgentScript({
        contentType: file.type,
        fileSize: file.size,
        fileName: file.name,
        forLeadService: forLS,
        forLeadServicePlus: forLSPlus,
        forAppointmentConfirmation: forAP,
        subFolder: title
      });
      const uploadData = uploadResponse.data.data;
      const bodyData = {
        "Content-Type": uploadData.fields["Content-Type"],
        key: uploadData.fields["key"],
        bucket: uploadData.fields["bucket"],
        "X-Amz-Algorithm": uploadData.fields["X-Amz-Algorithm"],
        "X-Amz-Credential": uploadData.fields["X-Amz-Credential"],
        "X-Amz-Date": uploadData.fields["X-Amz-Date"],
        Policy: uploadData.fields["Policy"],
        "X-Amz-Signature": uploadData.fields["X-Amz-Signature"],
        file: file
      };
      await axioxFileUpload(uploadData.url, bodyData, function (progress) {
        if (progressRef.current) {
          progressRef.current.children[0].style.width = `${progress}%`;
        }
      });
      showSuccess(uploadResponse.data.message || "Done!");
      const scriptInfo = {
        url: uploadData.url,
        key: uploadData.fields["key"],
        fileType: uploadData.fields["Content-Type"]
      };
      if (forLS) {
        dispatch(setLeadServiceScript(scriptInfo));
      }
      if (forLSPlus) {
        dispatch(setLeadServicePlusScript(scriptInfo));
      }
      if (forAP) {
        dispatch(setAppointmentConfirmationScript(scriptInfo));
      }
      handleCloseDialog();
    } catch (error) {
      console.log("Error in Uploading: ", error);
      showError(
        error.response?.data?.message || t("toastMessage.uploadItemError")
      );
    } finally {
      setIsUploading(false);
    }
  };
  const onDrop = useCallback((files) => {
    if (files.length) {
      storedFile.current = files[0];
      setFileError(false);
      setDisplayPdfUrl(URL.createObjectURL(files[0]));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"]
    },
    multiple: false
  });

  return (
    <Modal show={show} centered size="lg">
      <div className="p-3">
        <h5 className="mb-3 text-capitalize">{`${t(
          "common.change"
        )} ${title} ${t("script")}`}</h5>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(handleUploadFile)}>
            <FormLabel>{t("uploadScriptDialog.uploadPDF")}:</FormLabel>
            <DropArea
              fielerror={fileError}
              drag={isDragActive}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {displayPdfUrl ? (
                <div className="h-100 w-100">
                  <PDFViewer ariaLabel="ls-plus" scriptUrl={displayPdfUrl} />
                </div>
              ) : isDragActive ? (
                <h5>{t("suggestionGallery.dropHere")}!</h5>
              ) : (
                <h5>{t("dropPdfMsg")}</h5>
              )}
            </DropArea>
            <ProgressBar
              className={`mt-2 ${!isUploading ? "d-none" : "d-flex"}`}
              ref={progressRef}
              striped
              animated
            />
            <div className="text-end mt-3">
              <Button
                className="me-2"
                variant="outline-secondary"
                onClick={handleCloseDialog}
                disabled={isUploading}
              >
                {t("common.close")}
              </Button>
              <Button type="submit" variant="success" disabled={isUploading}>
                {t("common.save")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </Modal>
  );
};
export default UploadScriptDialog;
