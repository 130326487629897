import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "../../components/dashboard-layout";
import TagsPage from "../tags";
import LeadPage from "../leads";
import UsersPage from "../users";
import FunnelPage from "../funnel";
import GalleryPage from "../gallery";
import TemplatePage from "../template";
import CustomerPage from "../customers";
import CategoryPage from "../categories";
import UserSettingsPage from "../user-settings";
import { USER_ROLES } from "../../components/strings";
import LeadAgent from "../../components/lead-agent/lead-agent";
import LeadservicePanel from "../../components/leadservice-panel";
import FunnelsEvaluationPage from "../funnels-evaluation";
import ToolsPage from "../tools";
import MissingInfoPage from "../missing-info";
import AdsEvaluationPage from "../ads-evaluation";
import SiteSettingsPage from "../site-settings";
import LeadAppointmentConfirm from "../../components/lead-appointment-confirm/lead-appoint-confirm";

const Dashboard = () => {
  const auth = useSelector((state) => state.auth);
  const { isAgentPanelDisabled, isAppointmentTabDisabled } = useSelector(
    (state) => state.tabStatus
  );
  return (
    <DashboardLayout>
      {auth.user.role === USER_ROLES.AGENT ? (
        <Routes>
          <Route
            path="agent/*"
            element={
              isAgentPanelDisabled ? (
                <Navigate to="appointment" replace />
              ) : (
                <LeadAgent />
              )
            }
          />
          <Route
            path="appointment/*"
            element={
              isAppointmentTabDisabled ? (
                <Navigate to="agent" replace />
              ) : (
                <LeadAppointmentConfirm />
              )
            }
          />
          <Route path="*" element={<Navigate to="agent" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="funnels/*" element={<FunnelPage />} />
          <Route path="templates/*" element={<TemplatePage />} />
          <Route path="customers/*" element={<CustomerPage />} />
          <Route path="leads/*" element={<LeadPage />} />
          <Route path="categories/*" element={<CategoryPage />} />
          <Route path="gallery/*" element={<GalleryPage />} />
          <Route path="tags/*" element={<TagsPage />} />
          <Route path="user/*" element={<UsersPage />} />
          <Route path="settings/*" element={<UserSettingsPage />} />
          <Route path="site-settings/*" element={<SiteSettingsPage />} />
          <Route
            path="agent"
            element={
              isAgentPanelDisabled ? (
                <Navigate to="/dashboard/appointment" />
              ) : (
                <LeadAgent />
              )
            }
          />
          <Route
            path="appointment"
            element={
              isAppointmentTabDisabled ? (
                <Navigate to="/dashboard/agent" />
              ) : (
                <LeadAppointmentConfirm />
              )
            }
          />
          <Route path="leadservice-panel/*" element={<LeadservicePanel />} />
          <Route path="evaluation/*" element={<FunnelsEvaluationPage />} />
          <Route path="missing-info/*" element={<MissingInfoPage />} />
          <Route path="ads/evaluation/*" element={<AdsEvaluationPage />} />
          <Route path="*" element={<Navigate to="funnels" />} />
          <Route path="tools/*" element={<ToolsPage />} />
        </Routes>
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
