import { useEffect } from "react";
import { InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import TextField from "./text-field";
import {
  openColorPicker,
  handleColorChange,
  colorCodeRegex
} from "../../services/common-service";
import { FCInputColor, FCInputColorButton } from "../common-styling";
import { useTranslation } from "react-i18next";

const ColorPickerWithTextField = ({
  label,
  fieldRef,
  fieldName,
  fieldError,
  fieldVal = "",
  placeholder = "",
  noSpacing = false,
  isInGroup = false,
  setDisplay = false,
  hideErrMsg = false,
  isDisabled = false,
  displayFieldWatcher = false,
  fieldWatcherName = null,
  defaultColorValue = "",
  hideButton = false,
  onChange = undefined,
  isFormChanged
}) => {
  useEffect(() => {
    if (
      colorCodeRegex.test(fieldVal) &&
      fieldVal.length === 7 &&
      fieldRef.current
    ) {
      fieldRef.current.value = fieldVal;
      onChange && onChange(fieldVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldVal, fieldRef]);

  const { t } = useTranslation();
  const { setValue, register } = useFormContext();

  return (
    <InputGroup className="align-items-center">
      <TextField
        type="text"
        messageHeight="30px"
        label={label}
        placeholder={placeholder}
        name={fieldName}
        errors={fieldError}
        register={register}
        displayFieldWatcher={displayFieldWatcher}
        isInGroup={isInGroup}
        setDisplay={setDisplay}
        noSpacing={noSpacing}
        hideErrMsg={hideErrMsg}
        fieldWatcherName={fieldWatcherName}
        defaultValue={defaultColorValue}
        textFieldClasses="shadow-none"
      />
      <FCInputColor
        type="color"
        name={fieldName}
        ref={fieldRef}
        disabled={isDisabled}
        defaultValue={defaultColorValue}
        onChange={(ev) => {
          handleColorChange(ev, setValue);
          isFormChanged.current = true;
        }}
        style={{
          opacity: setDisplay && Boolean(!displayFieldWatcher) ? 0.3 : 1
        }}
      />
      {!hideButton && (
        <FCInputColorButton
          variant="outline-secondary"
          disabled={isDisabled}
          onClick={() => openColorPicker(fieldRef)}
        >
          {t("common.selectColor")}
        </FCInputColorButton>
      )}
    </InputGroup>
  );
};
export default ColorPickerWithTextField;
