import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  faPhone,
  faBuilding,
  faEnvelope,
  faAddressCard,
  faEnvelopeOpenText
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getLeadsCount,
  getFunnelsCount,
  getCustomerById,
  getGroupedCustomerFunnelReport
} from "../../services/api-declaration";
import { CustomCard } from "../common-styling";
import { FUNNEL_TYPE_FILTER } from "../strings";
import GroupedReportStats from "./grouped-report-stats";
import { showError } from "../../services/toast-service";
import { useTranslation } from "react-i18next";

const FAIcon = styled(FontAwesomeIcon)`
  color: #686868;
`;
const StatsItem = styled.div`
  width: 125px !important;
  border-style: dashed !important;
  text-align: center;
`;
const StatsHeadings = styled.div`
  color: var(--text-gray-muted);
`;

const CustomerOverview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { state } = useLocation();
  const { LEAD, EMPLOYEE } = FUNNEL_TYPE_FILTER;
  const [customer, setCustomer] = useState(state?.customer);
  const [totalFunnelsCount, setTotalFunnelsCount] = useState(0);
  const [isLoadingFunnelsDocs, setIsLoadingFunnelsDocs] = useState(true);
  const [totalLeadsCount, setTotalLeadsCount] = useState(0);
  const [isLoadingLeadsDocs, setIsLoadingLeadsDocs] = useState(true);
  const [reports, setReports] = useState([]);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const reportsMap = new Map(reports?.map((item) => [item._id, item]));

  const customerFunnels = async () => {
    setIsLoadingFunnelsDocs(true);
    try {
      console.log("state:", state?.customer);
      if (customer) {
        const customerFunnels = await getFunnelsCount({
          customer: customer._id
        });
        setTotalFunnelsCount(customerFunnels.data?.data || 0);
      }
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "funnels" })
      );
    } finally {
      setIsLoadingFunnelsDocs(false);
    }
  };
  const customerLeads = async () => {
    setIsLoadingLeadsDocs(true);
    try {
      if (customer) {
        const customerLeads = await getLeadsCount({
          archive: false,
          funnelCustomer: customer._id
        });
        setTotalLeadsCount(customerLeads.data?.data || 0);
      }
    } catch (error) {
      console.log(error);
      showError(
        error.response.data.message ||
          t("toastMessage.errorInGetting", { what: "leads" })
      );
    } finally {
      setIsLoadingLeadsDocs(false);
    }
  };
  const fetchFunnelsPerformance = async () => {
    setIsLoadingStats(true);
    try {
      const response = await getGroupedCustomerFunnelReport(params.id);
      setReports(response.data.data);
    } catch (error) {
      console.log("Error in getting funnels performance: ", error);
      showError(
        error.response?.data?.message || "Error in getting funnels performance"
      );
    } finally {
      setIsLoadingStats(false);
    }
  };

  const customerFunnelsCb = useCallback(
    customerFunnels,
    // eslint-disable-next-line
    [customer]
  );
  const customerLeadsCb = useCallback(
    customerLeads,
    // eslint-disable-next-line
    [customer]
  );
  const fetchFunnelsPerformanceCb = useCallback(fetchFunnelsPerformance, [
    params.id
  ]);

  useEffect(() => {
    customerFunnelsCb();
  }, [customerFunnelsCb]);
  useEffect(() => {
    customerLeadsCb();
  }, [customerLeadsCb]);
  useEffect(() => {
    if (!customer && params.id) {
      const getCustomer = async () => {
        try {
          const response = await getCustomerById(params.id);
          setCustomer(response.data?.data);
        } catch (err) {
          console.log(err);
          showError(
            err.response.data.message ||
              t("toastMessage.errorInGetting", {
                what: t("common.customer")
              })
          );
        }
      };
      getCustomer();
    }
    // eslint-disable-next-line
  }, [customer, params.id]);
  useEffect(() => {
    fetchFunnelsPerformanceCb();
  }, [fetchFunnelsPerformanceCb]);

  return (
    <div className="my-4">
      <div>
        <Row>
          <Col>
            <CustomCard>
              <Card.Body className="d-flex flex-center flex-column">
                <div>
                  <h6>{t("customerInfo")}</h6>
                  <div className="ms-2 mt-3">
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faBuilding} className="me-3" />
                      {customer?.companyName || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faEnvelope} className="me-3" />
                      {customer?.email || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faPhone} className="me-3" />
                      {customer?.phone || "N/A"}
                    </div>
                    <div className="p-2 bg-light mb-2">
                      <FAIcon icon={faAddressCard} className="me-3" />
                      {customer?.address || "N/A"}
                    </div>
                    <div className="p-2 bg-light">
                      <FAIcon icon={faEnvelopeOpenText} className="me-3" />
                      {customer?.zipCode || "N/A"}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </CustomCard>
          </Col>
          <Col>
            <CustomCard>
              <Card.Body className="d-flex flex-center flex-column">
                <div>
                  <h6>{t("summary")}</h6>
                  <div className="d-flex justify-content-center my-2">
                    <div>
                      <StatsItem className="border rounded py-2 px-3 me-3">
                        <div className="fs-5 fw-bold">
                          {isLoadingFunnelsDocs ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            totalFunnelsCount
                          )}
                        </div>
                        <StatsHeadings className="fw-bold mt-1">
                          {t("common.funnel", { count: 2 })}
                        </StatsHeadings>
                      </StatsItem>
                      <div className="text-center mt-2">
                        <Link
                          to="../funnels"
                          state={{ customer: state?.customer }}
                        >
                          {t("view")}
                        </Link>
                      </div>
                    </div>
                    <div>
                      <StatsItem className="border rounded py-2 px-3 me-3">
                        <div className="fs-5 fw-bold">
                          {isLoadingLeadsDocs ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            totalLeadsCount
                          )}
                        </div>
                        <StatsHeadings className="fw-bold mt-1">
                          {t("common.lead", { count: 2 })}
                        </StatsHeadings>
                      </StatsItem>
                      <div className="text-center mt-2">
                        <Link to="../leads">{t("view")}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </CustomCard>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <h5 className="fw-bold my-3">{t("budget90DaysLabel")}</h5>
            <Row className="position-relative">
              <GroupedReportStats
                isLoading={isLoadingStats}
                report={reportsMap.get(LEAD)}
                label={t("budgetSectionLabels.lead")}
              />
              <GroupedReportStats
                isLoading={isLoadingStats}
                report={reportsMap.get(EMPLOYEE)}
                label={t("budgetSectionLabels.employee")}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CustomerOverview;
