import Form from "react-bootstrap/Form";
import ValidationMessage from "../validation-message/validation-message";

const RadioInput = (props) => {
  return (
    <Form.Group className={!props.noSpacing ? "mb-3" : ""}>
      {props.label && (
        <Form.Label
          className={`${props.labelSpacing ? props.labelSpacing : ""} ${
            props?.errors && props.errors.message ? "text-danger" : ""
          }`}
        >
          {props.label}
          {props?.errors && props.errors.message && "*"}
        </Form.Label>
      )}
      <div
        className={`${props.isInline ? "d-flex " : ""} ${
          !props.noSpacing ? "ms-3" : ""
        }`}
      >
        <Form.Check
          type="radio"
          inline={props.isInline}
          id={props.firstValueId}
          value={props.firstValue}
          label={props.firstOptLabel}
          {...props.register(props.name)}
        />
        <Form.Check
          type="radio"
          inline={props.isInline}
          id={props.secondValueId}
          value={props.secondValue}
          label={props.secondOptLabel}
          {...props.register(props.name)}
        />
        {props.thirdValue && (
          <Form.Check
            type="radio"
            inline={props.isInline}
            id={props.thirdValueId}
            value={props.thirdValue}
            label={props.thirdOptLabel}
            {...props.register(props.name)}
          />
        )}
      </div>
      {props?.errors &&
        (props.forAppointment ? (
          <ValidationMessage error={props.errors.message} />
        ) : (
          <Form.Text className="text-danger">{props.errors.message}</Form.Text>
        ))}
    </Form.Group>
  );
};
RadioInput.defaultProps = {
  isInline: true
};
export default RadioInput;
