import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CustomCard } from "../../common-styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import AddSectionDialog from "./add-section-dialog";
import { getCustomerTemplateSections } from "../../../services/api-declaration";
import { useTranslation } from "react-i18next";

const AddNewSection = ({ addSection, selectedTemplate }) => {
  const [showModal, setShowModal] = useState(false);
  const [sections, setSections] = useState([]);

  const { t } = useTranslation();

  const getAllSections = async () => {
    try {
      const response = await getCustomerTemplateSections();
      const sections = response.data.data;
      setSections(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const allSectionsCB = useCallback(getAllSections, []);

  useEffect(() => {
    allSectionsCB();
  }, [allSectionsCB]);

  const toggleAddDialog = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={12}>
          <CustomCard className="p-3">
            <div
              onClick={toggleAddDialog}
              className="bg-light rounded d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer border"
            >
              <FontAwesomeIcon size="2xl" icon={faPlusSquare} />
              <h5 className="mb-0 ps-2">{t("customerWebsite.addSection")}</h5>
            </div>
          </CustomCard>
        </Col>
      </Row>

      {showModal && (
        <AddSectionDialog
          data={sections}
          addSection={addSection}
          toggleDialog={toggleAddDialog}
          show={showModal}
          selectedTemplate={selectedTemplate}
        />
      )}
    </>
  );
};

export default AddNewSection;
