import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import { DATE_REVERSE_FORMAT } from "../../strings";
import { useTranslation } from "react-i18next";
import {
  getInvoicePdf,
  sendInvoiceToCustViaEmail
} from "../../../services/api-declaration";
import { showError, showSuccess } from "../../../services/toast-service";
import { useParams } from "react-router";
import InvoiceActionsButton from "./invoice-buttons";
import {
  faArrowDown,
  faEnvelope,
  faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Invoice = ({ invoice }) => {
  const { t } = useTranslation();
  const { invoiceId, invoiceNumber, invoiceDate, amount, invoiceType, isPaid } =
    invoice;
  const { id: customerId } = useParams();
  const [isSending, setIsSending] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (e) => {
    e.stopPropagation();
    setIsDownloading(true);
    try {
      const response = await getInvoicePdf(invoiceId);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading PDF: ", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSendToCustomer = async (e) => {
    e.stopPropagation();
    setIsSending(true);
    try {
      const response = await sendInvoiceToCustViaEmail(customerId, invoiceId);
      showSuccess(response.data.message);
    } catch (error) {
      console.error("Error sending email: ", error);
      showError(error.response.data.message);
    } finally {
      setIsSending(false);
    }
  };

  const handleOpenDocument = async (e) => {
    e.stopPropagation();
    setIsOpening(true);
    try {
      const response = await getInvoicePdf(invoiceId);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.log("Error opening PDF: ", error);
    } finally {
      setIsOpening(false);
    }
  };

  return (
    <Card className="mb-3 shadow-sm">
      <Card.Body>
        <Row>
          <Col md={2}>
            <small className="text-muted">{t("invoice.invoiceNumber")}</small>
            <h5 className="card-title">{invoiceNumber}</h5>
          </Col>
          <Col md={2}>
            <small className="text-muted">{t("invoice.invoiceDate")}</small>
            <h5 className="card-title mb-2 text-muted">
              {moment(invoiceDate).format(DATE_REVERSE_FORMAT)}
            </h5>
          </Col>
          <Col md={1}>
            <small className="text-muted">{t("invoice.invoiceAmount")}</small>
            <h5
              className={`card-title ${
                invoiceType === "CREDIT" ? "text-success" : "text-dark"
              }`}
            >
              {amount / 100} €
            </h5>
          </Col>
          <Col md={2}>
            <small className="text-muted">{t("invoice.status")}</small>
            <h5
              className={`card-title ${isPaid ? "text-success" : "text-dark"}`}
            >
              {isPaid ? t("invoice.paid") : t("invoice.issued")}
            </h5>
          </Col>
          <Col md={5} className="text-md-end">
            <InvoiceActionsButton
              activityHandler={handleSendToCustomer}
              isLoading={isSending}
              btnText={t("invoice.sending")}
              btnStyle="btn-primary mt-2 me-2 btn-sm"
              icon={faEnvelope}
              width={"12rem"}
              btnTextPrimary={t("invoice.sendToCust")}
              variant="outline-primary"
              className="mt-2 me-2 btn-sm"
            />
            <InvoiceActionsButton
              activityHandler={handleOpenDocument}
              isLoading={isOpening}
              btnText={t("invoice.opening")}
              width={"8rem"}
              btnStyle="btn-success mt-2 me-2 btn-sm"
              icon={faMagnifyingGlass}
              btnTextPrimary={t("invoice.preview")}
              variant="outline-success"
              className="mt-2 me-2 btn-sm"
            />
            <InvoiceActionsButton
              activityHandler={handleDownload}
              isLoading={isDownloading}
              btnText={t("invoice.downloading")}
              width={"11rem"}
              btnStyle="btn-secondary mt-2 me-2 btn-sm"
              icon={faArrowDown}
              btnTextPrimary={t("invoice.download")}
              variant="outline-secondary"
              className="mt-2 me-2 btn-sm"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Invoice;
