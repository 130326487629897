import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getAllTags,
  getAllCities,
  getAllFunnels,
  getAllTemplates,
  getAllCustomers,
  getAllCategories,
  getAllFunnelsForSearch,
  fetchAllUsers
} from "../services/api-declaration";
import { ICON_TYPE } from "../components/strings";
import { showError } from "../services/toast-service";
import { useTranslation } from "react-i18next";

export const useOptionsFetch = ({ setValue } = {}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  const oneTimeUse = useRef(false);

  const handleCustomerOptions = useCallback(
    async (params = {}) => {
      try {
        const response = await getAllCustomers(params);
        const rows = response.data?.data?.rows;
        const selectedOption = rows.find(
          (row) => row._id === searchParamsRef.current.get("customer")
        );
        if (selectedOption && setValue) {
          setValue("customer", selectedOption);
        }
        return rows;
      } catch (error) {
        console.log(error);
        showError(error.response.data.message || "Error in loading customers!");
      }
    },
    [setValue]
  );
  const handleFunnelOptions = useCallback(
    async (params = {}) => {
      try {
        let response;
        if (params.forHeaderOnly) {
          response = await getAllFunnelsForSearch(params);
          response.data?.data?.rows?.map((row) => {
            if (row.archived) {
              row.disabled = true;
              row.iconType = ICON_TYPE.ARCHIVED;
            } else {
              row.iconType = ICON_TYPE.TARGET_BLANK;
              row.iconLink = `/dashboard/funnels/${row.id}/form?funnelType=${row.general.funnelType}`;
            }
            return row;
          });
        } else {
          response = await getAllFunnels(params);
        }
        const rows = response.data?.data?.rows;
        const selectedOption = rows.find(
          (row) => row._id === searchParamsRef.current.get("funnel")
        );
        if (selectedOption && setValue) {
          setValue("funnel", selectedOption);
        }
        return rows;
      } catch (error) {
        console.log(error);
        showError(
          error.response.data.message ||
            t("toastMessage.errorInGetting", { what: "funnels" })
        );
      }
    },
    // eslint-disable-next-line
    [setValue]
  );
  const handleCategoryOptions = useCallback(
    async (params = {}) => {
      try {
        const response = await getAllCategories(params);
        const rows = response.data?.data?.rows;
        const selectedOption = rows.find(
          (row) => row._id === searchParamsRef.current.get("category")
        );
        if (selectedOption && setValue) {
          setValue("category", selectedOption);
        }
        return rows;
      } catch (error) {
        console.log(error);
        showError(
          error.response.data.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.category", { count: 2 })
            })
        );
      }
    },
    // eslint-disable-next-line
    [setValue]
  );

  const handleTagOptions = useCallback(
    async (params = {}) => {
      try {
        const response = await getAllTags(params);
        const rows = response.data?.data?.rows;
        const selectedParams =
          JSON.parse(searchParamsRef.current.get("tags")) || [];
        const selectedOptions = rows.filter((row) =>
          selectedParams.includes(row._id)
        );
        if (setValue && !oneTimeUse.current) {
          setValue("tags", selectedOptions);
          oneTimeUse.current = true;
        }
        return rows;
      } catch (error) {
        console.log(error, "error");
        showError(error.response.data.message || "Error in loading Tags!");
      }
    },
    [setValue]
  );
  const handleCityOptions = useCallback(async (params = {}) => {
    try {
      const response = await getAllCities(params);
      const rows = response.data?.data;
      return rows;
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || "Error in loading Tags!");
    }
  }, []);
  const handleTemplateOptions = useCallback(async (params = {}) => {
    try {
      const response = await getAllTemplates(params);
      const rows = response.data?.data?.rows;
      return rows;
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || "Error in loading Tags!");
    }
  }, []);
  const handleUserOptions = useCallback(async (params = {}) => {
    try {
      const response = await fetchAllUsers(params);
      const rows = response.data?.data?.rows;
      return rows;
    } catch (error) {
      console.log(error);
      showError(error.response.data.message || "Error in loading Users!");
    }
  }, []);
  return {
    handleCustomerOptions,
    handleFunnelOptions,
    handleCategoryOptions,
    handleTagOptions,
    handleCityOptions,
    handleTemplateOptions,
    handleUserOptions
  };
};
