import {
  Row,
  Col,
  Form,
  Button,
  Tooltip,
  InputGroup,
  OverlayTrigger
} from "react-bootstrap";
import _debounce from "lodash/debounce";
import { useParams } from "react-router-dom";
import React, { useState, useMemo, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useDialog } from "../../hooks/useDialog";
import LinkPreviewComponent from "../link-preview";
import { generateCustomerSiteUrl } from "../../services/common-service";
import { getCustomerWebsiteInfo } from "../../services/api-declaration";
import { ValiditySpinner } from "../common-styling";
import { showError, showWarning } from "../../services/toast-service";
import ValidationMessage from "../validation-message/validation-message";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import { DNS_RECORD_TYPES } from "../strings";

const DuplicateWebsiteDomainSection = ({
  setIsDomainValid,
  isDomainValid,
  btnRef,
  inDialog = false,
  customerId = null,
  selectedCustomerId = null,
  dnsResult = null,
  dnsLoading,
  showCheckDNS,
  setShowCheckDNS,
  getDNS
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    trigger
  } = useFormContext();
  const params = useParams();
  const { showConfirmationDialogBox } = useDialog();
  const [isDomainChanged, setIsDomainChanged] = useState(false);
  const [isNonActive, setIsNonActive] = useState(false);
  const [noActiveTemplate, setNonActiveTemplate] = useState({});

  const toggleConfirmation = () =>
    showConfirmationDialogBox({
      title: "Warning!",
      dialogType: "warning",
      responseAction: dialogResponseAction,
      dialogMessage: dialogMessage()
    });

  const handleDomainChange = (fieldVal) => {
    if (fieldVal) {
      btnRef.current.disabled = true;
      setIsDomainChanged(true);
    }
  };
  const dialogResponseAction = (action) => {
    if (action && noActiveTemplate?.templateId) {
      setIsDomainValid(true);
      setIsNonActive(false);
    }
  };
  const dialogMessage = () => (
    <div>
      Do you want to use this domain and subdomain anyway?
      <div>
        Because it will remove domain and subdomain from Template Of Customer{" "}
        <OverlayTrigger overlay={<Tooltip>{t("common.archive")}</Tooltip>}>
          <b>{noActiveTemplate?.customerId}</b>
        </OverlayTrigger>
      </div>
    </div>
  );
  const handleDebounceCb = useMemo(
    (selectedCustomerId) =>
      _debounce(async (selectedCustomerId) => {
        const domainValue = watch("domain");
        try {
          if (domainValue) {
            const response = await getCustomerWebsiteInfo({
              domain: domainValue
            });
            if (
              response.data.data &&
              response.data.data.customerId !== selectedCustomerId &&
              ((customerId && response.data.data.customerId === customerId) ||
                response.data.data.customerId !== params.id)
            ) {
              let toastMsg = "";
              if (response.data.data.active === false) {
                toastMsg = t("websiteDomainNonActiveMsg1");
                setNonActiveTemplate({
                  templateId: response.data.data._id,
                  customerId: response.data.data.customerId
                });
              } else {
                toastMsg = t("domainArchivedMsg2");
              }
              setIsNonActive(response.data.data.active);
              setIsDomainValid(false);
              showWarning(toastMsg);
            } else {
              setIsDomainValid(true);
            }
          }
        } catch (err) {
          console.log("ERR ", err);
          showError(
            err.response.data.message || t("toastMessage.funnelByDomain")
          );
        } finally {
          btnRef.current.disabled = false;
          setIsDomainChanged(false);
        }
      }, 2000),
    // eslint-disable-next-line
    [setIsDomainValid, params.id, btnRef, watch, selectedCustomerId]
  );

  useEffect(() => {
    btnRef.current.disabled = true;
    setIsDomainChanged(true);
    handleDebounceCb(selectedCustomerId);
  }, [selectedCustomerId, handleDebounceCb, btnRef]);

  const manageDNSCheck = async () => {
    const isValid = await trigger("domain");
    await getDNS(watch("domain"), isValid);
  };

  return (
    <div className="py-0 px-0">
      <div className="px-0">
        <Row>
          <Col xs={12} md={inDialog ? 12 : 4}>
            <div>
              <Form.Label> Domain</Form.Label>
              <InputGroup className="d-flex align-items-center">
                <Controller
                  control={control}
                  name="domain"
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      {...field}
                      placeholder="domain"
                      onChange={(e) => {
                        field.onChange(e);
                        setIsDomainValid(true);
                        // setShowCheckDNS(true);
                        handleDomainChange(e.target.value);
                        handleDebounceCb(selectedCustomerId);
                      }}
                      className={errors.domain && "border-danger"}
                    />
                  )}
                />

                {showCheckDNS && !isDomainChanged && (
                  <Button
                    variant="primary"
                    size="md"
                    onClick={manageDNSCheck}
                    disabled={!isDomainValid}
                  >
                    Validate
                  </Button>
                )}

                {(isDomainChanged || dnsLoading) && (
                  <ValiditySpinner
                    animation="border"
                    variant="secondary"
                    className="ms-2 rounded-circle"
                  />
                )}

                {!dnsLoading && !isDomainChanged && !showCheckDNS && (
                  <>
                    {dnsResult === DNS_RECORD_TYPES.NON_ACTIVE && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            DNS record not matched with hosting IP Address
                          </Tooltip>
                        }
                      >
                        <Button variant="warning" size="md">
                          <FontAwesomeIcon icon={faWarning} />
                        </Button>
                      </OverlayTrigger>
                    )}

                    {dnsResult === DNS_RECORD_TYPES.NOT_FOUND && (
                      <OverlayTrigger
                        overlay={<Tooltip>Domain Not Found</Tooltip>}
                      >
                        <Button variant="danger" size="md">
                          <FontAwesomeIcon icon={faWarning} />
                        </Button>
                      </OverlayTrigger>
                    )}

                    {dnsResult === DNS_RECORD_TYPES.ACTIVE && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            DNS record matched with hosting IP Address
                          </Tooltip>
                        }
                      >
                        <Button variant="success" size="md">
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </>
                )}
              </InputGroup>

              {errors.domain && (
                <ValidationMessage error={errors.domain.message} />
              )}

              {!isDomainValid && (
                <div className="mt-2 small text-danger fw-bold">
                  {!isNonActive && !inDialog ? (
                    <div className="d-flex justify-content-between">
                      {t("websiteDomainNonActiveMsg1")}
                      <Button onClick={toggleConfirmation}>
                        {t("useAnyway")}
                      </Button>
                    </div>
                  ) : (
                    t("notAllowedDomainMsg")
                  )}
                </div>
              )}
              <LinkPreviewComponent
                link={generateCustomerSiteUrl(watch("domain"))}
                linkText={`https://${watch("domain")}`}
                show={watch("domain")}
                showCopyButton={true}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DuplicateWebsiteDomainSection;
