import React, { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomCard, FieldSet, Legend } from "../../common-styling";
import { Col, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import { showWarning } from "../../../services/toast-service";
import tinycolor from "tinycolor2";

const BrandColorsSection = ({ isAllowedChange, isFormChanged }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  //Brand Colors Picker Reference
  const logoColorPickerRef = useRef();
  const headingDarkColorPickerRef = useRef();
  const headingLightColorPickerRef = useRef();
  const textPrimaryColorPickerRef = useRef();
  const textSecondaryColorPickerRef = useRef();
  const textLightColorPickerRef = useRef();
  const btnPrimaryColorPickerRef = useRef();
  const btnPrimaryBGColorPickerRef = useRef();
  const btnSecondaryColorPickerRef = useRef();
  const btnSecondaryBGColorPickerRef = useRef();
  const primaryBGColorPickerRef = useRef();
  const secondaryBGColorPickerRef = useRef();
  //Brand Colors Picker Reference End

  const btnPrimary = watch("brand_colors.btn_primary");
  const btnSecondary = watch("brand_colors.btn_secondary");
  const btnBGPrimary = watch("brand_colors.btn_bg_primary");
  const btnBGSecondary = watch("brand_colors.btn_bg_secondary");
  const sections = watch("sections");

  const handleLogoColor = (value) => {
    if (value && isAllowedChange) {
      setValue("navbar.logo.color", value);
      setValue("footer.logo.color", value);
    }
  };

  const handleHeadingDark = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (!["banner"].includes(section.component)) {
            setValue(`sections[${index}].data.heading.color`, value);
          }
          setValue(`sections[${index}].data.q.color`, value);
          const services = section?.data?.services;
          const keyPoints = section?.data?.keyPoints;
          services &&
            services.forEach((_, i) => {
              setValue(
                `sections[${index}].data.services[${i}].heading.color`,
                value
              );
            });
          keyPoints &&
            keyPoints.forEach((_, i) => {
              setValue(
                `sections[${index}].data.keyPoints[${i}].heading.color`,
                value
              );
            });
        });
        setValue("footer.contact_card.name.color", value);
      }
    }
  };

  const handleHeadingLight = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["banner"].includes(section.component)) {
            setValue(`sections[${index}].data.heading.color`, value);
          }
        });
      }
    }
  };

  const handleTextPrimary = (value) => {
    if (value && isAllowedChange) {
      setValue("navbar.links.color", value);
      setValue("navbar.phone.color", value);
      setValue("navbar.address.color", value);
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (!["banner", "hero", "about"].includes(section.component)) {
            setValue(`sections[${index}].data.para.color`, value);
          }
          const services = section?.data?.services;
          services &&
            services.forEach((_, i) => {
              setValue(
                `sections[${index}].data.services[${i}].para.color`,
                value
              );
            });
        });
        setValue("footer.facebook.color", value);
        setValue("footer.instagram.color", value);
        setValue("footer.youtube.color", value);
        setValue("footer.footer_links.quick_links.color", value);
        setValue("footer.footer_links.pp_links.color", value);
        setValue("footer.contact_card.title.color", value);
      }
    }
  };

  const handleTextSecondary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["hero", "about"].includes(section.component)) {
            setValue(`sections[${index}].data.para.color`, value);
          }
          setValue(`sections[${index}].data.a.color`, value);
          const keyPoints = section?.data?.keyPoints;
          keyPoints &&
            keyPoints.forEach((_, i) => {
              setValue(
                `sections[${index}].data.keyPoints[${i}].para.color`,
                value
              );
            });
        });
      }
      setValue("footer.tag_line.color", value);
    }
  };

  const handleTextLight = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["banner"].includes(section.component)) {
            setValue(`sections[${index}].data.para.color`, value);
          }
        });
      }
    }
  };

  const handleBtnPrimary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["hero", "secondBanner"].includes(section.component)) {
            setValue(`sections[${index}].data.btn.color`, value);
          }
        });
      }
      setValue("footer.contact_card.btn.color", value);
    }
  };

  const handleBtnBGPrimary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["hero", "secondBanner"].includes(section.component)) {
            setValue(`sections[${index}].data.btn.bgColor`, value);
          }
        });
      }
      setValue("footer.contact_card.btn.bgColor", value);
    }
  };

  const handleBtnSecondary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["banner"].includes(section.component)) {
            setValue(`sections[${index}].data.btn.color`, value);
          }
        });
      }
    }
  };

  const handleBtnBGSecondary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["banner"].includes(section.component)) {
            setValue(`sections[${index}].data.btn.bgColor`, value);
          }
        });
      }
    }
  };

  const handleBGPrimary = (value) => {
    if (value && isAllowedChange) {
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (["secondBanner", "faq"].includes(section.component)) {
            setValue(`sections[${index}].data.bgColor`, value);
          }
        });
      }
    }
  };

  const handleBGSecondary = (value) => {
    if (value && isAllowedChange) {
      setValue("navbar.bgColor", value);
      if (sections?.length > 0) {
        sections.forEach((section, index) => {
          if (!["secondBanner", "faq"].includes(section.component)) {
            setValue(`sections[${index}].data.bgColor`, value);
          }
        });
      }
      setValue("footer.bgColor", value);
    }
  };

  useEffect(() => {
    const btnPrimaryTiny = tinycolor(btnPrimary);
    const btnSecondaryTiny = tinycolor(btnSecondary);
    const btnBGPrimaryTiny = tinycolor(btnBGPrimary);
    const btnBGSecondaryTiny = tinycolor(btnBGSecondary);
    const conflictPrimaryColor =
      btnPrimaryTiny.isDark() === btnBGPrimaryTiny.isDark() ||
      btnPrimaryTiny.isLight() === btnBGPrimaryTiny.isLight();
    const conflictSecondaryColor =
      btnSecondaryTiny.isDark() === btnBGSecondaryTiny.isDark() ||
      btnSecondaryTiny.isLight() === btnBGSecondaryTiny.isLight();
    if (conflictPrimaryColor || conflictSecondaryColor) {
      showWarning(t("customerWebsite.btnSameBGColorError"), {
        position: "top-right"
      });
    }
  }, [btnPrimary, btnBGPrimary, btnSecondary, btnBGSecondary, t]);

  return (
    <CustomCard className="mb-3 py-1">
      <FieldSet>
        <Row>
          <Col md={12}>
            <Legend>
              <h4 className="mb-0 fw-bold">
                {t("customerWebsite.brandColors.heading")}
                <p style={{ fontSize: "14px" }} className="text-danger">
                  ({t("customerWebsite.brandColors.warning")})
                </p>
              </h4>
            </Legend>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.logo.logoColor")}
              placeholder="#000000"
              fieldName="brand_colors.logo_color"
              fieldError={errors?.brand_colors?.logo_color}
              fieldRef={logoColorPickerRef}
              fieldVal={watch("brand_colors.logo_color")}
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              defaultColorValue="#000000"
              onChange={handleLogoColor}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.headingDark")}
              placeholder="#000000"
              fieldName="brand_colors.heading_dark"
              fieldError={errors?.brand_colors?.heading_dark}
              fieldVal={watch("brand_colors.heading_dark")}
              fieldRef={headingDarkColorPickerRef}
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              defaultColorValue="#000000"
              onChange={handleHeadingDark}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.headingLight")}
              placeholder="#000000"
              fieldName="brand_colors.heading_light"
              fieldError={errors?.brand_colors?.heading_light}
              fieldRef={headingLightColorPickerRef}
              fieldVal={watch("brand_colors.heading_light")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleHeadingLight}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.textPrimary")}
              placeholder="#000000"
              fieldName="brand_colors.text_primary"
              fieldError={errors?.brand_colors?.text_primary}
              fieldRef={textPrimaryColorPickerRef}
              fieldVal={watch("brand_colors.text_primary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleTextPrimary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.textSecondary")}
              placeholder="#000000"
              fieldName="brand_colors.text_secondary"
              fieldError={errors?.brand_colors?.text_secondary}
              fieldRef={textSecondaryColorPickerRef}
              fieldVal={watch("brand_colors.text_secondary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleTextSecondary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.textLight")}
              placeholder="#000000"
              fieldName="brand_colors.text_light"
              fieldError={errors?.brand_colors?.text_light}
              fieldRef={textLightColorPickerRef}
              fieldVal={watch("brand_colors.text_light")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleTextLight}
              isFormChanged={isFormChanged}
            />
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.buttonPrimary")}
              placeholder="#000000"
              fieldName="brand_colors.btn_primary"
              fieldError={errors?.brand_colors?.btn_primary}
              fieldRef={btnPrimaryColorPickerRef}
              fieldVal={watch("brand_colors.btn_primary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBtnPrimary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.buttonBGPrimary")}
              placeholder="#000000"
              fieldName="brand_colors.btn_bg_primary"
              fieldError={errors?.brand_colors?.btn_bg_primary}
              fieldRef={btnPrimaryBGColorPickerRef}
              fieldVal={watch("brand_colors.btn_bg_primary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBtnBGPrimary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.buttonSecondary")}
              placeholder="#000000"
              fieldName="brand_colors.btn_secondary"
              fieldError={errors?.brand_colors?.btn_secondary}
              fieldRef={btnSecondaryColorPickerRef}
              fieldVal={watch("brand_colors.btn_secondary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBtnSecondary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.buttonBGSecondary")}
              placeholder="#000000"
              fieldName="brand_colors.btn_bg_secondary"
              fieldError={errors?.brand_colors?.btn_bg_secondary}
              fieldRef={btnSecondaryBGColorPickerRef}
              fieldVal={watch("brand_colors.btn_bg_secondary")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBtnBGSecondary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.primaryBG")}
              placeholder="#000000"
              fieldName="brand_colors.primary_bg"
              fieldError={errors?.brand_colors?.primary_bg}
              fieldRef={primaryBGColorPickerRef}
              fieldVal={watch("brand_colors.primary_bg")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBGPrimary}
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3">
            <ColorPickerWithTextField
              label={t("customerWebsite.brandColors.secondaryBG")}
              placeholder="#000000"
              fieldName="brand_colors.secondary_bg"
              fieldError={errors?.brand_colors?.secondary_bg}
              fieldRef={secondaryBGColorPickerRef}
              fieldVal={watch("brand_colors.secondary_bg")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              onChange={handleBGSecondary}
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
      </FieldSet>
    </CustomCard>
  );
};

export default BrandColorsSection;
