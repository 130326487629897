import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faVial
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const GoToSiteButton = ({
  url,
  resourceId,
  variantSize,
  isArchived = false,
  isPublished = false,
  isTestMode,
  className
}) => {
  const { t } = useTranslation();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Button
        style={resourceId ? { paddingBottom: 2, paddingTop: 0 } : {}}
        size={variantSize}
        variant={`info ${!resourceId && "rounded-circle"}`}
        disabled={!url || isArchived}
        className={className}
      >
        {resourceId && (
          <span className="pe-2">
            {isTestMode ? t("openInTest") : t("open")}
          </span>
        )}
        <FontAwesomeIcon
          size={variantSize}
          icon={isTestMode ? faVial : faArrowUpRightFromSquare}
        />
      </Button>
    </a>
  );
};
export default GoToSiteButton;
