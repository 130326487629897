import DatePicker, { registerLocale } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { createGlobalStyle } from "styled-components";
import moment from "moment";
import { datePickerFormat } from "./analytics-helper";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import "./date-range-picker.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
        display: flex;
        align-self: center;
        text-align: center;
        margin-right: 10px;
    }
`;

const DatePickerLimitRange = ({
  mode,
  dateRange,
  dateRangeMode,
  onRangeChange,
  selectedDate,
  handleLeftBtn,
  handleRightBtn,
  fromCustomerPerformance = false,
  ...others
}) => {
  const startDate = dateRange[0];
  const endDate = dateRange[1];

  const formatDateRange = (date) => {
    switch (dateRangeMode) {
      case mode.DAY:
        return `${moment(date).format("LL")}`;
      case mode.WEEK:
        return `${moment(startDate).format("MMM D")} - ${moment(endDate).format(
          "ll"
        )}`;
      case mode.MONTH:
        return moment(date).format("MMMM");
      case mode.YEAR:
        return moment(date).format("YYYY");
      default:
        return `${moment(startDate).format("ll")} - ${moment(endDate).format(
          "ll"
        )}`;
    }
  };

  const CustomInput = ({ onClick }) => {
    const { t } = useTranslation();
    return (
      <div className="date-btn-container">
        <DatePickerWrapperStyles />
        {dateRangeMode !== mode.RANGE && (
          <div onClick={handleLeftBtn} className="btn-arrow">
            <FontAwesomeIcon icon={faChevronLeft} color={"grey"} />
          </div>
        )}
        <div onClick={onClick} className="date-btn">
          <p>
            {fromCustomerPerformance && !startDate && !endDate
              ? t("selectRangeLabel")
              : formatDateRange(selectedDate)}
          </p>
        </div>
        {dateRangeMode !== mode.RANGE && (
          <div onClick={handleRightBtn} className="btn-arrow">
            <FontAwesomeIcon icon={faChevronRight} color={"grey"} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    registerLocale("en-GB", enGB);
  }, []);

  return (
    <>
      {dateRangeMode === mode.RANGE ? (
        <DatePicker
          calendarClassName="range-picker"
          selected={selectedDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onRangeChange}
          selectsRange
          customInput={<CustomInput />}
          monthsShown={2}
          wrapperClassName="date_picker full-width"
          {...others}
        />
      ) : (
        <DatePicker
          calendarClassName={dateRangeMode === mode.WEEK ? "has-week" : ""}
          selected={selectedDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onRangeChange}
          dateFormat={datePickerFormat[dateRangeMode]}
          showWeekNumbers={dateRangeMode === mode.WEEK}
          showMonthYearPicker={dateRangeMode === mode.MONTH}
          showYearPicker={dateRangeMode === mode.YEAR}
          customInput={<CustomInput />}
          wrapperClassName="date_picker full-width"
          locale={dateRangeMode === mode.WEEK && "en-GB"}
          {...others}
        />
      )}
    </>
  );
};

export default DatePickerLimitRange;
