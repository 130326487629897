import React, { useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomCard, Legend } from "../../common-styling";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import TextField from "../../reusable-form-fields/text-field";
import TextArea from "../../reusable-form-fields/text-area";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const FaqSection = ({ index, isFormChanged }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    register,
    control
  } = useFormContext();

  const {
    fields: faqFields,
    append: addFaq,
    remove: removeFaq
  } = useFieldArray({
    control,
    name: `sections[${index}].data.faqs.data` // Path to the nested array
  });

  const FaqBGColorDisplay = watch(`sections[${index}].data.bgColorDisplay`);
  const faqsActive = watch(`sections[${index}].data.active`);
  const faqsQuestionLength = watch(`sections[${index}].data.faqs.data`).length;

  const faqBgColorPickerRef = useRef();
  const faqHeadingColorPickerRef = useRef();
  const faqParaColorPickerRef = useRef();
  const faqQuestionColorPickerRef = useRef();
  const faqAnswerColorPickerRef = useRef();

  return (
    <>
      <Row className="mb-3">
        {faqsActive && (
          <Col md={3} className="mt-1">
            <ColorPickerWithTextField
              hideButton
              label={t("customerWebsite.BGColor")}
              fieldName={`sections[${index}].data.bgColor`}
              fieldError={
                errors?.sections && errors?.sections[index]?.data?.bgColor
              }
              fieldRef={faqBgColorPickerRef}
              displayFieldWatcher={FaqBGColorDisplay}
              fieldWatcherName={`sections[${index}].data.bgColorDisplay`}
              fieldVal={watch(`sections[${index}].data.bgColor`)}
              isDisabled={Boolean(!FaqBGColorDisplay)}
              setDisplay
              isInGroup
              noSpacing
              hideErrMsg
              isFormChanged={isFormChanged}
            />
          </Col>
        )}
      </Row>
      {faqsActive && (
        <>
          <Row>
            <Col sm={12} md={6}>
              <TextField
                label={t("customerWebsite.heading")}
                placeholder={t("customerWebsite.heading")}
                errors={
                  errors?.sections &&
                  errors?.sections[index]?.data?.heading?.text
                }
                type="text"
                name={`sections[${index}].data.heading.text`}
                register={register}
                noSpacing
              />
            </Col>
            <Col xs={12} md={6}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.headingColor")}
                placeholder="#000000"
                fieldName={`sections[${index}].data.heading.color`}
                fieldError={
                  errors?.sections &&
                  errors?.sections[index]?.data?.heading?.color
                }
                fieldRef={faqHeadingColorPickerRef}
                fieldVal={watch(`sections[${index}].data.heading.color`)}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <TextArea
                label={t("customerWebsite.para")}
                name={`sections[${index}].data.para.text`}
                errors={
                  errors?.sections && errors?.sections[index]?.data?.para?.text
                }
                register={register}
              />
            </Col>
            <Col xs={12} md={6}>
              <ColorPickerWithTextField
                hideButton
                label={t("customerWebsite.paraColor")}
                placeholder="#000000"
                fieldName={`sections[${index}].data.para.color`}
                fieldError={
                  errors?.sections && errors?.sections[index]?.data?.para?.color
                }
                fieldRef={faqParaColorPickerRef}
                fieldVal={watch(`sections[${index}].data.para.color`)}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                isFormChanged={isFormChanged}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Row>
              <Col>
                <div className="d-flex justify-content-between align-items-center">
                  <Legend>
                    <h6 className="fw-bold">{t("customerWebsite.qAndA")}</h6>
                  </Legend>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => addFaq({ q: "", a: "" })}
                    disabled={faqsQuestionLength === 10}
                  >
                    {t("customerWebsite.addQ")}
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              {faqFields.map((faq, i) => (
                <Row key={faq.id}>
                  <Col xs={11} md={11}>
                    <CustomCard className="bg-light px-3 py-3 mb-3">
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          id="basic-addon1"
                          style={{ width: "120px" }}
                        >
                          {t("customerWebsite.q")}:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus={false}
                          placeholder={t("customerWebsite.q")}
                          // name={`faq.faqs.data[${index}].q`}
                          errors={
                            errors?.sections &&
                            errors?.sections[index]?.data?.faqs?.data &&
                            errors?.faq?.faqs?.data[i]?.q
                          }
                          {...register(
                            `sections[${index}].data.faqs.data.${i}.q`
                          )}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>

                      <InputGroup>
                        <InputGroup.Text
                          id="basic-addon1"
                          style={{ width: "120px" }}
                        >
                          {t("customerWebsite.a")}:{" "}
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus={false}
                          as="textarea"
                          placeholder={t("customerWebsite.a")}
                          // name={`faq.faqs.data[${index}].a`}
                          errors={
                            errors?.sections &&
                            errors?.sections[index]?.data?.faqs?.data &&
                            errors?.faq?.faqs?.data[i]?.a
                          }
                          {...register(
                            `sections[${index}].data.faqs.data.${i}.a`
                          )}
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </CustomCard>
                  </Col>
                  <Col md={1}>
                    <Button
                      variant="danger"
                      type="button"
                      onClick={() => removeFaq(i)}
                    >
                      <FontAwesomeIcon size="1x" icon={faTrashAlt} />
                    </Button>
                  </Col>
                </Row>
              ))}
            </Row>

            <Row className="mt-3">
              <Col xs={12} md={6}>
                <ColorPickerWithTextField
                  hideButton
                  label={t("customerWebsite.qColor")}
                  placeholder="#000000"
                  fieldName={`sections[${index}].data.faqs.q.color`}
                  fieldError={
                    errors?.sections &&
                    errors?.sections[index]?.data?.faqs?.q?.color
                  }
                  fieldRef={faqQuestionColorPickerRef}
                  fieldVal={watch(`sections[${index}].data.faqs.q.color`)}
                  defaultColorValue="#000000"
                  isInGroup
                  noSpacing
                  hideErrMsg
                  isFormChanged={isFormChanged}
                />
              </Col>
              <Col xs={12} md={6}>
                <ColorPickerWithTextField
                  hideButton
                  label={t("customerWebsite.aColor")}
                  placeholder="#000000"
                  fieldName={`sections[${index}].data.faqs.a.color`}
                  fieldError={
                    errors?.sections &&
                    errors?.sections[index]?.data?.faqs?.a?.color
                  }
                  fieldRef={faqAnswerColorPickerRef}
                  fieldVal={watch(`sections[${index}].data.faqs.a.color`)}
                  defaultColorValue="#000000"
                  isInGroup
                  noSpacing
                  hideErrMsg
                  isFormChanged={isFormChanged}
                />
              </Col>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default FaqSection;
